import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import { setAccess } from '../../redux/modules/auth'
import layout from '../../decorators/layout'

class PersonalData extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
		this.props.setAccess(true)
	}

	componentWillUnmount() {
		this.props.setAccess(false)
	}

	render() {
		return (
			<Fragment>
				<Fade>
					<div className="content middle text-page">
						<div className="title">Согласие на обработку персональных данных</div>
						<p>
							Я, субъект персональных данных, в соответствии с Федеральным законом от 27 июля 2006 года №
							152 «О персональных данных» предоставляю АО «ПСН», зарегистрированному по адресу: 115114, г.
							Москва, Дербеневская наб., д. 7, стр. 10, пом. IV, этаж 1 (далее – Оператор), согласие на
							обработку персональных данных, указанных мной на страницах сайта в сети «Интернет», при
							заполнении веб-форм, характер информации которых предполагает или допускает включение в них
							следующих персональных данных: имя, фамилия, отчество, дата рождения, пол, номер телефона,
							адрес электронной почты, сведения о месте проживания с целью регистрации личного кабинета
							пользователя, предоставления услуги бронирования объекта недвижимости, записи на осмотр
							объекта недвижимости, продажи объектов недвижимости, получения сведений о ранее совершенных
							сделках, приема обращений физических лиц, предоставления обратной связи по различным
							вопросам.
						</p>
						<p>
							Согласен на передачу (распространение) моих персональных данных в управляющую компанию с
							целью предоставления услуги постпродажного обслуживания, приема обращений физических лиц,
							предоставления обратной связи по различным вопросам.
						</p>
						<p>
							Согласен на передачу (распространение) моих персональных данных с целью администрирования и
							сопровождение сайта, информационных систем Оператора. Согласие предоставляется на совершение
							следующих действий (операций) с указанными в настоящем согласии персональными данными: сбор,
							запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
							использование, передачу (распространение), блокирование, удаление, уничтожение,
							осуществляемых с использованием средств автоматизации (автоматизированная обработка).
						</p>
						<p>
							Я подтверждаю, что ознакомлен с требованиями законодательства Российской Федерации,
							устанавливающими порядок обработки персональных данных, с политикой Оператора в отношении
							обработки персональных данных, а также с моими правами и обязанностями в этой области.
						</p>
						<p>
							Согласие действует по достижении целей обработки или случая утраты необходимости в
							достижении этих целей. Согласие может быть отозвано мною в любое время на основании моего
							письменного заявления.
						</p>
					</div>
				</Fade>
			</Fragment>
		)
	}
}

export default connect(null, { setAccess })(layout(PersonalData))
