import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import { authUserSelector } from '../../redux/modules/auth'
import Reveal from 'react-reveal/Reveal'
import {
	closeChangeEmailForm,
	userInfoSelector,
	changeEmail,
	loadingSelector,
	changeEmailFormSentSelector,
	errorSelector,
	closeChangeEmailSuccess
} from '../../redux/modules/profile'
import SuccessMessage from './change-email-success'
class ChangeEmail extends Component {
	state = {
		triggerValidate: false,
		email: {
			value: '',
			name: 'email',
			type: 'email',
			placeholder: 'Email',
			validate: 'email',
			valid: false
		}
	}

	id = null

	componentDidMount() {
		if (this.container) this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.closeChangeEmailForm, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		if (this.props.sent && !this.props.error) {
			return <SuccessMessage email={this.state.email.value} send={this.handleSend} />
		}

		return (
			<div className="modal modal-profile-change-email" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.hide}></div>
				<div className="modal__container">
					<div className="modal__body">
						<div className="modal-close ico-close" onClick={this.hide}></div>
						<div className="modal-title">Сменить e-mail</div>
						<div className="form-content">
							<div className="modal-text">Введите новый E-mail:</div>
							<InputField
								triggerValidate={this.state.triggerValidate}
								setFieldValid={this.setFieldValid}
								change={this.handleChange}
								className="ico-email"
								{...this.state.email}
							/>
							<div className="modal-text modal-text--middle">
								На указанный e-mail будет отправлена ссылка <br /> для подтверждения измененных данных
							</div>
							<div className="input-field btn-field" onClick={this.handleSend}>
								<div className="btn">отправить</div>
							</div>
						</div>
					</div>

					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	handleSend = (resend) => {
		this.setState({ triggerValidate: true })
		if ((!resend && !this.formIsValid()) || this.props.loading) return false
		this.props.changeEmail({
			cmd: 'change_email_request',
			new_email: this.state.email.value,
			email: this.props.user.email,
			token: this.props.user.token
		})
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			const field = this.state[name]
			if (!field.validate) return true
			return field.valid
		})
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}
}

export default connect(
	(state) => ({
		userInfo: userInfoSelector(state),
		user: authUserSelector(state),
		loading: loadingSelector(state),
		sent: changeEmailFormSentSelector(state),
		error: errorSelector(state)
	}),
	{ closeChangeEmailForm, changeEmail, closeChangeEmailSuccess }
)(ChangeEmail)
