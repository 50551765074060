import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Reveal from 'react-reveal/Reveal'
import InputField from './common/input-field'
import {
	signIn,
	authErrorSelector,
	closeErrorMessage,
	attemptsSingInSelector,
	authLoadingSelector
} from '../redux/modules/auth'
import ErrorMessage from './common/error-message'
import Button from './common/button'
import layout from '../decorators/layout'

class SignIn extends Component {
	state = {
		triggerValidate: false,
		email: {
			value: '',
			name: 'email',
			type: 'email',
			placeholder: 'Email',
			validate: 'email',
			valid: false
		},
		password: {
			value: '',
			name: 'password',
			open: false,
			type: 'password',
			placeholder: 'Пароль',
			validate: 'password',
			valid: false
		}
	}

	componentDidMount() {
		document.title = 'Авторизация - Сервис онлайн-покупки PSN group'

		// document.addEventListener('keypress', this.logKey);
	}

	// componentWillUnmount() {
	// 	document.removeEventListener('keypress', this.logKey);
	// }

	// logKey= (event) => {
	// 	if (event.key === 'Enter') {
	// 		console.log(12);
	// 		this.handleSend()
	// 	}
	// }

	render() {
		return (
			<Fragment>
				<div className="page-bg animate-scale"></div>
				<div className="auth-logo ico-1 animate-fade animate-delay02"></div>
				<div className="modal modal-auth visible">
					<div className="modal__container">
						<Reveal effect="fadeInUp">
							<div className="modal__body js-auth">
								<div className="modal-title">личный кабинет</div>
								<div className="form-content">
									<InputField
										triggerValidate={this.state.triggerValidate}
										setFieldValid={this.setFieldValid}
										change={this.handleChange}
										className="ico-email"
										{...this.state.email}
										keyPressed={this.handleSend}
									/>
									<div className={this.state.password.open ? 'password-wrap open' : 'password-wrap'}>
										<div
											className="password-wrap__handle-click"
											onClick={this.passwordToggle}
										></div>
										<InputField
											triggerValidate={this.state.triggerValidate}
											setFieldValid={this.setFieldValid}
											className="ico-password"
											{...this.state.password}
											change={this.handleChange}
											keyPressed={this.handleSend}
										/>
									</div>
									<Button submit={true} onClick={this.handleSend} loading={this.props.loading}>
										Войти
									</Button>
									<div className="form-links">
										<div className="form-links__item">
											<Link to="/reset-password" data-modal-change="reset-password">
												Забыли пароль?
											</Link>
										</div>
										<div className="form-links__item">
											<Link to="/sign-up">Зарегистрироваться</Link>
										</div>
									</div>
								</div>
							</div>
						</Reveal>

						<div className="modal-helper"></div>
					</div>
				</div>
				{this.props.error && <ErrorMessage error={this.props.error} close={this.props.closeErrorMessage} />}
			</Fragment>
		)
	}

	passwordToggle = () => {
		this.setState({
			password: {
				...this.state.password,
				open: !this.state.password.open,
				type: this.state.password.open ? 'password' : 'text'
			}
		})
	}

	handleSend = () => {
		if (this.props.loading) return false

		this.setState({ triggerValidate: true })
		if (!this.formIsValid()) return false

		this.props.signIn(
			{
				cmd: 'user_authorize',
				email: this.state.email.value,
				password: this.state.password.value
			},
			this.props.attempts
		)
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			const field = this.state[name]
			if (!field.validate) return true
			return field.valid
		})
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}
}

export default connect(
	(state) => ({
		error: authErrorSelector(state),
		attempts: attemptsSingInSelector(state),
		loading: authLoadingSelector(state)
	}),
	{ signIn, closeErrorMessage }
)(layout(SignIn))
