import React, { Component, Fragment } from 'react'
import Row from './row'

class Table extends Component {
	render() {
		const { list, title } = this.props
		if (!list) return null
		return (
			<div className="table deal-table">
				<div className="table-title">{title}</div>
				<table>
					<thead>
						<tr>
							<th className="title-img"></th>
							<th className="title-text">Информация об&nbsp;объекте</th>
							<th className="title-square">
								Площадь, м
								<sup>
									<small>2</small>
								</sup>
							</th>
							<th className="title-price">Стоимость, ₽</th>
							<th className="title-icon">Забронировать</th>
							<th className="title-icon">PDF</th>
							<th className="title-icon">Удалить</th>
						</tr>
					</thead>
					<tbody>{this.rows}</tbody>
				</table>
			</div>
		)
	}

	get rows() {
		return this.props.list.map((el, i) => <Row key={i} el={el} />)
	}
}

export default Table
