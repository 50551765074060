import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ children, loading, submit, ...rest }) => {
	const loadingClassName = loading ? ' btn-field--loading' : ''

	if (submit)
		return (
			<button type="submit" className={`input-field btn-field${loadingClassName}`} {...rest}>
				<div className="btn">{children}</div>
			</button>
		)

	return (
		<div className={`input-field btn-field${loadingClassName}`} {...rest}>
			<div className="btn">{children}</div>
		</div>
	)
}

Button.propTypes = {
	loading: PropTypes.bool.isRequired
}

export default Button
