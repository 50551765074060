import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import { authUserSelector } from '../../redux/modules/auth'
import { getUserInfo, userInfoSelector } from '../../redux/modules/profile'
import { loadDeals } from '../../redux/modules/deals'
import Reveal from 'react-reveal/Reveal'
import { FileDrop } from 'react-file-drop'
import {
	sendQuestion,
	closeForm,
	pendingSelector,
	fulfilledSelector,
	errorSelector,
	closeSuccess
} from '../../redux/modules/faq'
import SuccessMessage from './form-success-message'
import PpoForm from '../ppo-form/form'
import ErrorMessage from '../common/error-message'
import { closeFormError } from '../../redux/modules/form'
import { ppoDealsSelector } from '../../redux/modules/deals'

class Question extends Component {
	state = {
		tab: 1,
		triggerValidate: false,
		name: {
			value: '',
			name: 'name',
			type: 'text',
			placeholder: 'Имя',
			validate: 'text',
			valid: false
		},
		question: {
			value: '',
			name: 'question',
			type: 'textarea',
			placeholder: 'Вопрос',
			validate: 'textarea',
			valid: false
		},
		email: {
			value: '',
			name: 'email',
			type: 'email',
			placeholder: 'Email',
			validate: 'email',
			valid: false
		},
		email2: {
			visible: false,
			value: '',
			name: 'email2',
			type: 'email',
			placeholder: 'Email',
			validate: 'email',
			valid: false
		},
		file: null,
		fileError: null
	}

	id = null

	componentDidMount() {
		if (this.container) this.id = setTimeout(() => this.container.classList.add('visible'), 10)
		const { user, userInfo, getUserInfo } = this.props
		if (user && !userInfo.email) getUserInfo({ email: user.email, token: user.token })

		if (!this.props.loadedDeals && !this.props.loadingDeals && this.props.user && !this.props.clone) {
			loadDeals({
				cmd: 'user_deals',
				email: this.props.user.email,
				token: this.props.user.token
			})
		}
	}

	componentDidUpdate(prevProps) {
		const { user, userInfo, getUserInfo } = this.props
		if (prevProps.user !== user && user && !userInfo.email) getUserInfo({ email: user.email, token: user.token })
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.closeForm, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		if (this.props.fulfilled && !this.props.error) {
			return <SuccessMessage close={this.props.closeSuccess} />
		}

		if (this.props.formError) return <ErrorMessage error={this.props.formError} close={this.props.closeFormError} />
		if (this.props.formSuccess) return <SuccessMessage close={this.props.closeSuccess} />

		if (this.props.userInfo.first_name && !this.state.name.value) {
			this.setState({
				name: {
					...this.state.name,
					value: this.props.userInfo.first_name,
					valid: true
				},
				email: {
					...this.state.email,
					value: this.props.userInfo.email,
					valid: true
				}
			})
		}

		return (
			<div className="modal modal-ask-question modal-ppo" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.hide}></div>
				<div className="modal__container">
					<div className="modal__body js-ask-question">
						<div className="modal-close ico-close" onClick={this.hide}></div>
						<div className="popupTabs">
							<div className="popupTabs__tabs">
								<div
									className={
										this.state.tab == 1 ? 'popupTabs__tab popupTabs__tab_active' : 'popupTabs__tab'
									}
									onClick={() => this.setState({ tab: 1 })}
								>
									Тех. поддержка
								</div>
								<div
									className={
										this.state.tab == 2
											? 'popupTabs__tab popupTabs__tab_active'
											: this.props.deals.length == 0
											? 'popupTabs__tab popupTabs__tab_disabled'
											: 'popupTabs__tab'
									}
									onClick={() => (this.props.deals.length > 0 ? this.setState({ tab: 2 }) : '')}
								>
									постпродажное обслуживание
									{this.props.deals.length == 0 && (
										<span>
											Уважаемый клиент, форма пост-продажного обслуживания доступа только на этапе
											«Договор зарегистрирован»
										</span>
									)}
								</div>
							</div>
							{this.state.tab == 1 && (
								<div className="popupTabs__content">
									<div className="modal-title">
										Задать вопрос в службу <br /> технической поддержки
									</div>
									<div className="form-content">
										<div className="modal-text">
											<div>
												Если вы не нашли ответа на ваш вопрос, заполните форму ниже, и мы
												свяжемся с вами по указанному адресу электронной почты.
											</div>
										</div>
										<InputField
											triggerValidate={this.state.triggerValidate}
											setFieldValid={this.setFieldValid}
											change={this.handleChange}
											className="ico-profile"
											{...this.state.name}
											readOnly={this.props.userInfo.first_name && true}
										/>
										<InputField
											triggerValidate={this.state.triggerValidate}
											setFieldValid={this.setFieldValid}
											change={this.handleChange}
											className="ico-email"
											{...this.state.email}
											readOnly={this.props.userInfo.email && 'readonly'}
										/>
										<div className="add-email">
											<span
												className="add-email__icon"
												onClick={() =>
													this.setState({
														email2: {
															...this.state.email2,
															visible: !this.state.email2.visible
														}
													})
												}
											>
												{this.state.email2.visible ? '-' : '+'}
											</span>
											<div
												className={
													this.state.email2.visible
														? 'add-email__input visible'
														: 'add-email__input'
												}
											>
												<InputField
													triggerValidate={this.state.triggerValidate}
													setFieldValid={this.setFieldValid}
													change={this.handleChange}
													className="ico-email"
													{...this.state.email2}
												/>
											</div>
										</div>
										<InputField
											triggerValidate={this.state.triggerValidate}
											setFieldValid={this.setFieldValid}
											change={this.handleChange}
											{...this.state.question}
										/>
										<FileDrop
											className={this.state.fileError ? 'file-drop error' : 'file-drop'}
											onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
											onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
											onFrameDrop={(event) => console.log('onFrameDrop', event)}
											onDragOver={(event) => console.log('onDragOver', event)}
											onDragLeave={(event) => console.log('onDragLeave', event)}
											onDrop={(files, event) => {
												console.log('onDrop!', files, event)

												let handleDropImages
												if (files.length === 0) {
													return
												} else if (files.length > 1) {
													this.setState({ file: null, fileError: 1 })
												} else if (files[0].size > 5242880) {
													this.setState({ file: null, fileError: 2 })
												} else {
													this.setState({ file: files[0], fileError: null })
												}
											}}
										>
											<input
												type="file"
												onChange={(e) =>
													this.setState({ file: e.target.files[0], fileError: null })
												}
											/>
											{console.log(this.state.file ? this.state.file.name : '')}
											{this.state.file
												? this.state.file.name
												: !this.state.fileError
												? 'Загрузить файл'
												: ''}
											{this.state.fileError == 1 && 'Вы загружаете слишком много файлов'}
											{this.state.fileError == 2 && 'Загружаемый файл больше 5Mb'}
										</FileDrop>
										<div className="input-field btn-field" onClick={this.handleSend}>
											<div className="btn">отправить</div>
										</div>
									</div>
								</div>
							)}
							{this.state.tab == 2 && (
								<div className="popupTabs__content">
									<PpoForm deals={this.props.deals} />
								</div>
							)}
						</div>
					</div>

					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	handleSend = (resend) => {
		this.setState({ triggerValidate: true })
		//console.log('     .....       ' + this.formIsValid())
		if (!this.formIsValid() || this.props.pending) return false
		/*console.log({
			cmd: 'faq_new',
			name: this.state.name.value || this.props.userInfo.first_name,
			email: this.state.email.value || this.props.userInfo.email,
			email2: this.state.email2.valid ? this.state.email2.value : '',
			question: this.state.question.value,
			file: this.state.file
		})*/
		this.props.sendQuestion({
			cmd: 'faq_new',
			name: this.state.name.value || this.props.userInfo.first_name,
			email: this.state.email.value || this.props.userInfo.email,
			email2: this.state.email2.valid ? this.state.email2.value : '',
			question: this.state.question.value,
			file: this.state.file
		})
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			const field = this.state[name]
			if (name == 'email2' && !field.visible) return true
			if (name == 'file' || (name == 'fileError' && field == null)) return true
			if (name == 'fileError' && field != null) return false
			if (!field.validate) return true
			return field.valid
		})
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}
}

export default connect(
	(state) => ({
		userInfo: userInfoSelector(state),
		authUser: authUserSelector(state),
		user: state.auth.user,
		deals: ppoDealsSelector(state),
		loadedDeals: state.deals.loaded,
		loadingDeals: state.deals.loading,
		pending: pendingSelector(state),
		fulfilled: fulfilledSelector(state),
		error: errorSelector(state),
		formError: state.form.ppo.error,
		formSuccess: state.form.ppo.send
	}),
	{ sendQuestion, closeForm, closeSuccess, getUserInfo, closeFormError }
)(Question)
