import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Counter from './counter'
import { signOut } from '../../redux/modules/auth'

class ErrorMessage extends Component {
	static propTypes = {
		error: PropTypes.string.isRequired,
		close: PropTypes.func.isRequired
	}

	id = null

	componentDidMount() {
		this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.close, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		const { logout_message } = this.props
		return (
			<div className="modal modal-error" ref={(el) => (this.container = el)}>
				<div className="modal__overlay"></div>
				<div className="modal__container">
					<div className="modal__body">
						<div className="modal-title">Ошибка!</div>
						<div className="form-content">
							{logout_message ? (
								<div className="modal-text">Время сессии истекло, пожалуйста, авторизуйтесь снова</div>
							) : (
								<div className="modal-text" dangerouslySetInnerHTML={{ __html: this.props.error }} />
							)}
							{logout_message ? (
								<div
									className="btn"
									onClick={() => {
										this.props.signOut(true)
									}}
								>
									<span>Авторизация</span>
								</div>
							) : (
								<div className="btn" onClick={this.hide}>
									<span>Закрыть</span>
								</div>
							)}
						</div>
						{/*<div className="modal-text modal-text--bottom">
							Это окно будет скрыто автоматически через <Counter callback={this.hide} /> секунд
						</div>*/}
					</div>
					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}
}

export default connect(
	(state) => ({
		logout_message: state.auth.logout_message
	}),
	{ signOut }
)(ErrorMessage)
