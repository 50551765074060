import { createBrowserHistory } from 'history'
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
	gtmId: 'GTM-WS9DHKW'
}

const history = createBrowserHistory()
const sendtg = (pn) => {
	const path = pn.split('/')[1]
	if (path === 'sign-in' || path === 'favorite-frame' || path === 'booking-frame') {
		TagManager.initialize(tagManagerArgs)
	}
}
sendtg(history.location.pathname)
history.listen((location, action) => sendtg(location.pathname))

export default history
