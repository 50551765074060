import axios from 'axios'
import { api } from '../config'
// import { createSelector } from 'reselect'

/**
 * Constants
 * */
export const moduleName = 'form'
export const LOAD_START = `${moduleName}/LOAD_START`
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`
export const LOAD_ERROR = `${moduleName}/LOAD_ERROR`
export const CLOSE_ERROR = `${moduleName}/CLOSE_ERROR`

export const CLOSE_FORM = `${moduleName}/CLOSE_FORM`
export const OPEN_FORM = `${moduleName}/OPEN_FORM`
export const CLOSE_SUCCESS = `${moduleName}/CLOSE_SUCCESS`

export const SEND_START = `${moduleName}/SEND_START`
export const SEND_SUCCESS = `${moduleName}/SEND_SUCCESS`
export const SEND_ERROR = `${moduleName}/SEND_ERROR`

/**
 * Reducer
 * */
const defaultState = {
	ppo: {
		open: false,
		send: false,
		pending: false,
		error: null,
		objectId: '7f366653-606d-e711-80fb-005056994571'
	}
}

export default (state = defaultState, action) => {
	const { type, payload } = action

	switch (type) {
		case OPEN_FORM:
			return { ...state, ppo: { ...state.ppo, open: true } }

		case CLOSE_FORM:
			return { ...state, ppo: { ...state.ppo, open: false } }

		case SEND_START:
			return { ...state, ppo: { ...state.ppo, pending: true } }

		case SEND_ERROR:
			return { ...state, ppo: { ...state.ppo, pending: false, open: false, error: payload.message } }

		case SEND_SUCCESS:
			return { ...state, ppo: { ...state.ppo, pending: false, open: false, send: true } }

		case CLOSE_SUCCESS:
			return { ...state, ppo: { ...state.ppo, send: false } }

		case CLOSE_ERROR:
			return { ...state, ppo: { ...state.ppo, error: null } }

		default:
			return state
	}
}

/**
 * Selectors
 * */

/**
 * Action Creators
 * */

export const closeFormSuccess = () => {
	return {
		type: CLOSE_SUCCESS
	}
}
export const closeFormError = () => {
	return {
		type: CLOSE_SUCCESS
	}
}
export const openForm = () => {
	return {
		type: OPEN_FORM
	}
}
export const closeForm = () => {
	return {
		type: CLOSE_FORM
	}
}

export const sendPpo = (params) => async (dispatch) => {
	// dispatch({ type: SEND_START })
	var formData = new FormData()
	// formData.append('cmd', params.cmd)
	// formData.append('name', params.name)
	// formData.append('email', params.email)
	// formData.append('email2', params.email2)
	// formData.append('question', params.question)
	// formData.append('file', params.file)

	try {
		const { data } = await axios.post(api, formData)
		if (data.status) dispatch({ type: SEND_SUCCESS })
	} catch (error) {
		dispatch({ type: SEND_ERROR, payload: { message: 'у нас пока нет апи' } })
		// dispatch({ type: SEND_ERROR, payload:{ message: error.response.data.message} })
	}
}
