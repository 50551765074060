import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { loadMailing } from '../../redux/modules/mailing'

import layout from '../../decorators/layout-mailing'
import Loader from '../common/loader'

import Table from './table'
import Filter from './filter'
import Send from './send'
import Modal from './modal'
import './style.css'

class Mailing extends Component {
	componentDidMount() {
		document.title = 'Рассылка - Сервис онлайн-покупки PSN group'
		const { loadMailing, user } = this.props
		loadMailing({
			cmd: 'marketing_get',
			email: user.email,
			token: user.token
		})
	}

	render() {
		const { filtrated, user, loaded } = this.props
		if (!user || !user.is_developer || !loaded) return <Loader />
		return (
			<Fragment>
				<div className="content middle mail">
					<div className="title">рассылка клиентам</div>

					<Filter scrollIntoView={this.scrollIntoView} />
					<span ref={(r) => (this.table = r)} />
					<Table />
					<Send />
				</div>
				{this.props.modal && <Modal />}
			</Fragment>
		)
	}

	scrollIntoView = () => this.table.scrollIntoView({ behavior: 'smooth' })
}

export default connect(
	(state) => ({
		user: state.auth.user,
		loaded: state.mailing.loaded,
		loading: state.mailing.loading,
		modal: state.mailing.modal
	}),
	{ loadMailing }
)(layout(Mailing))
