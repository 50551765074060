import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../../common/checkbox'
import { buildingOptions, changeCheckbox } from '../../../redux/modules/mailing'

class Rooms extends Component {
	render() {
		const { buildings, options, checkedList } = this.props
		console.log(buildings)
		return (
			<div className="checkbox-fields">
				{options.map((el) => (
					<Checkbox
						change={() => this.changeCheckbox(el)}
						title={el.BuildingGroupName.replace(/^[0-9][0-9]\./g, '')}
						checked={buildings.indexOf(el.BuildingGroupId) > -1}
					/>
				))}
			</div>
		)
	}

	changeCheckbox = (el) => {
		const { buildings } = this.props
		const newArray =
			buildings.indexOf(el.BuildingGroupId) > -1
				? buildings.filter((id) => el.BuildingGroupId !== id)
				: [...buildings, el.BuildingGroupId]
		this.props.changeCheckbox('buildings', newArray)
	}
}

export default connect(
	(state) => ({
		buildings: state.mailing.filter.buildings,
		options: buildingOptions(state)
	}),
	{ changeCheckbox }
)(Rooms)

// polyanka: {
//   name: 'polyanka',
//   title: 'Полянка/44',
//   checked: false
// },
// grinada: {
//   name: 'grinada',
//   title: 'Гринада',
//   checked: false
// },
// sreda: {
//   name: 'sreda',
//   title: 'Sreda',
//   checked: false
// },
// domashniy: {
//   name: 'domashniy',
//   title: 'Домашний',
//   checked: false
// },
