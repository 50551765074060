import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SelectField extends Component {
	static propTypes = {
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.string.isRequired,
		valid: PropTypes.bool.isRequired,

		setFieldValid: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value !== this.props.value) {
			console.log(this.isValidField())
			this.props.setFieldValid(this.props.name, this.isValidField())
		}
	}

	render() {
		return (
			<div className={this.getClassName()}>
				<select name={this.props.name} value={this.props.value} onChange={this.props.change}>
					{this.options}
				</select>
			</div>
		)
	}

	get options() {
		return this.props.options.map((el, i) => (
			<option key={i} value={el.value}>
				{el.label}
			</option>
		))
	}

	getClassName = () => {
		const valid = this.props.valid || (!this.props.value && !this.props.triggerValidate) ? ' valid' : ' error'
		return 'input-field select-field ' + valid
	}

	isValidField = () => {
		if (this.props.value === '') return false
		return true
	}
}

export default SelectField
