import React, { Component } from 'react'
import { connect } from 'react-redux'
import { makeBooking } from '../../redux/modules/booking'

class Loader extends Component {
	id = null

	render() {
		return (
			<div className="crm_loader">
				Бронирование произойдет в течение нескольких минут <br /> <img src="/images/loading.gif" alt="" />{' '}
			</div>
		)
	}
}

export default connect(
	(state) => ({
		user: state.auth.user,
		not_synced: state.deals.not_synced,
		refresh_after: state.deals.refresh_after
	}),
	{ makeBooking }
)(Loader)
