import axios from 'axios'
import { api } from '../config'
import { push, replace } from 'connected-react-router'
// import { createSelector } from 'reselect'

/**
 * Constants
 * */
export const moduleName = 'auth'

export const SIGN_UP_START = `${moduleName}/SIGN_UP_START`
export const SIGN_UP_SUCCESS = `${moduleName}/SIGN_UP_SUCCESS`
export const SIGN_UP_ERROR = `${moduleName}/SIGN_UP_ERROR`

export const SIGN_IN_START = `${moduleName}/SIGN_IN_START`
export const SIGN_IN_SUCCESS = `${moduleName}/SIGN_IN_SUCCESS`
export const SIGN_IN_ERROR = `${moduleName}/SIGN_IN_ERROR`

export const ERROR_CLOSE = `${moduleName}/ERROR_CLOSE`
export const SIGN_OUT = `${moduleName}/SIGN_OUT`

export const RESET_PASSWORD_START = `${moduleName}/RESET_PASSWORD_START`
export const RESET_PASSWORD_SUCCESS = `${moduleName}/RESET_PASSWORD_SUCCESS`
export const RESET_PASSWORD_ERROR = `${moduleName}/RESET_PASSWORD_ERROR`

export const NEW_PASSWORD_START = `${moduleName}/NEW_PASSWORD_START`
export const NEW_PASSWORD_SUCCESS = `${moduleName}/NEW_PASSWORD_SUCCESS`
export const NEW_PASSWORD_ERROR = `${moduleName}/NEW_PASSWORD_ERROR`

export const SET_ACCESS = `${moduleName}/SET_ACCESS`
export const STEP_AUTH = `${moduleName}/STEP_AUTH`

export const CLOSE_SIGN_UP_SUCCESS = `${moduleName}/CLOSE_SIGN_UP_SUCCESS`

/**
 * Reducer
 * */
const defaultState = {
	checkedAuthorized: false,
	loading: false,
	error: null,
	user: null,
	attemptsSingIn: 0,
	attemptsSingUp: 0,
	resetPasswordEmail: null,
	newPasswordSuccess: false,
	step: 1,
	access: false,
	showIframe: false,
	sign_up_success: false,

	logout_message: false
}

export default (state = defaultState, action) => {
	const { type, user, error, email, payload } = action

	switch (type) {
		case SIGN_UP_START:
			return { ...state, loading: true }

		case SIGN_UP_SUCCESS:
			return { ...state, loading: false, sign_up_success: true, user, showIframe: payload.showIframe }

		case SIGN_UP_ERROR:
			return {
				...state,
				loading: false,
				attemptsSingUp: state.attemptsSingUp + 1,
				error,
				showIframe: payload.showIframe
			}

		case CLOSE_SIGN_UP_SUCCESS:
			return { ...state, error: null, sign_up_success: false }

		case ERROR_CLOSE:
			return { ...state, error: null }

		case SIGN_IN_START:
			return { ...state, loading: true }

		case SIGN_IN_SUCCESS:
			return {
				...state,
				loading: false,
				user,
				checkedAuthorized: true,
				step: payload.step,
				showIframe: payload.showIframe
			}

		case SIGN_IN_ERROR:
			return {
				...state,
				loading: false,
				attemptsSingIn: state.attemptsSingIn + 1,
				error,
				showIframe: payload.showIframe,
				checkedAuthorized: true
			}

		case SIGN_OUT:
			if (payload.reload) window.location.href = '/sign-in'
			return { ...state, showIframe: payload.showIframe, checkedAuthorized: true }

		case RESET_PASSWORD_START:
			return { ...state, loading: true }

		case RESET_PASSWORD_SUCCESS:
			return { ...state, loading: false, resetPasswordEmail: email }

		case RESET_PASSWORD_ERROR:
			return { ...state, loading: false, error, resetPasswordEmail: null }

		case NEW_PASSWORD_START:
			return { ...state, loading: true }

		case NEW_PASSWORD_SUCCESS:
			return { ...state, loading: false, newPasswordSuccess: true }

		case NEW_PASSWORD_ERROR:
			return { ...state, loading: false, error, newPasswordSuccess: false }

		case STEP_AUTH:
			return { ...state, loading: false, step: payload.step }

		case SET_ACCESS:
			return { ...state, loading: false, access: payload.access }

		case 'LOG_OUT_MESSAGE':
			return { ...state, logout_message: true }

		default:
			return state
	}
}

/**
 * Selectors
 * */
export const authLoadingSelector = (state) => state[moduleName].loading
export const authUserSelector = (state) => state[moduleName].user
export const authErrorSelector = (state) => state[moduleName].error
export const attemptsSingInSelector = (state) => state[moduleName].attemptsSingIn
export const attemptsSingUpSelector = (state) => state[moduleName].attemptsSingUp
export const resetPasswordEmailSelector = (state) => state[moduleName].resetPasswordEmail
export const newPasswordSuccessSelector = (state) => state[moduleName].newPasswordSuccess

/**
 * Action Creators
 * */

export const checkSession = (params) => async (dispatch) => {
	const user = JSON.parse(params)
	try {
		const { data } = await axios.get(api, {
			params: {
				cmd: 'session_check',
				email: user.email,
				token: user.token
			}
		})

		if (data.status) dispatch({ type: SIGN_IN_SUCCESS, user, payload: { step: 3, showIframe: true } })
	} catch (error) {
		console.log(error)
		dispatch({ type: SIGN_IN_ERROR, removeLocalStorage: 'user', payload: { showIframe: true } })
	}
}

export const setAccess = (access) => {
	return {
		type: SET_ACCESS,
		payload: { access }
	}
}

export const signIn = (params, attempts) => async (dispatch) => {
	dispatch({ type: SIGN_IN_START })

	if (attempts > 4) return dispatch({ type: SIGN_IN_ERROR, error: 'Превышен лимит попыток авторизации' })

	try {
		const { data } = await axios.get(api, { params })

		if (data.status) {
			const user = { ...data, email: params.email }

			dispatch({
				type: SIGN_IN_SUCCESS,
				setLocalStorage: { name: 'user', value: user },
				payload: { step: 3, showIframe: true },
				user
			})
		}
	} catch (error) {
		console.log(error)
		if (error.response)
			dispatch({
				type: SIGN_IN_ERROR,
				error: error.response.data.message,
				removeLocalStorage: 'user',
				payload: { showIframe: true }
			})
	}
}

export const signUp = (params, attempts, step) => async (dispatch) => {
	dispatch({ type: SIGN_UP_START })

	if (attempts > 2) return dispatch({ type: SIGN_UP_ERROR, error: 'Превышен лимит' })

	try {
		const { data } = await axios.get(api, { params })

		if (data.status) {
			const user = { ...data, email: params.email }

			if (step) {
				return dispatch({
					type: STEP_AUTH,
					payload: { step: 4 }
				})
			}

			dispatch({
				type: SIGN_UP_SUCCESS,
				setLocalStorage: { name: 'user', value: user },
				payload: { showIframe: true },
				user
			})
		}
	} catch (error) {
		if (step) {
			return dispatch({ type: STEP_AUTH, payload: { step: 2 } })
		}
		if (error.response)
			dispatch({ type: SIGN_UP_ERROR, error: error.response.data.message, payload: { showIframe: true } })
	}
}

export const signOut = (reload) => async (dispatch) => {
	dispatch({
		type: SIGN_OUT,
		removeLocalStorage: 'user',
		payload: { reload, showIframe: true }
	})
}

export const closeErrorMessage = () => {
	return {
		type: ERROR_CLOSE
	}
}

export const resetPassword = (params) => async (dispatch) => {
	dispatch({ type: RESET_PASSWORD_START })

	try {
		const { data } = await axios.get(api, { params })

		if (data.status) {
			dispatch({
				type: RESET_PASSWORD_SUCCESS,
				email: params.email
			})
		}
	} catch (error) {
		console.log(error)
		if (error.response) dispatch({ type: RESET_PASSWORD_ERROR, error: error.response.data.message })
	}
}

export const newEmail = (params) => async (dispatch) => {
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) {
			const user = { ...data, email: params.new_email }
			await dispatch({
				type: SIGN_IN_SUCCESS,
				setLocalStorage: { name: 'user', value: user },
				payload: { step: 3 },
				user
			})

			dispatch(replace('/favorites'))
		}
	} catch (error) {
		console.log(error)
		dispatch({ type: SIGN_OUT, payload: { reload: true }, removeLocalStorage: 'user' })
	}
}

export const newPassword = (params) => async (dispatch) => {
	dispatch({ type: NEW_PASSWORD_START })

	try {
		const { data } = await axios.get(api, { params })

		if (data.status) dispatch({ type: NEW_PASSWORD_SUCCESS })
	} catch (error) {
		console.log(error)
		if (error.response) dispatch({ type: NEW_PASSWORD_ERROR, error: error.response.data.message })
	}
}

export const closeSignUpSuccess = () => {
	return {
		type: CLOSE_SIGN_UP_SUCCESS
	}
}
