import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import layout from '../../decorators/layout'

class ErrorPage extends Component {
	componentDidMount() {
		document.title = '404 - Сервис онлайн-покупки PSN group'
	}

	render() {
		return (
			<div className="error-page">
				<div className="content2 header">
					<Link to="/deals" className="logo ico-1"></Link>
				</div>
				<div className="content2 error-page__body animate-fade">
					<div>
						<img src="/images/404.png" alt="" />
					</div>
					Неправильно набран адрес или такой страницы не существует.
					<div>
						Вернитесь в <Link to="/deals">личный кабинет</Link>
					</div>
				</div>
			</div>
		)
	}
}

export default ErrorPage
