import axios from 'axios'
import { api } from '../config'
import { createSelector } from 'reselect'
import { distinct, objectToMap, arrayToMap } from '../../services/utils'
import deals from '../mocks/deals'
import deal from '../mocks/add-deal'
import { statusCode, statusCodeDefault } from '../../services/constants'

/**
 * Constants
 * */
export const moduleName = 'deals'
export const LOAD_START = `${moduleName}/LOAD_START`
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`
export const LOAD_ERROR = `${moduleName}/LOAD_ERROR`
export const CLOSE_ERROR = `${moduleName}/CLOSE_ERROR`
export const CHANGE_STATUS = `${moduleName}/CHANGE_STATUS`
export const REMOVE_START = `${moduleName}/REMOVE_START`
export const REMOVE_SUCCESS = `${moduleName}/REMOVE_SUCCESS`
export const REMOVE_ERROR = `${moduleName}/REMOVE_ERROR`
export const ADD_START = `${moduleName}/ADD_START`
export const ADD_SUCCESS = `${moduleName}/ADD_SUCCESS`
export const ADD_ERROR = `${moduleName}/ADD_ERROR`
export const DEAL_ERROR_CRM = `${moduleName}/DEAL_ERROR_CRM`
export const CLOSE_REMOVE_SUCCESS = `${moduleName}/CLOSE_REMOVE_SUCCESS`
export const CLOSE_ADD_SUCCESS = `${moduleName}/CLOSE_ADD_SUCCESS`
export const SET_DISBLED = `${moduleName}/SET_DISBLED`
export const SET_UPDATED = `${moduleName}/SET_UPDATED`
export const STATUS_ERROR = `${moduleName}/STATUS_ERROR`

/**
 * Reducer
 * */
const defaultState = {
	loading: false,
	loaded: false,
	load_error: null,
	error: null,

	entities: [],
	detail: null,

	filter: {
		status: null
	},

	remove_loading: false,
	remove_success: false,
	add_loading: false,
	add_success: false,
	not_synced: undefined,
	refresh_after: 5000,
	dealErrorCrm: false,
	disabled: false,
	updated: false,
	objectType: 1,
	dealErrorCrmFav: false
}

export default (state = defaultState, action) => {
	const { type, error, payload } = action

	switch (type) {
		case LOAD_START:
			return { ...state, loading: true }

		case LOAD_ERROR:
			return { ...state, loading: false, load_error: error }

		case LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				entities: payload.deals,
				not_synced: payload.not_synced,
				refresh_after: payload.refresh_after,
				dealErrorCrm: false
			}
		// return { ...state, loading: false, loaded: true, entities: payload.deals }

		case CLOSE_ERROR:
			return { ...state, error: null, load_error: null, dealErrorCrm: false, dealErrorCrmFav: false }

		case CHANGE_STATUS:
			return { ...state, filter: { ...state.filter, status: payload.status } }

		case REMOVE_START:
			return { ...state, remove_loading: true }

		case REMOVE_ERROR:
			return { ...state, remove_loading: false, load_error: error }

		case REMOVE_SUCCESS:
			return {
				...state,
				remove_loading: false,
				remove_success: true
			}

		case CLOSE_REMOVE_SUCCESS:
			return {
				...state,
				remove_success: false
			}

		case ADD_START:
			return { ...state, add_loading: true }

		case ADD_ERROR:
			return { ...state, add_loading: false, error }

		case ADD_SUCCESS:
			return { ...state, add_loading: false, add_success: true, objectType: payload.objectType }

		case CLOSE_ADD_SUCCESS:
			return { ...state, add_success: false }

		case DEAL_ERROR_CRM:
			return { ...state, dealErrorCrm: true }

		case DEAL_ERROR_CRM + '_FAVORITE':
			return { ...state, dealErrorCrmFav: { object: payload.object, article: payload.article } }

		case SET_DISBLED:
			return { ...state, disabled: payload.disabled }

		case SET_UPDATED:
			return { ...state, updated: payload.updated }

		case STATUS_ERROR:
			return {
				...state,
				add_loading: false,
				error: statusCode[payload.statusCode] ? statusCode[payload.statusCode] : statusCodeDefault
			}

		default:
			return state
	}
}

/**
 * Selectors
 * */
export const errorSelector = (state) => state[moduleName].error
export const loadingSelector = (state) => state[moduleName].loading
export const loadedSelector = (state) => state[moduleName].loaded
export const dealsSelector = (state) => state[moduleName].entities
export const statusSelector = (state) => state[moduleName].filter.status
export const removeLoadingSelector = (state) => state[moduleName].remove_loading
export const addLoadingSelector = (state) => state[moduleName].add_loading

export const groupSelector = createSelector(dealsSelector, (deals) => objectToMap(deals, 'StatusGroupID'))
export const dealsMapSelector = createSelector(dealsSelector, (deals) => arrayToMap(deals, 'dealId'))

export const filtratedSelector = createSelector(dealsSelector, statusSelector, (deals, status) => {
	return deals.filter((deal) => !status || deal.StatusID === status)
})
export const activeSelector = createSelector(filtratedSelector, (deals) => {
	return deals.filter((deal) => {
		const item1 = deal.history.find((el) => el.stepId === '11')
		const item2 = deal.history.find((el) => el.stepId === '10')
		if ((item1 && item1.startDateTime) || (item2 && item2.startDateTime)) return false
		return true
	})
})
export const inactiveSelector = createSelector(filtratedSelector, (deals) => {
	return deals.filter((deal) => {
		const item1 = deal.history.find((el) => el.stepId === '11')
		const item2 = deal.history.find((el) => el.stepId === '10')
		if ((item1 && item1.startDateTime) || (item2 && item2.startDateTime)) return true
		return false
	})
})

export const ppoDealsSelector = createSelector(activeSelector, (deals) => {
	return deals.filter((deal) => {
		console.log(deal)
		// return deal.paymentSum > 0
		return deal
	})
})

// export const ppoDealsSelector = createSelector(dealsSelector, (deals) => objectToMap(deals, 'objectId'))
/**
 * Action Creators
 * */

export const loadDeals = (params) => async (dispatch) => {
	dispatch({ type: LOAD_START })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) {
			dispatch({
				type: LOAD_SUCCESS,
				payload: { deals: data.deals, not_synced: data.not_synced, refresh_after: data.refresh_after }
			})
		}
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}

		if (error.response && error.response.data && error.response.data.error_code === 3) {
			dispatch({ type: DEAL_ERROR_CRM })
		} else {
			if (error.response && error.response.data)
				dispatch({ type: LOAD_ERROR, error: error.response.data.message })
		}
	}
}

export const addDeal = (params) => async (dispatch) => {
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) {
			await dispatch({ type: ADD_SUCCESS, payload: { objectType: params.objectType } })
			dispatch(
				loadDeals({
					cmd: 'user_deals',
					email: params.email,
					token: params.token
				})
			)
		}
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 6) {
			return dispatch({ type: STATUS_ERROR, payload: { statusCode: params.statusCode } })
		}
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		if (error.response && error.response.data && error.response.data.error_code === 3) {
			dispatch({
				type: DEAL_ERROR_CRM + '_FAVORITE',
				payload: { article: params.article, object: params.object }
			})
		}
		if (error.response && error.response.data && error.response.data.error_code !== 3) {
			dispatch({ type: ADD_ERROR, error: error.response.data.message })
		}
	}
}

export const removeDeal = (params) => async (dispatch) => {
	dispatch({ type: REMOVE_START })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) {
			await dispatch({ type: REMOVE_SUCCESS })
			dispatch(
				loadDeals({
					cmd: 'user_deals',
					email: params.email,
					token: params.token
				})
			)
		}
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		dispatch({ type: REMOVE_ERROR, error: error.response.data.message })
	}
}

export const closeError = () => {
	return {
		type: CLOSE_ERROR
	}
}

export const changeStatus = (status) => {
	return {
		type: CHANGE_STATUS,
		payload: { status }
	}
}

export const closeRemoveSuccess = () => ({
	type: CLOSE_REMOVE_SUCCESS
})

export const closeAddSuccess = () => ({
	type: CLOSE_ADD_SUCCESS
})

export const setDisbled = (disabled) => ({
	type: SET_DISBLED,
	payload: { disabled }
})

export const setUpdated = (updated) => ({
	type: SET_UPDATED,
	payload: { updated }
})
