import React, { Fragment } from 'react'
import Header from '../components/layout/header'
import Footer from '../components/layout/footer'
import Icons from '../components/layout/icons'
import Cookie from '../components/common/cookie'

export default (OriginalComponent) =>
	class LayoutComponent extends React.Component {
		render() {
			return (
				<Fragment>
					<Icons type="mobile" />
					<div className="page">
						<Header />
						<OriginalComponent {...this.props} />
						<Footer />
					</div>
					<Cookie />
				</Fragment>
			)
		}
	}
