import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import { changeEmailFormSentSelector, errorSelector, closeChangeEmailSuccess } from '../../redux/modules/profile'

class ChangeEmail extends Component {
	state = {
		resending: false
	}

	id = null

	componentDidMount() {
		if (this.container) this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.closeChangeEmailSuccess, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		return (
			<div className="modal modal-profile-email-message" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.hide}></div>
				<div className="modal__container">
					<div className="modal__body">
						<div className="modal-close ico-close" onClick={this.hide}></div>
						<div className="modal-title">Проверьте вашу электронную почту</div>
						<div className="form-content">
							<div className="modal-text">
								<p>
									Мы {this.state.resending && 'повторно'} отправили письмо на адрес электронной почты{' '}
									<span className="color-orange">{this.props.email}</span>. Перейдите по ссылке в
									письме для изменения данных.
								</p>
								<p>
									Если письма нет во входящих, проверьте другие папки (например, «Спам», «Социальные
									сети» и т.д.)
								</p>
								{this.state.resending || (
									<p>
										<span className="link" onClick={this.handleResend}>
											Мне не пришло письмо
										</span>
									</p>
								)}
							</div>
						</div>
					</div>

					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	handleResend = () => {
		this.props.send(true)
		this.setState({ resending: true })
	}
}

export default connect(
	(state) => ({
		sent: changeEmailFormSentSelector(state),
		error: errorSelector(state)
	}),
	{ closeChangeEmailSuccess }
)(ChangeEmail)
