import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import SelectField from '../common/select-field'
import {
	userInfoSelector,
	openChangeEmailForm,
	userInfoUpdate,
	loadingSelector,
	confirmPhoneSelector,
	sendSmsReload
} from '../../redux/modules/profile'
import Button from '../common/button'

class Personal extends Component {
	state = {
		loading: false,
		triggerValidate: false,
		last_name: {
			value: '',
			name: 'last_name',
			type: 'text',
			placeholder: 'Фамилия',
			validate: 'name',
			valid: false
		},
		first_name: {
			value: '',
			name: 'first_name',
			type: 'text',
			placeholder: 'Имя',
			validate: 'name',
			valid: false
		},
		second_name: {
			value: '',
			name: 'second_name',
			type: 'text',
			placeholder: 'Отчество',
			validate: 'second_name',
			valid: true
		},
		birthday: {
			value: '',
			name: 'birthday',
			type: 'text',
			placeholder: 'Дата рождения',
			validate: 'date',
			valid: false
		},
		phone: {
			value: '',
			name: 'phone',
			type: 'tel',
			placeholder: 'Телефон',
			validate: 'phone',
			valid: false
		},
		gender: {
			value: '',
			name: 'gender',
			validate: true,
			valid: false
		}
	}

	render() {
		return (
			<Fragment>
				<div className="profile-page-form__column">
					<div className="form-title">Личные данные:</div>
					{this.props.userInfo.first_name ? this.info : this.form}
				</div>
			</Fragment>
		)
	}

	get info() {
		const { userInfo } = this.props
		console.log(userInfo)
		return (
			<Fragment>
				{this.email}
				<div className="input-field--readonly">
					<span className="readonly-title">Фамилия</span>
					<div className="readonly-body">
						<span>{userInfo.last_name}</span>
					</div>
				</div>
				<div className="input-field--readonly">
					<span className="readonly-title">Имя</span>
					<div className="readonly-body">
						<span>{userInfo.first_name}</span>
					</div>
				</div>
				{userInfo.second_name && (
					<div className="input-field--readonly">
						<span className="readonly-title">Отчество</span>
						<div className="readonly-body">
							<span>{userInfo.second_name}</span>
						</div>
					</div>
				)}
				{userInfo.gender && (
					<div className="input-field--readonly">
						<span className="readonly-title">Пол</span>
						<div className="readonly-body">
							<span>{userInfo.gender.replace('F', 'женский').replace('M', 'мужской')}</span>
						</div>
					</div>
				)}
				<div className="input-field--readonly">
					<span className="readonly-title">Дата рождения</span>
					<div className="readonly-body">
						<span>{userInfo.birthday}</span>
					</div>
				</div>
				{userInfo.phone && (
					<div className="input-field--readonly">
						<span className="readonly-title">Телефон</span>
						<div className="readonly-body">
							<span>{userInfo.phone}</span>
						</div>
					</div>
				)}
				{this.props.deal || (
					<div className="profile-text">
						Если вы желаете изменить ваши данные,
						<br />
						вам необходимо обратиться в службу <br /> поддержки по телефону: <br />
						<a href="tel:+7 (495) 125-23-37">
							<span>+7 (495) 125-23-37</span>
						</a>
					</div>
				)}
			</Fragment>
		)
	}

	get form() {
		const { userInfo, loading } = this.props
		return (
			<Fragment>
				{this.email}
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.last_name}
					keyPressed={this.handleSend}
				/>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.first_name}
					keyPressed={this.handleSend}
				/>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.second_name}
					keyPressed={this.handleSend}
				/>
				<SelectField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.gender}
					keyPressed={this.handleSend}
				/>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					setDateValue={this.setDateValue}
					{...this.state.birthday}
					keyPressed={this.handleSend}
				/>

				{userInfo.phone ? (
					<div className="input-field--readonly">
						<span className="readonly-title">Телефон</span>
						<div className="readonly-body">
							<span>{userInfo.phone}</span>
						</div>
					</div>
				) : (
					<Fragment>
						{this.props.confirm || (
							<InputField
								readonly={this.props.sms_pending}
								triggerValidate={this.state.triggerValidate}
								setFieldValid={this.setFieldValid}
								change={this.handleChange}
								{...this.state.phone}
							/>
						)}
					</Fragment>
				)}

				<Button onClick={this.handleSend} loading={loading && this.state.loading}>
					Сохранить
					{/*this.props.deal ? 'Забронировать' : 'Сохранить'*/}
				</Button>
			</Fragment>
		)
	}

	get email() {
		return (
			<div className="input-field--readonly">
				<span className="readonly-title">Email</span>
				<div className="readonly-body readonly-email">
					<span>{this.props.userInfo.email}</span>
					{this.props.deal || (
						<div
							className="change-email ico-change-email js-change-email"
							onClick={this.props.openChangeEmailForm}
						/>
					)}
				</div>
			</div>
		)
	}

	handleSend = async () => {
		this.setState({ triggerValidate: true })
		if (!this.formIsValid() || this.props.loading) return false
		const birthday = this.state.birthday.value.split('.')
		this.setState({ loading: true })
		await this.props.userInfoUpdate({
			email: this.props.user.email,
			token: this.props.user.token,
			first_name: this.state.first_name.value,
			second_name: this.state.second_name.value,
			last_name: this.state.last_name.value,
			gender: this.state.gender.value,
			birthday: birthday[2] + '-' + birthday[1] + '-' + birthday[0],
			phone_not_confirm: this.state.phone.value
		})
		// if (this.props.makeBooking) {
		// 	await this.props.makeBooking()
		// }
		this.setState({ loading: false })
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			const field = this.state[name]
			if (field.name === 'phone' && this.props.userInfo.phone) return true
			if (!field.validate) return true
			return field.valid
		})
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		if (name === 'phone') this.props.sendSmsReload()
		this.setState({ [name]: { ...this.state[name], value } })
	}

	setDateValue = (value) => {
		this.setState({ birthday: { ...this.state.birthday, value } })
	}
}

export default connect(
	(state) => ({
		userInfo: userInfoSelector(state),
		user: state.auth.user,
		loading: loadingSelector(state),
		confirm: confirmPhoneSelector(state)
	}),
	{ openChangeEmailForm, userInfoUpdate, sendSmsReload }
)(Personal)
