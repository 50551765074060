import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ErrorMessage from '../common/error-message'
import {
	errorSelector,
	closeError,
	getUserInfo,
	userInfoSelector,
	loadingInfoSelector,
	changeEmailFormOpenSelector
} from '../../redux/modules/profile'
import { signOut, authUserSelector } from '../../redux/modules/auth'
import Fade from 'react-reveal/Fade'

import Personal from './personal'
import Password from './password'
// import Notifications from './notifications'
import ChangeEmailForm from './change-email-form'
import layout from '../../decorators/layout'

class Profile extends Component {
	componentDidMount() {
		const { user, userInfo, getUserInfo } = this.props
		if (user && !userInfo.email) getUserInfo({ email: user.email, token: user.token })
	}

	componentDidUpdate(prevProps) {
		const { user, userInfo, getUserInfo } = this.props
		if (prevProps.user !== user && user && !userInfo.email) getUserInfo({ email: user.email, token: user.token })
	}

	render() {
		if (!this.props.user || this.props.userLoading) return null
		if (this.props.deal) {
			return (
				<Fragment>
					<Personal
						deal={true}
						makeBooking={this.props.makeBooking}
						makeBookingLoading={this.props.makeBookingLoading}
					/>
				</Fragment>
			)
		}
		return (
			<Fragment>
				<Fade>
					<div className="content middle profile-page">
						<div className="title">
							Профиль
							<div className="title__link ico-logout" onClick={() => this.props.signOut(true)}>
								<span>выйти из личного кабинета</span>
							</div>
						</div>

						<div className="profile-page-form">
							<Personal deal={false} />
							<Password />
							{/*<Notifications />*/}
						</div>
					</div>
				</Fade>
				{this.props.changeEmailOpen && <ChangeEmailForm />}
				{this.props.error && <ErrorMessage error={this.props.error} close={this.props.closeError} />}
			</Fragment>
		)
	}
}

export default connect(
	(state) => ({
		user: authUserSelector(state),
		userLoading: loadingInfoSelector(state),
		error: errorSelector(state),
		userInfo: userInfoSelector(state),
		changeEmailOpen: changeEmailFormOpenSelector(state)
	}),
	{ closeError, signOut, getUserInfo }
)(Profile)
