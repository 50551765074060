import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { openForm } from '../../redux/modules/faq'

class Menu extends Component {
	render() {
		return (
			<div className="tabs__lef">
				{this.items}
				<div className="btn faq-btn" onClick={this.props.openForm}>
					<i className="ico-faq"></i>обратная связь
				</div>
			</div>
		)
	}

	get items() {
		const { sections } = this.props
		return Object.keys(sections).map((id) => {
			return (
				<NavLink to={`/faq/${sections[id].code}`}>
					<div className="tab-nav">{sections[id].title}</div>
				</NavLink>
			)
		})
	}
}

export default connect(null, { openForm })(Menu)
