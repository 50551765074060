import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import EnterEmail from './enter-email'
import EnterPassword from './enter-password'
import RegistrationSuccess from './registration-success'
import { setAccess } from '../../redux/modules/auth'
import {
	makeBooking,
	closeBookingError,
	loadingSelector,
	errorSelector,
	successSelector,
	closeBookingSuccess,
	errorObjectSelector,
	objectSelector
} from '../../redux/modules/booking'
import { closeError as closeProfileError } from '../../redux/modules/profile'
import Profile from '../profile/profile-inner'
import ErrorMessage from '../common/error-message'
import SuccessMessage from './success-message'
import Table from './table'
import layout from '../../decorators/layout'
import Button from '../common/button'
import SmsCode from '../../components/smc-code'
import Loader from './crm-loader'
import Loading from '../common/loader'

class Reservation extends Component {
	state = {
		triggerValidate: false,
		email: {
			value: '',
			name: 'email',
			type: 'email',
			placeholder: 'Email',
			validate: 'email',
			valid: false
		},
		password: {
			value: '',
			name: 'password',
			open: false,
			type: 'password',
			placeholder: 'Пароль',
			validate: 'password',
			valid: false
		},
		key: 1
	}

	componentDidMount() {
		this.props.setAccess(true)
		document.title = 'Бронирование - Сервис онлайн-покупки PSN group'
	}

	componentWillUnmount() {
		this.props.setAccess(false)
	}

	render() {
		if (this.props.objectLoading) return <Loading />
		return (
			<Fragment>
				<Fade>
					<div className="content middle make-reservation">
						<div className="title">забронировать</div>
						<Table id={this.props.id} article={this.props.article} />
						{this.authSteps}
					</div>
				</Fade>
				<SmsCode key={this.state.key} bookingPage={true} />
				{this.props.error && <ErrorMessage close={this.props.closeBookingError} error={this.props.error} />}
				{this.props.success && this.props.object && (
					<SuccessMessage objectType={this.props.object.Type} close={this.props.closeBookingSuccess} />
				)}
				{this.props.profileError && (
					<ErrorMessage error={this.props.profileError} close={this.props.closeProfileError} />
				)}
			</Fragment>
		)
	}

	get authSteps() {
		if (this.props.errorObject || !this.props.object) return null
		if (this.props.step === 1)
			return (
				<EnterEmail
					email={this.state.email}
					setFieldValid={this.setFieldValid}
					handleChange={this.handleChange}
				/>
			)
		if (this.props.step === 2)
			return (
				<EnterPassword
					email={this.state.email}
					password={this.state.password}
					setFieldValid={this.setFieldValid}
					handleChange={this.handleChange}
					open={this.state.password.open}
					passwordToggle={this.passwordToggle}
				/>
			)
		if (this.props.step === 3)
			return (
				<div className="make-reservation__profile">
					<Profile deal={true} makeBooking={this.handleSend} />

					{/*this.props.not_synced ? (
						<Loader id={this.props.id} article={this.props.article} />
					) : this.props.userInfo.first_name ? (
						<Button onClick={this.handleSend} loading={this.props.loading}>
							Забронировать
						</Button>
					) : null*/}
					{this.props.userInfo.first_name && (
						<Button onClick={this.handleSend} loading={this.props.loading}>
							Забронировать
						</Button>
					)}
				</div>
			)

		if (this.props.step === 4)
			return (
				<RegistrationSuccess
					email={this.state.email}
					password={this.state.password}
					setFieldValid={this.setFieldValid}
					handleChange={this.handleChange}
					open={this.state.password.open}
					passwordToggle={this.passwordToggle}
				/>
			)
	}

	passwordToggle = () => {
		this.setState({
			password: {
				...this.state.password,
				open: !this.state.password.open,
				type: this.state.password.open ? 'password' : 'text'
			}
		})
	}

	handleSend = () => {
		if (!this.props.userInfo.crm_id) return this.setState({ key: this.state.key + 1 })
		if (this.props.loading) return false
		this.props.makeBooking({
			cmd: 'book',
			email: this.props.user.email,
			token: this.props.user.token,
			object: this.props.id,
			article: this.props.article
		})
		this.setState({ key: this.state.key + 1 })
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}
}

export default connect(
	(state) => ({
		step: state.auth.step,
		user: state.auth.user,
		error: errorSelector(state),
		loading: loadingSelector(state),
		success: successSelector(state),
		objectLoading: state.booking.objectLoading,

		object: objectSelector(state),
		errorObject: errorObjectSelector(state),
		not_synced: state.deals.not_synced,
		userInfo: state.profile.info,
		profileError: state.profile.error
	}),
	{ setAccess, makeBooking, closeBookingError, closeBookingSuccess, closeProfileError }
)(layout(Reservation))
