import { push, replace } from 'connected-react-router'

export function getPage(url, lastURL) {
	return (dispatch) => {
		dispatch(push(url))
	}
}
export function replacePage(url) {
	return (dispatch) => {
		dispatch(replace(url))
	}
}
