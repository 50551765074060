import React, { Component, Fragment } from 'react'
import Row from './row'

class Table extends Component {
	render() {
		const { list, title, cancel } = this.props
		if (!list.length) return null
		return (
			<div className="table deal-table">
				<div className="table-title">{title}</div>
				<table>
					<thead>
						<tr>
							<th className="title-num">№</th>
							<th className="title-img"></th>
							<th className="title-text">Информация об&nbsp;объекте</th>
							<th className="title-type">Тип</th>
							<th className="title-square nowrap">
								Площадь, м
								<sup>
									<small>2</small>
								</sup>
							</th>
							<th className="title-price nowrap">Стоимость, ₽</th>
							<th className="title-icon">PDF</th>
							<th>Статус</th>
							{cancel || <th className="title-text2">Действия</th>}
						</tr>
					</thead>
					<tbody>{this.rows}</tbody>
				</table>
			</div>
		)
	}

	get rows() {
		return this.props.list.map((el, i) => <Row cancel={this.props.cancel} key={i} el={el} />)
	}
}

export default Table
