import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import history from './history'
import mobileReducer, { moduleName as mobileModule } from './modules/mobile'
import authReducer, { moduleName as authModule } from './modules/auth'
import profileReducer, { moduleName as profileModule } from './modules/profile'
import bookingReducer, { moduleName as bookingModule } from './modules/booking'
import dealsReducer, { moduleName as dealsModule } from './modules/deals'
import favoritesReducer, { moduleName as favoritesModule } from './modules/favorites'
import faqReducer, { moduleName as faqModule } from './modules/faq'
import mailingReducer, { moduleName as mailingModule } from './modules/mailing'
import formReducer, { moduleName as formModule } from './modules/form'

export default combineReducers({
	router: connectRouter(history),
	[mobileModule]: mobileReducer,
	[authModule]: authReducer,
	[profileModule]: profileReducer,
	[bookingModule]: bookingReducer,
	[dealsModule]: dealsReducer,
	[favoritesModule]: favoritesReducer,
	[faqModule]: faqReducer,
	[mailingModule]: mailingReducer,
	[formModule]: formReducer
})
