import axios from 'axios'
import { api } from '../config'
import { loadDeals } from './deals'
import { statusCode, statusCodeDefault } from '../../services/constants'

/**
 * Constants
 * */
export const moduleName = 'booking'

export const ERROR = `${moduleName}/ERROR`
export const ERROR_CLOSE = `${moduleName}/ERROR_CLOSE`
export const BOOKING_SUCCESS = `${moduleName}/BOOKING_SUCCESS`
export const BOOKING_SUCCESS_CLOSE = `${moduleName}/BOOKING_SUCCESS_CLOSE`

export const PENDING = `${moduleName}/PENDING`

export const OBJECT_LOAD_START = `${moduleName}/OBJECT_LOAD_START`
export const OBJECT_LOAD_ERROR = `${moduleName}/OBJECT_LOAD_ERROR`
export const OBJECT_LOAD_SUCCESS = `${moduleName}/OBJECT_LOAD_SUCCESS`
export const ERROR_CRM = `${moduleName}/ERROR_CRM`
export const STATUS_ERROR = `${moduleName}/STATUS_ERROR`

/**
 * Reducer
 * */
const defaultState = {
	loading: false,
	error: null,
	success: false,

	object: null,
	objectLoading: null,
	objectError: null,
	error_crm: false
}

export default (state = defaultState, action) => {
	const { type, error, payload } = action

	switch (type) {
		case PENDING:
			return { ...state, loading: true }

		case ERROR:
			return { ...state, loading: false, error }

		case STATUS_ERROR:
			return {
				...state,
				loading: false,
				error: statusCode[payload.statusCode] ? statusCode[payload.statusCode] : statusCodeDefault
			}

		case ERROR_CLOSE:
			return { ...state, error: null }

		case BOOKING_SUCCESS:
			return { ...state, success: true, loading: false }

		case BOOKING_SUCCESS_CLOSE:
			return { ...state, success: false }

		case ERROR:
			return { ...state, loading: false, error }

		case OBJECT_LOAD_START:
			return { ...state, objectLoading: true }

		case OBJECT_LOAD_ERROR:
			return { ...state, objectLoading: false, objectError: error }

		case OBJECT_LOAD_SUCCESS:
			return { ...state, objectLoading: false, object: payload.object }

		case ERROR_CRM:
			return { ...state, loading: false, error_crm: true }

		default:
			return state
	}
}

/**
 * Selectors
 * */
export const errorSelector = (state) => state[moduleName].error
export const loadingSelector = (state) => state[moduleName].loading
export const successSelector = (state) => state[moduleName].success

export const objectSelector = (state) => state[moduleName].object
export const loadingObjectSelector = (state) => state[moduleName].objectLoading
export const errorObjectSelector = (state) => state[moduleName].objectError

/**
 * Action Creators
 * */

export const makeBooking = (params) => async (dispatch) => {
	dispatch({ type: PENDING })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) {
			await dispatch({ type: BOOKING_SUCCESS })

			dispatch(
				loadDeals({
					cmd: 'user_deals',
					email: params.email,
					token: params.token
				})
			)
		}
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 6) {
			return dispatch({ type: STATUS_ERROR, payload: { statusCode: params.statusCode } })
		}
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		if (error.response && error.response.data.error_code !== 3) {
			dispatch({ type: ERROR, error: error.response.data.message })
		}
	}
}

export const makeBookInFrame = (params) => async (dispatch) => {
	dispatch({ type: PENDING })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) dispatch({ type: BOOKING_SUCCESS })
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		dispatch({ type: ERROR, error: error.response.data.message })
	}
}

export const closeBookingError = () => {
	return {
		type: ERROR_CLOSE
	}
}
export const closeBookingSuccess = () => {
	return {
		type: BOOKING_SUCCESS_CLOSE
	}
}

export const loadObject = (params) => async (dispatch) => {
	dispatch({ type: OBJECT_LOAD_START })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) dispatch({ type: OBJECT_LOAD_SUCCESS, payload: { object: data.object } })
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		dispatch({ type: OBJECT_LOAD_ERROR, error: error.response.data.message })
	}
}
