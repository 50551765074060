import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import {
	signIn,
	signUp,
	attemptsSingUpSelector,
	authErrorSelector,
	authLoadingSelector
} from '../../redux/modules/auth'
import { addFavoriteItem } from '../../redux/modules/favorites'

class FramePage extends Component {
	state = {
		triggerValidate: false,
		email: {
			value: '',
			name: 'email',
			type: 'email',
			placeholder: 'Email',
			validate: 'email',
			valid: false
		},
		check: false
	}

	render() {
		return (
			<div className="widget-form">
				<div className="widget-input-title">Введите ваш E-mail</div>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.email}
				/>
				<div className="input-field check-field check-field-policy">
					<label className={this.state.triggerValidate && !this.state.check ? 'error' : ''}>
						<input
							type="checkbox"
							checked={this.state.check}
							name="check"
							onChange={this.handleChangeCheck}
						/>
						<i></i>
						<span>
							Даю согласие на обработку <br />
							<a href="/personal-data" target="_blank">
								моих персональных данных
							</a>
						</span>
					</label>
				</div>

				<div className="widget-btn" onClick={this.handleSingnUp}>
					<span>Продолжить</span>
				</div>
			</div>
		)
	}

	handleSingnUp = async () => {
		if (this.props.loading) return false
		this.setState({ triggerValidate: true })
		if (!this.state['email'].valid || !this.state.check) return false

		await this.props.signUp(
			{
				cmd: 'user_add',
				email: this.state.email.value
			},
			this.props.attempts
		)

		this.props.addFavoriteItem({
			cmd: 'favorites_add',
			email: this.state.email.value,
			article: this.props.article,
			object: this.props.object
		})
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}

	handleChangeCheck = (ev) => {
		const { name, checked } = ev.target
		this.setState({ [name]: checked })
	}
}

export default connect(
	(state) => ({
		error: authErrorSelector(state),
		attempts: attemptsSingUpSelector(state),
		loading: authLoadingSelector(state)
	}),
	{ signUp, addFavoriteItem }
)(FramePage)
