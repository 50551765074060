import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import accounting from 'accounting'
import { removeFavoriteItem, removeLoadingSelector } from '../../../redux/modules/favorites'
import { getPage } from '../../../redux/ac'
import { addDeal, addLoadingSelector, dealsSelector } from '../../../redux/modules/deals'

class Row extends Component {
	state = {
		loading: false
	}
	render() {
		const { el, deals, mobile, add_loading } = this.props

		const disabled = deals.length ? deals.find((deal) => deal.objectId === el.ObjectID) : null

		const title =
			el.Type === 'Жилое'
				? +el.Rooms === 0
					? 'Студия'
					: `${el.Rooms} - комнатная квартира `
				: el.Type === 'Нежилое'
				? 'Апартаменты'
				: el.Type

		return (
			<tr data-href="deal.html" data-status="stat3">
				<td className="cell-img">
					<img src={el.Layout ? el.Layout : '/images/default.jpg'} alt="" />
					{this.props.mobile && (
						<div className="row-icons-mobile">
              {el.ObjectReservetion && (
							  <div className="cell-icon" onClick={this.handleAddToDeals}>
                  <span
                    className={`ico-lock ${!disabled && !el.in_deals ? '' : ' ico-lock--disabled'}`}
                  ></span>
                </div>
              )}
							{el.objectPDF ? (
								<div className="cell-icon">
									<a href={el.objectPdf} target="_blank">
										<span className="ico-download"></span>
									</a>
								</div>
							) : null}
							<a className="close-icon-row" href="/" onClick={this.handleRemove} />
						</div>
					)}
				</td>
				<td className="cell-text">
					<div>
						<span className="color-orange">
							{title} №{el.BeforeBtiNumber}
						</span>{' '}
						<br />
						{el.BuildingGroupName.replace(/^[0-9][0-9]\./g, 'ЖК ')}
						<br />
						{el.AddressBuild}
					</div>
				</td>
				<td className="cell-square">
					<span className="cell__title mobile">
						Площадь, м<sup>2</sup>
					</span>
					{el.SpaceDesign}
				</td>
				<td className="cell-price">
					<span className="cell__title mobile">Стоимость, ₽</span>
					{+el.Price ? accounting.formatNumber(el.Price, 0, ' ') : '-'}
				</td>
				{mobile || (
					<td className="cell-icon" onClick={this.handleAddToDeals}>
            {el.ObjectReservetion && (
              <>
                {this.state.loading ? (
                  <img className="img-loader" src="/images/loading.gif" alt="" />
                ) : !disabled && !el.in_deals ? (
                  <span className="ico-lock"></span>
                ) : (
                  <span className="ico-ok"></span>
                )}
              </>
            )}
					</td>
				)}
				{mobile || (
					<td className="cell-icon">
						{el.ObjectPdf ? (
							<a href={el.ObjectPdf} target="_blank">
								<span className="ico-download"></span>
							</a>
						) : (
							<div>&ndash;</div>
						)}
					</td>
				)}
				{mobile || (
					<td className="cell-icon" onClick={this.handleRemove}>
						<span className="ico-close"></span>
					</td>
				)}
			</tr>
		)
	}

	handleRemove = (ev) => {
		ev.preventDefault()
		if (this.props.loading) return false
		this.props.removeFavoriteItem({
			cmd: 'favorites_del',
			email: this.props.user.email,
			token: this.props.user.token,
			article: this.props.el.ArticleId,
			object: this.props.el.ObjectID
		})
	}

	handleAddToDeals = async (ev) => {
		ev.preventDefault()
		const { el, deals } = this.props
		const disabled = deals.length ? deals.find((deal) => deal.objectId === el.ObjectID) : null
		if (this.state.loading || this.props.el.in_deals || disabled) return false
		this.setState({ loading: true })

		// const { el, deals, add_loading } = this.props
		// const disabled = deals.length ? deals.find((deal) => deal.objectId === el.ObjectID) : null
		// if (this.props.el.in_deals) return false
		await this.props.addDeal({
			cmd: 'book',
			email: this.props.user.email,
			token: this.props.user.token,
			article: this.props.el.ArticleId,
			object: this.props.el.ObjectID,
			objectType: this.props.el.Type,
			statusCode: this.props.el.StatusCode
		})
		this.setState({ loading: false })
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile,
		user: state.auth.user,
		loading: removeLoadingSelector(state),
		add_loading: addLoadingSelector(state),
		deals: dealsSelector(state)
	}),
	{ removeFavoriteItem, getPage, addDeal }
)(Row)
