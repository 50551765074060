import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import { signUp, attemptsSingUpSelector, authLoadingSelector } from '../../redux/modules/auth'
import ErrorMessage from '../common/error-message'
import Button from '../common/button'

class EnterEmail extends Component {
	state = {
		triggerValidate: false,
		check: false
	}
	render() {
		return (
			<Fragment>
				<div className="profile-page-form">
					<div className="profile-page-form__column js-profile-personal">
						<div className="form-title">Введите вашу электронную почту</div>
						<InputField
							triggerValidate={this.state.triggerValidate}
							setFieldValid={this.props.setFieldValid}
							change={this.props.handleChange}
							className="ico-email"
							{...this.props.email}
							keyPressed={this.handleSend}
						/>
						<div className="input-field check-field check-field-policy">
							<label className={this.state.triggerValidate && !this.state.check ? 'error' : ''}>
								<input
									type="checkbox"
									checked={this.state.check}
									name="check"
									onChange={this.handleChangeCheck}
									onKeyPress={this.keyPressed}
								/>
								<i></i>
								<span>
									Даю согласие на обработку <br />
									<a href="/personal-data" target="_blank" tabIndex="-1">
										моих персональных данных
									</a>
								</span>
							</label>
						</div>
						<Button submit={true} onClick={this.handleSend} loading={this.props.loading}>
							Продолжить
						</Button>
						<div className="modal-text modal-text--small">
							При нажатии кнопки "Продолжить" вы соглашаетесь
							<br />с{' '}
							<a href="https://www.psngroup.ru/privacy-policy.php" target="_blank">
								Политикой конфиденциальности.
							</a>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}

	keyPressed = (event) => {
		if (event.key === 'Enter') {
			this.handleSend()
		}
	}

	handleSend = () => {
		this.setState({ triggerValidate: true })
		if (!this.formIsValid() || this.props.loading) return false

		this.props.signUp(
			{
				cmd: 'user_add',
				email: this.props.email.value
			},
			this.props.attempts,
			true
		)
	}

	formIsValid = () => this.props.email.valid && this.state.check

	handleChangeCheck = (ev) => {
		const { name, checked } = ev.target
		this.setState({ [name]: checked })
	}
}

export default connect(
	(state) => ({
		attempts: attemptsSingUpSelector(state),
		loading: authLoadingSelector(state)
	}),
	{ signUp }
)(EnterEmail)
