export default [
	{
		ObjectPdf: '/download',
		Type: '1', // 1 - квартира, 2 - кладовая, 3 - машиноместо
		ObjectNumber: '12 ', // Номер квартиры/помещения/машинометса
		StatusGroupID: '1', // 1 - В процессе сделки, 2 - Работа с договором, 3 - Завершение сделки, 4 - Незаконченные сделки
		StatusType: '1', // 1 - активная сделка, 2 - неактивная сделка
		StatusName: 'Онлайн бронь', // то, что выводится в колонке таблицы на станице всех сделок
		// Онлайн<br>бронь
		// Фиксированная бронь
		// Подготовка<br>договора
		// Договор<br>подписан
		// Договор передан на регистрацию
		// Договор<br>зарегистрирован
		// Выдача<br>ключей
		// Ключи<br>получены
		// Закрыто (сделка не была закончена)
		// Отменено
		status_history: [
			// то, что выводится в статусах детальной страницы (полный список статусов)
			{
				id: '1',
				name: 'Онлайн<br>бронь',
				datetime: '01.07.2019'
			},
			{
				id: '2',
				name: 'Подготовка<br>договора'
			},
			{
				id: '3',
				name: 'Договор<br>подписан'
			},
			{
				id: '4',
				name: 'Договор передан<br>на регистрацию'
			},
			{
				id: '5',
				name: 'Договор<br>зарегистрирован'
			},
			{
				id: '6',
				name: 'Выдача<br>ключей'
			},
			{
				id: '7',
				name: 'Ключи<br>получены'
			}
		],

		ObjectID: 'РП2-Б1-01-02-04-001',
		DealID: '33511',
		DDUContractID: 'DDU-NUMBER',
		ArticleId: 'fd9319b3-ef7a-e411-bba2-005056995954',
		ArticleName: 'ЖК «Домашний»',
		ArticleAddress: 'Рязанский пр-т, вл.2, корп. Б1',
		ObjectSquare: '78.19',
		ObjectFloor: '2',
		ObjectRooms: '3',
		ObjectPrice: '14074200',
		ObjectPlanImage: 'http://sreda-kvartal.ru/assets/images/aptsj/301-4.jpg',
		ObjectPDF: '',
		StatusID: '1',
		StatusDateTime: '2019-12-01 12:22:33'
	},
	{
		ObjectPdf: '/download',
		Type: '2',
		ObjectNumber: '23',
		StatusGroupID: '2',
		StatusType: '1',
		StatusName: 'Подготовка<br>договора',
		status_history: [
			{
				id: '1',
				name: 'Онлайн<br>бронь',
				datetime: '01.07.2019'
			},
			{
				id: '2',
				name: 'Подготовка<br>договора',
				datetime: '01.12.2019 12:22:33'
			},
			{
				id: '3',
				name: 'Договор<br>подписан'
			},
			{
				id: '4',
				name: 'Договор передан<br>на регистрацию'
			},
			{
				id: '5',
				name: 'Договор<br>зарегистрирован'
			},
			{
				id: '6',
				name: 'Выдача<br>ключей'
			},
			{
				id: '7',
				name: 'Ключи<br>получены'
			}
		],

		ObjectID: 'РП2-Б1-01-02-04-002',
		DealID: '33512',
		DDUContractID: 'DDU-NUMBER',
		ArticleId: 'fd9319b3-ef7a-e411-bba2-005056995954',
		ArticleName: 'ЖК «Домашний»',
		ArticleAddress: 'Рязанский пр-т, вл.2, корп. Б1',
		ObjectSquare: '78.19',
		ObjectFloor: '2',
		ObjectRooms: '3',
		ObjectPrice: '14074200',
		ObjectPlanImage: 'http://sreda-kvartal.ru/assets/images/aptsj/301-4.jpg',
		ObjectPDF: '',
		StatusID: '2',
		StatusName: 'Работа с договором',
		StatusDateTime: '2019-12-01 12:22:33'
	},
	{
		ObjectPdf: '/download',
		Type: '3',
		ObjectNumber: '01',
		StatusGroupID: '2',
		StatusType: '1',
		StatusName: 'Договор<br>подписан',
		status_history: [
			{
				id: '1',
				name: 'Онлайн<br>бронь',
				datetime: '01.07.2019'
			},
			{
				id: '2',
				name: 'Подготовка<br>договора',
				datetime: '01.12.2019 12:22:33'
			},
			{
				id: '3',
				name: 'Договор<br>подписан',
				datetime: '03.12.2019 12:22:33'
			},
			{
				id: '4',
				name: 'Договор передан<br>на регистрацию'
			},
			{
				id: '5',
				name: 'Договор<br>зарегистрирован'
			},
			{
				id: '6',
				name: 'Выдача<br>ключей'
			},
			{
				id: '7',
				name: 'Ключи<br>получены'
			}
		],

		ObjectID: 'РП2-Б1-01-02-04-002',
		DealID: '33513',
		DDUContractID: 'DDU-NUMBER',
		ArticleId: 'fd9319b3-ef7a-e411-bba2-005056995954',
		ArticleName: 'ЖК «Домашний»',
		ArticleAddress: 'Рязанский пр-т, вл.2, корп. Б1',
		ObjectSquare: '78.19',
		ObjectFloor: '2',
		ObjectRooms: '3',
		ObjectPrice: '14074200',
		ObjectPlanImage: 'http://sreda-kvartal.ru/assets/images/aptsj/301-4.jpg',
		ObjectPDF: '',
		StatusID: '2',
		StatusName: 'Работа с договором',
		StatusDateTime: '2019-12-01 12:22:33'
	},
	{
		ObjectPdf: '/download',
		Type: '1',
		ObjectNumber: '86',
		StatusGroupID: '3',
		StatusType: '1',
		StatusName: 'Выдача<br>ключей',
		status_history: [
			{
				id: '1',
				name: 'Онлайн<br>бронь',
				datetime: '01.07.2019'
			},
			{
				id: '2',
				name: 'Подготовка<br>договора',
				datetime: '01.12.2019 12:22:33'
			},
			{
				id: '3',
				name: 'Договор<br>подписан',
				datetime: '03.12.2019 12:22:33'
			},
			{
				id: '4',
				name: 'Договор передан<br>на регистрацию',
				datetime: '08.12.2019 12:22:33'
			},
			{
				id: '5',
				name: 'Договор<br>зарегистрирован',
				datetime: '15.12.2019 12:22:33'
			},
			{
				id: '6',
				name: 'Выдача<br>ключей',
				datetime: '25.12.2019 12:22:33'
			},
			{
				id: '7',
				name: 'Ключи<br>получены'
			}
		],

		ObjectID: 'РП2-Б1-01-02-04-004',
		DealID: '33514',
		DDUContractID: 'DDU-NUMBER',
		ArticleId: 'fd9319b3-ef7a-e411-bba2-005056995954',
		ArticleName: 'ЖК «Домашний»',
		ArticleAddress: 'Рязанский пр-т, вл.2, корп. Б1',
		ObjectSquare: '78.19',
		ObjectFloor: '2',
		ObjectRooms: '2',
		ObjectPrice: '14074200',
		ObjectPlanImage: 'http://sreda-kvartal.ru/assets/images/aptsj/301-4.jpg',
		ObjectPDF: '',
		StatusID: '3',
		StatusName: 'Завершение сделки',
		StatusDateTime: '2019-12-01 12:22:33'
	},
	{
		ObjectPdf: '/download',
		Type: '1',
		ObjectNumber: '74',
		StatusGroupID: '4',
		StatusType: '2',
		StatusName: 'Отменено',
		status_history: [
			{
				id: '1',
				name: 'Онлайн<br>бронь',
				datetime: '01.07.2019'
			},
			{
				id: '2',
				name: 'Подготовка<br>договора',
				datetime: '01.12.2019 12:22:33'
			},
			{
				id: '3',
				name: 'Договор<br>подписан',
				datetime: '03.12.2019 12:22:33'
			},
			{
				id: '4',
				name: 'Договор передан<br>на регистрацию',
				datetime: '08.12.2019 12:22:33'
			},
			{
				id: '5',
				name: 'Отменено',
				datetime: '15.12.2019 12:22:33'
			}
		],

		ObjectID: 'РП2-Б1-01-02-04-003',
		DealID: '33515',
		DDUContractID: 'DDU-NUMBER',
		ArticleId: 'fd9319b3-ef7a-e411-bba2-005056995954',
		ArticleName: 'ЖК «Домашний»',
		ArticleAddress: 'Рязанский пр-т, вл.2, корп. Б1',
		ObjectSquare: '78.19',
		ObjectFloor: '2',
		ObjectRooms: '1',
		ObjectPrice: '14074200',
		ObjectPlanImage: 'http://sreda-kvartal.ru/assets/images/aptsj/301-4.jpg',
		ObjectPDF: '',
		StatusID: '4',
		StatusName: 'Незаконченная сделка',
		StatusDateTime: '2019-12-01 12:22:33'
	}
]
