import React, { Component } from 'react'
import { connect } from 'react-redux'
import { statusSelector, changeStatus, groupSelector } from '../../redux/modules/deals'

class Filter extends Component {
	render() {
		const { changeStatus, status, groups } = this.props
		return (
			<div className="deal-status">
				<span className={!status ? 'active' : ''} onClick={() => changeStatus(0)}>
					Все статусы
				</span>
				<span className={status === '1' ? 'active' : ''} onClick={() => changeStatus('1')}>
					В процессе сделки<i>{groups[1] ? groups[1].length : 0}</i>
				</span>
				<span className={status === '2' ? 'active' : ''} onClick={() => changeStatus('2')}>
					Работа с договором<i>{groups[2] ? groups[2].length : 0}</i>
				</span>
				<span className={status === '3' ? 'active' : ''} onClick={() => changeStatus('3')}>
					Завершение сделки<i>{groups[3] ? groups[3].length : 0}</i>
				</span>
				<span className={status === '4' ? 'active' : ''} onClick={() => changeStatus('4')}>
					Незаконченные сделки<i>{groups[4] ? groups[4].length : 0}</i>
				</span>
			</div>
		)
	}

	// handleChange = (status) => {

	// }
}

export default connect(
	(state) => ({
		status: statusSelector(state),
		groups: groupSelector(state)
	}),
	{ changeStatus }
)(Filter)
