import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'

class Footer extends Component {
	render() {
		if (!this.props.user && !this.props.access) return null
		return (
			<Fade>
				<div className="footer">
					<div className="content">
						<div>
							<div className="footer-logo ico-1"></div>
							<span className="footer-copy">© Группа ПСН {new Date().getFullYear()}</span>
						</div>
						<div>
							<a
								href="https://www.psngroup.ru/privacy-policy.php"
								className="footer-link"
								target="_blank"
							>
								Политика конфиденциальности
							</a>
						</div>
					</div>
				</div>
			</Fade>
		)
	}
}

export default connect(
	(state) => ({
		user: state.auth.user,
		access: state.auth.access
	}),
	{}
)(Footer)
