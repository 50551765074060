import React, { Component } from 'react'

class Header extends Component {
	render() {
		return (
			<div className="content header header2">
				<div className="logo ico-1"></div>
			</div>
		)
	}
}

export default Header
