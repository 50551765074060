import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Counter from './counter'

class ErrorMessage extends Component {
	static propTypes = {
		message: PropTypes.string.isRequired,
		close: PropTypes.func.isRequired
	}

	id = null

	componentDidMount() {
		this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.close, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		return (
			<div className="modal modal-error" ref={(el) => (this.container = el)}>
				<div className="modal__overlay"></div>
				<div className="modal__container">
					<div className="modal__body">
						<div className="modal-title" dangerouslySetInnerHTML={{ __html: this.props.message }} />
						<div className="form-content">
							<div className="btn" onClick={this.hide}>
								<span>Закрыть</span>
							</div>
						</div>
						{/*<div className="modal-text modal-text--bottom">
							Это окно будет скрыто автоматически через <Counter callback={this.hide} /> секунд
						</div>*/}
					</div>
					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}
}

export default ErrorMessage
