import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import accounting from 'accounting'
import {
	loadedSelector,
	loadingSelector,
	loadDeals,
	errorSelector,
	closeError,
	dealsMapSelector,
	setDisbled,
	setUpdated
} from '../../redux/modules/deals'
import { closeFormError, closeFormSuccess, openForm } from '../../redux/modules/form'
import ErrorMessage from '../common/error-message'
import Message from '../common/message'
import layout from '../../decorators/layout'
import Loader from './crm-loader'
import Form from '../ppo-form/modal'

class Deals extends Component {
	state = {
		active: null,
		open: false
	}
	componentDidMount() {
		document.title = 'Сделка - Сервис онлайн-покупки PSN group'
		window.scrollTo(0, 0)
		const { loaded, loading, user } = this.props

		if (!this.props.updated) {
			this.tid = setTimeout(() => {
				this.props.setDisbled(false)
			}, 2000)
		}

		if (loaded || loading || !user) return false

		loadDeals({
			cmd: 'user_deals',
			email: user.email,
			token: user.token
		})
	}

	tid = null

	componentDidUpdate(prevProps, prevState) {
		const { loaded, loading, user, not_synced } = this.props
		if (!not_synced && not_synced !== prevProps.not_synced) {
			this.props.setUpdated(true)
		}
		if (!this.props.updated && this.props.updated !== prevProps.updated) {
			this.tid = setTimeout(() => {
				this.props.setDisbled(false)
			}, 60000)
		}
		if (loaded || loading || !user || user === prevProps.user) return false

		loadDeals({
			cmd: 'user_deals',
			email: user.email,
			token: user.token
		})
	}

	componentWillUnmount(nextProps, nextState) {
		clearTimeout(this.tid)
	}

	handleCloseMsg = () => {
		this.props.setUpdated(false)
		this.props.setDisbled(true)
	}

	handleReload = () => {
		const { loadDeals, user, disabled, not_synced, loading } = this.props
		if (not_synced || disabled || loading) return false
		loadDeals({
			cmd: 'user_deals',
			email: user.email,
			token: user.token,
			force: 'yes'
		})
	}

	render() {
		const { deals, id, loading, not_synced } = this.props
		const flat = deals[id]
		if (!flat) return null
		const title =
			+flat.Type === 2
				? 'Апартаменты '
				: +flat.Type === 3
				? 'Машиноместо '
				: +flat.objectRooms === 0
				? 'Студия'
				: `${flat.objectRooms} - комнатная квартира `
		return (
			<Fragment>
				<Fade>
					<div className="content middle deal">
						<div className="title">
							{title} №{flat.objectNumber}
							<div className="deal-path mobile">
								<Link to="/deals">Все сделки</Link>
							</div>
							<div>
								<Link to="/faq/settings" className="title__link ico-faq">
									<span>процесс бронирования</span>
								</Link>
								<div
									className={
										not_synced || this.props.disabled
											? 'title__link ico-13 disabled'
											: 'title__link ico-13'
									}
									onClick={this.handleReload}
								>
									<span>Обновить данные</span>
									{this.props.disabled && (
										<div className="tooltip">
											Обновление данных возможно <br /> не чаще чем 1 раз в минуту
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="deal-path desctope">
							<Link to="/deals">Все сделки</Link>
						</div>

						{not_synced && (
							<Fragment>
								<Loader />
								<br />
							</Fragment>
						)}

						<div className="deal-steps">{this.steps}</div>

						<div className="deal__flat">
							<div className="deal__flat__left" onClick={this.handleOpen}>
								<img src={flat.objectPlanImage ? flat.objectPlanImage : '/images/default.jpg'} alt="" />
							</div>
							<div className="deal__flat__right">
								<div className="deal__flat__title">
									{title} №{flat.objectNumber}
								</div>
								<div className="deal__flat__text">
									<div>{flat.ArticleName}</div>
									<div>{flat.ArticleAddress}</div>
								</div>
								<div className="deal__flat__text">
									<div>
										<span className="desctope">Общая площадь:</span>
										<span className="mobile">
											Площадь, м<sup>2</sup>
										</span>
										{flat.objectSquare} м<sup>2</sup>
									</div>
									<div>
										<span>Этаж:</span>
										{flat.objectFloor}
									</div>
									<div>
										<span>Комнат:</span>
										{flat.objectRooms}
									</div>
									{flat.objectPrice ? (
										<div>
											<span>Стоимость:</span>
											<span className="color-orange">
												{accounting.formatNumber(flat.objectPrice, 0, ' ')}
											</span>
										</div>
									) : null}
								</div>
								{flat.objectPDF && (
									<div className="deal__flat__text">
										<a href={flat.objectPDF} target="_blank" className="ico-download">
											<span>Скачать PDF</span>
										</a>
									</div>
								)}
								<div className="btn deal-btn" onClick={this.props.openForm}>
									<span>ПОСТПРОДАЖНОЕ ОБСЛУЖИВАНИЕ</span>
								</div>
							</div>
						</div>
					</div>
				</Fade>
				{this.props.updated && <Message message="История сделок обновлена" close={this.handleCloseMsg} />}
				{this.props.error && <ErrorMessage error={this.props.error} close={closeError} />}
				{this.state.open && (
					<div className="deal__zoom">
						<img src={flat.objectPlanImage ? flat.objectPlanImage : '/images/default.jpg'} alt="" />
						<div className="deal__zoom__close" onClick={this.handleClose}></div>
					</div>
				)}
				{this.props.form && <Form flat={flat} />}
				{this.props.formError && <ErrorMessage error={this.props.formError} close={closeFormError} />}
				{this.props.formSuccess && <Message message="Отправлено" close={this.closeFormSuccess} />}
			</Fragment>
		)
	}

	handleOpen = () => this.setState({ open: true })
	handleClose = () => this.setState({ open: false })

	getActive = (list) =>
		list.reduce((acc, el) => {
			if (el.startDateTime) return el
			return acc
		}, {})

	get steps() {
		const { deals, id } = this.props
		const flat = deals[id]
		const active = this.getActive(flat.history)
		return flat.history.map((el, i) => {
			if (el.stepId === '10' && el.stepId !== active.stepId) {
				return null
			}
			if (el.stepId === '11' && el.stepId !== active.stepId) {
				return null
			}
			return (
				<div key={i} className={active.stepId === el.stepId ? 'active' : ''}>
					<span className="deal-steps__title" dangerouslySetInnerHTML={{ __html: el.stepName }} />
					<span className="deal-steps__date">{el.startDateTime}</span>
				</div>
			)
		})
	}
}

export default connect(
	(state) => ({
		loaded: loadedSelector(state),
		loading: loadingSelector(state),
		error: errorSelector(state),
		mobile: state.mobile,
		user: state.auth.user,
		not_synced: state.deals.not_synced,
		deals: dealsMapSelector(state),
		disabled: state.deals.disabled,
		updated: state.deals.updated,
		form: state.form.ppo.open,
		formError: state.form.ppo.error,
		formSuccess: state.form.ppo.send
	}),
	{ loadDeals, closeError, setDisbled, setUpdated, openForm, closeFormError, closeFormSuccess }
)(layout(Deals))
