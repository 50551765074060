import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeField } from '../../../redux/modules/mailing'

class Search extends Component {
	render() {
		return (
			<div className="text-search">
				<input
					type="text"
					placeholder="Поиск по коду, проекту и т.д."
					onChange={this.changeText}
					{...this.props.field}
				/>
				<div className="btn text-search__btn" onClick={this.props.scrollIntoView}>
					Найти
				</div>
			</div>
		)
	}

	changeText = (ev) => this.props.changeField(ev.target.name, ev.target.value)

	// get textSearch() {
	//  const { text } = this.state
	//  return (
	//    <div className="text-search">
	//      <input type="text" placeholder="Поиск по коду, проекту и т.д." onChange={this.changeText} {...text} />
	//      <div className="btn text-search__btn" onClick={() => this.sendText(text)}>
	//        Найти
	//      </div>
	//    </div>
	//  )
	// }
	// changeText = (ev) => this.setState({ [ev.target.name]: { name: ev.target.name, value: ev.target.value } })
	// sendText = (field) => {
	//  this.props.changeField(field.value)
	//  this.props.scrollIntoView()
	// }
}

export default connect(
	(state) => ({
		field: state.mailing.filter.text
	}),
	{ changeField }
)(Search)
