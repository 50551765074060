import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Icons from './icons'
import Fade from 'react-reveal/Fade'

class Header extends Component {
	render() {
		if (!this.props.user && !this.props.access) return null
		return (
			<Fade>
				<div className="content header">
					<Link to="/deals" className="logo ico-1"></Link>
					{this.props.mobile || <Icons type="desctope" clone={true} />}
				</div>
			</Fade>
		)
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile,
		user: state.auth.user,
		access: state.auth.access
	}),
	{}
)(Header)
