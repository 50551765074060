import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Row from './row'
import { sortList, sortedSelector } from '../../../redux/modules/mailing'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

class Table extends Component {
	state = {
		order: {
			date: 'none',
			client_name: 'none',
			client_email: 'none',
			BuildingGroupName: 'none',
			ObjectID: 'none',
			corpus: 'none',
			section: 'none',
			Floor: 'none',
			BeforeBtiNumber: 'none',
			Rooms: 'none',
			Price: 'none',
			status: 'none'
		}
	}

	render() {
		const { filtrated, title } = this.props
		const { order } = this.state

		if (!filtrated.length)
			return (
				<div className="not-found">
					По вашим параметрам ничего не найдено. Попробуйте изменить значения фильтров.
				</div>
			)
		// console.log(this.props.list);
		return (
			<SimpleBar forceVisible="y" autoHide={false}>
				<div className="table deal-table">
					<div className="table-title">{title}</div>
					<table>
						<thead>
							<tr>
								<th
									sort="date"
									order={order.date}
									className={'soter-header sort-' + order.date}
									onClick={this.handleSort}
								>
									<span>Дата</span>
								</th>
								<th
									sort="client_name"
									order={order.client_name}
									className={'soter-header sort-' + order.client_name}
									onClick={this.handleSort}
								>
									<span>Клиент</span>
								</th>
								<th
									sort="client_email"
									order={order.client_email}
									className={'soter-header sort-' + order.client_email}
									onClick={this.handleSort}
								>
									<span>Email</span>
								</th>
								<th
									sort="BuildingGroupName"
									order={order.BuildingGroupName}
									className={'soter-header sort-' + order.BuildingGroupName}
									onClick={this.handleSort}
								>
									<span>Проект</span>
								</th>
								<th
									sort="ObjectID"
									order={order.ObjectID}
									className={'soter-header sort-' + order.ObjectID}
									onClick={this.handleSort}
								>
									<span>Код</span>
								</th>
								<th
									sort="corpus"
									order={order.corpus}
									className={'soter-header sort-' + order.corpus}
									onClick={this.handleSort}
								>
									<span>Корпус</span>
								</th>
								<th
									sort="section"
									order={order.section}
									className={'soter-header sort-' + order.section}
									onClick={this.handleSort}
								>
									<span>Секция</span>
								</th>
								<th
									sort="Floor"
									order={order.Floor}
									className={'soter-header sort-' + order.Floor}
									onClick={this.handleSort}
								>
									<span>Этаж</span>
								</th>
								<th
									sort="BeforeBtiNumber"
									order={order.BeforeBtiNumber}
									className={'soter-header sort-' + order.BeforeBtiNumber}
									onClick={this.handleSort}
								>
									<span>№&nbsp;кв.</span>
								</th>
								<th
									sort="Rooms"
									order={order.Rooms}
									className={'soter-header sort-' + order.Rooms}
									onClick={this.handleSort}
								>
									<span>Комнат</span>
								</th>
								<th
									sort="Price"
									order={order.Price}
									className={'soter-header sort-' + order.Price}
									onClick={this.handleSort}
								>
									<span>Стоимость,&nbsp;₽</span>
								</th>
								<th>
									<span>Планировка</span>
								</th>
								<th
									sort="status"
									order={order.status}
									className={'soter-header sort-' + order.status}
									onClick={this.handleSort}
								>
									<span>Статус</span>
								</th>
								<th>Выбрать</th>
							</tr>
						</thead>
						<tbody>{this.rows}</tbody>
					</table>
				</div>
			</SimpleBar>
		)
	}

	handleSort = (ev) => {
		const field = ev.currentTarget.getAttribute('sort')
		const order = ev.currentTarget.getAttribute('order') === 'desc' ? 'asc' : 'desc'

		this.props.sortList(field, order)

		this.setState({
			order: {
				date: 'none',
				client_name: 'none',
				client_email: 'none',
				BuildingGroupName: 'none',
				ObjectID: 'none',
				corpus: 'none',
				section: 'none',
				Floor: 'none',
				BeforeBtiNumber: 'none',
				Rooms: 'none',
				Price: 'none',
				status: 'none',
				[field]: this.state.order[field] === 'desc' ? 'asc' : 'desc'
			}
		})
	}

	get rows() {
		return this.props.filtrated.map((list) => {
			return list.map((el, i) => <Row first={i === 0} key={i} el={el} />)
		})
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile,
		filtrated: sortedSelector(state)
	}),
	{ sortList }
)(Table)
