import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import {
	loadedSelector,
	loadingSelector,
	errorSelector,
	favoritesSelector,
	loadFavorites,
	groupSelector,
	closeError,
	removeFavorites,
	removeAllLoadingSelector
} from '../../redux/modules/favorites'
import {
	errorSelector as dealsErrorSelector,
	closeError as closeDealsError,
	closeAddSuccess
} from '../../redux/modules/deals'

import Table from './table'
import SuccessMessage from './sign-up-success'
import ErrorMessage from '../common/error-message'
import layout from '../../decorators/layout'
import Loader from '../common/loader'
import Message from '../booking/success-message'
import NoteMessage from './booking-error-message'

class Favorites extends Component {
	componentDidMount() {
		document.title = 'Избранное - Сервис онлайн-покупки PSN group'
		window.scrollTo(0, 0)
		const { loaded, loading, loadFavorites, user } = this.props
		if (!user) return false

		loadFavorites({
			cmd: 'favorites',
			email: user.email,
			token: user.token
		})
	}

	componentDidUpdate(prevProps, prevState) {
		const { loaded, loading, loadFavorites, user } = this.props
		if (loaded || loading || !user || user === prevProps.user) return false

		loadFavorites({
			cmd: 'favorites',
			email: user.email,
			token: user.token
		})
	}

	render() {
		const { favorites, user, loaded, groups } = this.props
		if (!user || !loaded) return <Loader />
		return (
			<Fragment>
				<Fade>
					<div className="content middle favorites">
						<div className="title">
							Избранное
							<div className="title__link ico-cart" onClick={this.handleRemoveAll}>
								<span>очистить список</span>
							</div>
						</div>

						{!favorites.length && (
							<div className="table-info">
								В Избранном пока нет объектов. Чтобы добавить квартиру / кладовую / машиноместо,
								необходимо перейти на сайт выбранного ЖК.
							</div>
						)}
						<Table list={groups['Жилое']} title="Квартиры" />
						<Table list={groups['Кладовая']} title="Кладовые" />
						<Table list={groups['Машиноместо']} title="Машиноместа" />
						<Table list={groups['Коммерческое']} title="Коммерческие помещения" />
					</div>
				</Fade>
				{this.props.sign_up_success && <SuccessMessage />}
				{this.props.error && <ErrorMessage error={this.props.error} close={this.props.closeError} />}
				{this.props.deals_error && (
					<ErrorMessage error={this.props.deals_error} close={this.props.closeDealsError} />
				)}
				{this.props.add_success && (
					<Message objectType={this.props.objectType} close={this.props.closeAddSuccess} />
				)}
				{this.props.dealErrorCrmFav && (
					<NoteMessage
						object={this.props.dealErrorCrmFav.object}
						article={this.props.dealErrorCrmFav.article}
						close={this.props.closeDealsError}
					/>
				)}
			</Fragment>
		)
	}

	handleRemoveAll = () => {
		if (this.props.remove_all_loading) return false
		this.props.removeFavorites({
			cmd: 'favorites_del',
			email: this.props.user.email,
			token: this.props.user.token,
			all: 'yes'
		})
	}
}

export default connect(
	(state) => ({
		loaded: loadedSelector(state),
		loading: loadingSelector(state),
		error: errorSelector(state),
		deals_error: dealsErrorSelector(state),
		favorites: favoritesSelector(state),
		groups: groupSelector(state),
		user: state.auth.user,
		remove_all_loading: removeAllLoadingSelector(state),
		sign_up_success: state.auth.sign_up_success,
		add_success: state.deals.add_success,
		objectType: state.deals.objectType,
		dealErrorCrmFav: state.deals.dealErrorCrmFav
	}),
	{ loadFavorites, closeError, removeFavorites, closeDealsError, closeAddSuccess }
)(layout(Favorites))
