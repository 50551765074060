import axios from 'axios'
import { api } from '../config'
import { createSelector } from 'reselect'
import { sortingTable, groupAndSort, sortGroups, distinct, compare } from '../../services/utils'
import data from '../mocks/mailing'
import accounting from 'accounting'

/**
 * Constants
 * */
export const moduleName = 'mailing'

export const LOAD = `${moduleName}/LOAD`
export const SEND = `${moduleName}/SEND`
export const SORT = `${moduleName}/SORT`

export const START = '_START'
export const SUCCESS = '_SUCCESS'
export const ERROR = '_ERROR'
export const CLOSE = '_CLOSE'
export const OPEN = '_OPEN'
export const CHANGE = '_CHANGE'

export const CHECKBOX = `${moduleName}/CHECKBOX`
export const FIELD = `${moduleName}/FIELD`
export const RADIO = `${moduleName}/RADIO`
export const CHECK_ROW = `${moduleName}/CHECK_ROW`
export const TEMPLATE = `${moduleName}/TEMPLATE`
export const DISCOUNT = `${moduleName}/DISCOUNT`
export const MODAL = `${moduleName}/MODAL`

/**
 * Reducer
 * */
const defaultState = {
	entities: [],
	templates: {
		name: 'template',
		value: '',
		options: [{ label: 'Выбрать шаблон', value: '' }]
	},
	discount: '',
	loading: false,
	loaded: false,
	error: null,
	sort: {
		field: 'date',
		order: 'desc'
	},
	filter: {
		text: {
			value: '',
			name: 'text'
		},
		buildings: [],
		date_from: {
			name: 'date_from',
			value: '',
			options: [{ label: 'от', value: '' }]
		},
		date_to: {
			name: 'date_to',
			value: '',
			options: [{ label: 'до', value: '' }]
		},
		client: {
			name: 'client',
			value: ''
		},
		email: {
			name: 'email',
			value: ''
		},
		corpus_from: {
			name: 'corpus_from',
			value: '',
			options: [{ label: 'от', value: '' }]
		},
		corpus_to: {
			name: 'corpus_to',
			value: '',
			options: [{ label: 'до', value: '' }]
		},
		section: {
			name: 'section',
			value: '',
			options: [{ label: '-', value: '' }]
		},
		floor_from: {
			name: 'floor_from',
			value: '',
			options: [{ label: 'от', value: '' }]
		},
		floor_to: {
			name: 'floor_to',
			value: '',
			options: [{ label: 'до', value: '' }]
		},
		num_from: {
			name: 'num_from',
			value: '',
			options: [{ label: 'от', value: '' }]
		},
		num_to: {
			name: 'num_to',
			value: '',
			options: [{ label: 'до', value: '' }]
		},
		rooms_from: {
			name: 'rooms_from',
			value: '',
			options: [{ label: 'от', value: '' }]
		},
		rooms_to: {
			name: 'rooms_to',
			value: '',
			options: [{ label: 'до', value: '' }]
		},
		price_from: {
			name: 'price_from',
			value: '',
			options: [{ label: 'от', value: '' }]
		},
		price_to: {
			name: 'price_to',
			value: '',
			options: [{ label: 'до', value: '' }]
		},
		status: {
			name: 'status',
			value: '',
			options: [{ label: '-', value: '' }]
		},
		radio: 0
	},

	checked: [],
	modal: false
}

export default (state = defaultState, action) => {
	const { type, error, payload } = action

	switch (type) {
		case LOAD + START:
			return { ...state, loading: true }

		case LOAD + SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				entities: Object.values(payload.mailing),
				templates: {
					...state.templates,
					options: [...state.templates.options, ...Object.values(payload.templates)]
				}
			}

		case LOAD + ERROR:
			return { ...state, loading: false, error }

		// filter
		case SORT:
			return { ...state, sort: { field: payload.field, order: payload.order } }

		case CHECKBOX + CHANGE:
			return {
				...state,
				filter: { ...state.filter, [payload.name]: payload.value, radio: 0 },
				checked: []
			}

		case FIELD + CHANGE:
			return {
				...state,
				filter: {
					...state.filter,
					[payload.name]: { ...state.filter[payload.name], value: payload.value },
					radio: 0
				},
				checked: []
			}

		case RADIO + CHANGE:
			return { ...state, filter: { ...state.filter, radio: payload.value } }

		case CHECK_ROW:
			return { ...state, checked: payload.value }

		case TEMPLATE + CHANGE:
			return { ...state, templates: { ...state.templates, value: payload.value }, discount: '' }

		case DISCOUNT + CHANGE:
			return { ...state, templates: { ...state.templates, value: '' }, discount: payload.value }

		case MODAL + OPEN:
			return { ...state, modal: true }

		case MODAL + CLOSE:
			return { ...state, modal: false }

		default:
			return state
	}
}

/**
 * Selectors
 * */
export const listSelector = (state) => state[moduleName].entities
export const filterSelector = (state) => state[moduleName].filter
export const sortSelector = (state) => state[moduleName].sort
export const checkedSelector = (state) => state[moduleName].checked

export const filtratedSelector = createSelector(listSelector, filterSelector, (list, filter) => {
	const {
		text,
		date_from,
		date_to,
		corpus_from,
		corpus_to,
		floor_from,
		floor_to,
		num_from,
		num_to,
		rooms_from,
		rooms_to,
		price_from,
		price_to,
		section,
		status,
		client,
		email,
		buildings
	} = filter

	return list.filter((el) => {
		return (
			(!text.value ||
				Object.values(el)
					.join(' ')
					.toLowerCase()
					.indexOf(text.value.toLowerCase()) > -1) &&
			(!date_from.value || !date_to.value || (el.date >= date_from.value && el.date <= date_to.value)) &&
			(!corpus_from.value ||
				!corpus_to.value ||
				(el.corpus >= corpus_from.value && el.corpus <= corpus_to.value)) &&
			(!floor_from.value || !floor_to.value || (el.Floor >= floor_from.value && el.Floor <= floor_to.value)) &&
			(!num_from.value ||
				!num_to.value ||
				(el.BeforeBtiNumber >= num_from.value && el.BeforeBtiNumber <= num_to.value)) &&
			(!rooms_from.value || !rooms_to.value || (el.Rooms >= rooms_from.value && el.Rooms <= rooms_to.value)) &&
			(!price_from.value || !price_to.value || (el.Price >= price_from.value && el.Price <= price_to.value)) &&
			(!section.value || +section.value === +el.section) &&
			(!status.value || +status.value === +el.status) &&
			(!buildings.length || buildings.indexOf(el.BuildingGroupId) > -1) &&
			(!client.value || el.client_name.toLowerCase().indexOf(client.value.toLowerCase()) > -1) &&
			(!email.value || el.client_email.toLowerCase().indexOf(email.value.toLowerCase()) > -1)
		)
	})
})

export const sortedSelector = createSelector(filtratedSelector, sortSelector, (list, sort) => {
	const filtrated = groupAndSort(list, 'client_id', sort)
	var keysSorted = sortGroups(filtrated, sort)
	return keysSorted.map((el, i) => filtrated[el])
})

export const checkedListSelector = createSelector(filtratedSelector, checkedSelector, (list, checked) => {
	return checked.filter((id) => list.find((el) => el.id === id))
})

// fields
export const date_from = (state) => state[moduleName].filter.date_from
export const dateFromOptions = createSelector(listSelector, (objects) => {
	const options = distinct(objects, 'date')
	return options
		.sort((a, b) => compare(new Date(a['date']), new Date(b['date'])))
		.map((el) => ({ label: el.date, value: el.date }))
})
export const dateToOptions = createSelector(dateFromOptions, date_from, (options, date_from) => {
	return options.filter((el) => el.value >= date_from.value)
})

export const corpus_from = (state) => state[moduleName].filter.corpus_from
export const corpusFromOptions = createSelector(listSelector, (objects) => {
	const options = distinct(objects, 'corpus')
	return options
		.sort((a, b) => compare(a['corpus'], b['corpus']))
		.map((el) => ({ label: el.corpus, value: el.corpus }))
})
export const corpusToOptions = createSelector(corpusFromOptions, corpus_from, (options, corpus) => {
	return options.filter((el) => el.value >= corpus.value)
})

export const floor_from = (state) => state[moduleName].filter.floor_from
export const floorFromOptions = createSelector(listSelector, (objects) => {
	const options = distinct(objects, 'Floor')
	return options.sort((a, b) => compare(a['Floor'], b['Floor'])).map((el) => ({ label: el.Floor, value: el.Floor }))
})
export const floorToOptions = createSelector(floorFromOptions, floor_from, (options, floor) => {
	return options.filter((el) => el.value >= floor.value)
})

export const num_from = (state) => state[moduleName].filter.num_from
export const numFromOptions = createSelector(listSelector, (objects) => {
	const options = distinct(objects, 'BeforeBtiNumber')
	return options
		.sort((a, b) => compare(a['BeforeBtiNumber'], b['BeforeBtiNumber']))
		.map((el) => ({ label: el.BeforeBtiNumber, value: el.BeforeBtiNumber }))
})
export const numToOptions = createSelector(numFromOptions, num_from, (options, num) => {
	return options.filter((el) => el.value >= num.value)
})

export const rooms_from = (state) => state[moduleName].filter.rooms_from
export const roomsFromOptions = createSelector(listSelector, (objects) => {
	const options = distinct(objects, 'Rooms')
	return options.sort((a, b) => compare(a['Rooms'], b['Rooms'])).map((el) => ({ label: el.Rooms, value: el.Rooms }))
})
export const roomsToOptions = createSelector(roomsFromOptions, rooms_from, (options, rooms) => {
	return options.filter((el) => el.value >= rooms.value)
})

export const price_from = (state) => state[moduleName].filter.price_from
export const priceFromOptions = createSelector(listSelector, (objects) => {
	const options = distinct(objects, 'Price')
	return options
		.sort((a, b) => compare(a['Price'], b['Price']))
		.map((el) => ({ label: accounting.formatNumber(el.Price, 0, ' '), value: el.Price }))
})
export const priceToOptions = createSelector(priceFromOptions, price_from, (options, price) => {
	return options.filter((el) => el.value >= price.value)
})

export const sectionOptions = createSelector(listSelector, (objects) => {
	const options = distinct(objects, 'section')
	return options
		.sort((a, b) => compare(a['section'], b['section']))
		.map((el) => ({ label: el.section, value: el.section }))
})

export const statusOptions = createSelector(listSelector, (objects) => {
	const options = distinct(objects, 'status')
	return options
		.sort((a, b) => compare(a['status'], b['status']))
		.map((el) => ({ label: el.status === 2 ? 'Отправлено' : 'Не отправлено', value: el.status }))
})

export const buildingOptions = createSelector(listSelector, (objects) => distinct(objects, 'BuildingGroupId'))

/**
 * Action Creators
 * */
// export const closeSuccess = () => ({type: LOAD + ERROR + CLOSE})
export const openModal = () => ({ type: MODAL + OPEN })
export const closeModal = () => ({ type: MODAL + CLOSE })

export const loadMailing = (params) => async (dispatch) => {
	dispatch({ type: LOAD + START })
	try {
		const { data } = await axios.get(api, { params })
		console.log(data)
		if (data.status) {
			dispatch({ type: LOAD + SUCCESS, payload: { mailing: data.objects, templates: data.templates } })
		}
	} catch (error) {
		console.log(error)
		// dispatch({ type: LOAD_ERROR, error: error.response.data.message })
	}
}

export const changeCheckbox = (name, value) => ({ type: CHECKBOX + CHANGE, payload: { name, value } })
export const changeField = (name, value) => ({ type: FIELD + CHANGE, payload: { name, value } })
export const changeRadio = (value) => ({ type: RADIO + CHANGE, payload: { value } })
export const sortList = (field, order) => ({ type: SORT, payload: { field, order } })
export const checkRow = (value) => ({ type: CHECK_ROW, payload: { value } })
export const changeTemplate = (value) => ({ type: TEMPLATE + CHANGE, payload: { value } })
export const changeDiscount = (value) => ({ type: DISCOUNT + CHANGE, payload: { value } })
