import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Tabs extends Component {
	state = {
		open: []
	}

	componentDidMount() {
		this.setState({
			open: this.props.items && this.props.items.length ? [+this.props.items[0]['id']] : []
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.items !== this.props.items) {
			this.setState({
				open: this.props.items && this.props.items.length ? [+this.props.items[0]['id']] : []
			})
		}
	}

	render() {
		return (
			<div className="tabs__right">
				<Fade key={this.props.id}>
					<div className="tab">{this.body}</div>
				</Fade>
			</div>
		)
	}

	get body() {
		if (!this.props.items) return <div>Еще никто не задал вопрос</div>
		return this.props.items.map((el) => {
			const opened = this.state.open.includes(+el.id)
			return (
				<div key={el.id}>
					<div className={opened ? 'question active' : 'question'} onClick={() => this.toggle(+el.id)}>
						<i className="question-ico-active ico-14"></i>
						<i className="question-ico ico-15"></i>
						<span dangerouslySetInnerHTML={{ __html: el.title }}></span>
					</div>
					{opened && <div className="answer" dangerouslySetInnerHTML={{ __html: el.text }}></div>}
				</div>
			)
		})
	}

	toggle = (id) => {
		const { open } = this.state
		this.setState({
			open: open.includes(id) ? open.filter((el) => el !== id) : [...open, id]
		})
	}
}

export default Tabs
