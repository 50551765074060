import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import history from './redux/history'
import './css/app.css'

import PrivateRoute from './components/common/private-router'
import Header from './components/layout/header'
import Footer from './components/layout/footer'
import Icons from './components/layout/icons'
import SignIn from './components/sign-in'
import ResetPassword from './components/reset-password'
import SignUp from './components/sign-up'
import Favorites from './components/favorites'
import NewPassword from './components/new-password'
import NewEmail from './components/new-email'
import Profile from './components/profile'
import Deals from './components/deals'
import Deal from './components/deals/detail'
import Faq from './components/faq'

import Booking from './components/booking'
import FramePage from './components/iframe'
import Loader from './components/common/loader'
import PersonalData from './components/text-page/personal-data'
import ErrorPage from './components/404'
import Mailing from './components/mailing'

import { setMobile } from './redux/modules/mobile'
import { signOut, checkSession } from './redux/modules/auth'

import { searchToObject } from './services/utils'

class App extends Component {
	componentDidMount() {
		this.props.setMobile(window.innerWidth <= 990)
		window.addEventListener('resize', this._onResize)

		this.checkUserAuthorized()
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.checkedAuthorized !== prevProps.checkedAuthorized) {
			setTimeout(() => this.pageContainer.classList.add('loaded'), 200)
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._onResize)
	}

	checkUserAuthorized = () => {
		const user = localStorage.getItem('user')
		if (user) this.props.checkSession(user)
		if (!user) this.props.signOut(false)
	}

	render() {
		const { mobile, user, checkedAuthorized } = this.props
		if (!checkedAuthorized) return <Loader />
		return (
			<ConnectedRouter history={history}>
				<div ref={(el) => (this.pageContainer = el)}>
					<Switch>
						<Route path="/" exact component={this.mainPage} />
						<Route path="/sign-in" exact component={this.signIn} />
						<Route path="/reset-password" exact component={this.resetPassword} />
						<Route path="/sign-up" exact component={this.signUp} />
						<Route path="/new-password" exact component={this.newPassword} />
						<Route path="/new-email" exact component={this.newEmail} />

						<PrivateRoute path="/mailing" exact component={Mailing} />

						<PrivateRoute path="/favorites" exact component={Favorites} />
						<PrivateRoute path="/profile" exact component={Profile} />
						<PrivateRoute path="/deals" exact component={Deals} />
						<PrivateRoute path="/deals/:id" exact component={this.dealPage} />
						<Route path="/booking/:article/:id" exact component={this.bookingPage} />
						<Route path="/faq" exact component={this.faqPage} />
						<Route path="/faq/:id" exact component={this.faqPage} />
						<Route path="/personal-data" exact component={PersonalData} />

						<Route path="/favorite-frame/:article/:object/:theme" exact component={this.frameFavorite} />
						<Route path="/booking-frame/:article/:object/:theme" exact component={this.frameBooking} />
						<Route path="*" component={ErrorPage} />
					</Switch>
				</div>
			</ConnectedRouter>
		)
	}

	signIn = () => {
		// if (this.props.user && this.props.user.is_developer) return <Redirect to="/mailing" />
		if (this.props.user) return <Redirect to="/favorites" />
		return <SignIn />
	}

	signUp = () => {
		// if (this.props.user && this.props.user.is_developer) return <Redirect to="/mailing" />
		if (this.props.user) return <Redirect to="/favorites" />
		return <SignUp />
	}

	resetPassword = () => {
		// if (this.props.user && this.props.user.is_developer) return <Redirect to="/mailing" />
		if (this.props.user) return <Redirect to="/favorites" />
		return <ResetPassword />
	}

	newPassword = ({ match, location }) => {
		const search = searchToObject(location.search)
		if (!search.checkword || !search.email) return <Redirect to="/reset-password" />
		if (match.isExact) return <NewPassword {...search} />
	}

	newEmail = ({ match, location }) => {
		const search = searchToObject(location.search)
		if (!search.checkword || !search.email || !search.new_email) return <Redirect to="/sign-in" />
		if (match.isExact) return <NewEmail {...search} />
	}

	dealPage = ({ match }) => {
		if (match.isExact) return <Deal id={match.params.id} />
	}

	bookingPage = ({ match }) => {
		if (match.isExact) return <Booking id={match.params.id} article={match.params.article} />
	}

	mainPage = ({ match }) => {
		// if (this.props.user && this.props.user.is_developer) return <Redirect to="/mailing" />
		if (match.isExact) return <Redirect to="/favorites" />
	}

	faqPage = ({ match }) => {
		if (match.isExact) return <Faq id={match.params.id} />
	}

	_onResize = () => {
		const mobile = window.innerWidth <= 990

		if ((mobile && !this.props.mobile) || (!mobile && this.props.mobile)) {
			this.props.setMobile(mobile)
		}
	}

	frameFavorite = ({ match }) => {
		if (match.isExact) {
			return (
				<FramePage
					object={match.params.object}
					article={match.params.article}
					theme={match.params.theme}
					favorite={true}
				/>
			)
		}
	}

	frameBooking = ({ match }) => {
		if (match.isExact) {
			return (
				<FramePage
					object={match.params.object}
					article={match.params.article}
					theme={match.params.theme}
					booking={true}
				/>
			)
		}
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile,
		user: state.auth.user,
		checkedAuthorized: state.auth.checkedAuthorized
	}),
	{ setMobile, signOut, checkSession }
)(App)
