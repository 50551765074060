import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'

class InputField extends Component {
	static propTypes = {
		value: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		valid: PropTypes.bool.isRequired,

		setFieldValid: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value !== this.props.value) {
			this.props.setFieldValid(this.props.name, this.isValidField())
		}
	}

	render() {
		return (
			<div className={this.getClassName()}>
				<select name={this.props.name} value={this.props.value} onChange={this.props.change}>
					<option value="">Пол</option>
					<option value="M">Мужской</option>
					<option value="F">Женский</option>
				</select>
			</div>
		)
	}

	getClassName = () => {
		const valid = this.props.valid || (!this.props.value && !this.props.triggerValidate) ? ' valid' : ' error'
		return 'input-field select-field ' + valid
	}

	isValidField = () => {
		if (this.props.value === '') return false
		return true
	}
}

export default InputField
