import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'
import {
	loadedSelector,
	loadingSelector,
	errorSelector,
	filtratedSelector,
	loadDeals,
	inactiveSelector,
	activeSelector,
	closeError,
	closeRemoveSuccess,
	setDisbled,
	setUpdated
} from '../../redux/modules/deals'
import { closeError as closeSmsError } from '../../redux/modules/profile'
import Filter from './filter'
import Table from './table'
import ErrorMessage from '../common/error-message'
import NoteMessage from './note-error'
import Message from '../common/message'
import layout from '../../decorators/layout'
import SmsCode from '../../components/smc-code'
import Loader from './crm-loader'

class Deals extends Component {
	componentDidMount() {
		document.title = 'Сделки - Сервис онлайн-покупки PSN group'
		window.scrollTo(0, 0)
		const { loaded, loading, loadDeals, user } = this.props
		if (!this.props.updated) {
			this.tid = setTimeout(() => {
				this.props.setDisbled(false)
			}, 2000)
		}
		if (!user) return false
		loadDeals({
			cmd: 'user_deals',
			email: user.email,
			token: user.token
		})
	}

	tid = null

	componentDidUpdate(prevProps, prevState) {
		const { loaded, loading, loadDeals, user, not_synced } = this.props
		if (!not_synced && not_synced !== prevProps.not_synced) {
			this.props.setUpdated(true)
		}
		if (!this.props.updated && this.props.updated !== prevProps.updated) {
			this.tid = setTimeout(() => {
				this.props.setDisbled(false)
			}, 60000)
		}
		if (loaded || loading || !user || user === prevProps.user) return false

		loadDeals({
			cmd: 'user_deals',
			email: user.email,
			token: user.token
		})
	}

	componentWillUnmount(nextProps, nextState) {
		clearTimeout(this.tid)
	}

	handleCloseMsg = () => {
		this.props.setUpdated(false)
		this.props.setDisbled(true)
	}

	handleReload = () => {
		const { loadDeals, user, disabled, loading } = this.props
		if (this.props.not_synced || disabled || loading) return false
		loadDeals({
			cmd: 'user_deals',
			email: user.email,
			token: user.token,
			force: 'yes'
		})
	}

	render() {
		const {
			active,
			inactive,
			filtrated,
			user,
			loaded,
			closeError,
			loading,
			closeSmsError,
			not_synced,
			closeRemoveSuccess
		} = this.props
		// if (not_synced) return <Loader />
		// if (!user || (loading && !loaded)) return null
		return (
			<Fragment>
				<Fade>
					<div className="content middle deals">
						<div className="title">
							сделки
							<div>
								<Link to="/faq/settings" className="title__link ico-faq js-clear-all">
									<span>процесс бронирования</span>
								</Link>
								<div
									className={
										not_synced || this.props.disabled
											? 'title__link ico-13 disabled'
											: 'title__link ico-13'
									}
									onClick={this.handleReload}
								>
									<span>Обновить данные</span>
									{this.props.disabled && (
										<div className="tooltip">
											Обновление данных возможно <br /> не чаще чем 1 раз в минуту
										</div>
									)}
								</div>
							</div>
						</div>

						{/*<Filter />*/}
						{not_synced && (
							<Fragment>
								<Loader />
								<br />
							</Fragment>
						)}
						{!filtrated.length && !not_synced && <div className="table-info">Нет объектов в сделках.</div>}
						<Table list={active} title="Активные сделки" />
						<Table list={inactive} cancel={true} title="Неактивные сделки" />
					</div>
				</Fade>
				{this.props.updated && <Message message="История сделок обновлена" close={this.handleCloseMsg} />}
				{this.props.dealErrorCrm && (
					<NoteMessage message="Для бронирования и покупки необходимо заполнить свой профиль и подтвердить номер телефона" />
				)}
				{this.props.remove_success && <Message close={closeRemoveSuccess} message="Сделка отменена" />}
				{this.props.error && <ErrorMessage error={this.props.error} close={closeError} />}
			</Fragment>
		)
	}
}

export default connect(
	(state) => ({
		loaded: loadedSelector(state),
		loading: loadingSelector(state),
		error: state.deals.load_error,
		filtrated: filtratedSelector(state),
		active: activeSelector(state),
		inactive: inactiveSelector(state),
		user: state.auth.user,
		not_synced: state.deals.not_synced,
		dealErrorCrm: state.deals.dealErrorCrm,
		remove_success: state.deals.remove_success,
		disabled: state.deals.disabled,
		updated: state.deals.updated
	}),
	{ loadDeals, closeError, closeSmsError, closeRemoveSuccess, setDisbled, setUpdated }
)(layout(Deals))
