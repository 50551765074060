import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Counter from '../common/counter'

class SuccessMessage extends Component {
	static propTypes = {
		close: PropTypes.func.isRequired
	}

	id = null

	componentDidMount() {
		this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		this.container.classList.remove('visible')
		this.id = setTimeout(this.props.close, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		const { objectType } = this.props
		const type =
			+objectType === 2 || objectType === 'Кладовая'
				? ' кладовую комнату'
				: +objectType === 3 || objectType === 'Машиноместо'
				? ' машиноместо'
				: ' квартиру'

		return (
			<div className="modal modal-reservation-message" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.hide}></div>
				<div className="modal__container">
					<div className="modal__body js-reservation-message">
						<div className="modal-title">Объект успешно забронирован!</div>
						<div className="form-content">
							<div className="modal-text ">
								<p>Вы успешно забронировали {type}. </p>
								<p>
									В ближайшее время наш сотрудник свяжется с вами для подтверждения и оформления
									сделки
								</p>
							</div>
							<div className="input-field btn-field">
								<Link to="/deals" className="btn">
									перейти к сделкам
								</Link>
							</div>
							<div className="btn-link" onClick={this.hide}>
								<span>Пропустить</span>
							</div>
						</div>
						{/*<div className="modal-text modal-text--bottom">
							Это окно будет скрыто автоматически через <Counter callback={this.hide} /> секунд
						</div>*/}
					</div>
					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}
}

export default SuccessMessage
