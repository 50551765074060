import axios from 'axios'
import { api } from '../config'
import { loadDeals } from './deals'

/**
 * Constants
 * */
export const moduleName = 'profile'

export const PROFILE_ERROR = `${moduleName}/PROFILE_ERROR`
export const PROFILE_ERROR_CLOSE = `${moduleName}/PROFILE_ERROR_CLOSE`
export const USER_INFO_START = `${moduleName}/USER_INFO_START`
export const USER_INFO_SUCCESS = `${moduleName}/USER_INFO_SUCCESS`
export const CHANGE_EMAIL_OPEN = `${moduleName}/CHANGE_EMAIL_OPEN`
export const CHANGE_EMAIL_CLOSE = `${moduleName}/CHANGE_EMAIL_CLOSE`
export const CHANGE_EMAIL_SUCCESS = `${moduleName}/CHANGE_EMAIL_SUCCESS`
export const CHANGE_EMAIL_SUCCESS_CLOSE = `${moduleName}/CHANGE_EMAIL_SUCCESS_CLOSE`
export const CHANGE_PASSWORD_SUCCESS = `${moduleName}/CHANGE_PASSWORD_SUCCESS`
export const CHANGE_PASSWORD_SUCCESS_CLOSE = `${moduleName}/CHANGE_PASSWORD_SUCCESS_CLOSE `
export const NOTIFICATIONS_SUCCESS = `${moduleName}/NOTIFICATIONS_SUCCESS`
export const NOTIFICATIONS_SUCCESS_CLOSE = `${moduleName}/NOTIFICATIONS_SUCCESS_CLOSE `

export const SEND_SMS = `${moduleName}/SEND_SMS`
export const PHONE_SEND_SMS_SUCCESS = `${moduleName}/PHONE_SEND_SMS_SUCCESS`
export const PHONE_SEND_SMS_SUCCESS_CLOSE = `${moduleName}/PHONE_SEND_SMS_SUCCESS_CLOSE`
export const SEND_SMS_RELOAD = `${moduleName}/SEND_SMS_RELOAD`

export const PENDING = `${moduleName}/PENDING`

/**
 * Reducer
 * */
const defaultState = {
	loading: false,
	loadingInfoSelector: false,
	error: null,
	info: {
		first_name: null
	},
	changeEmail: {
		open: false,
		sent: false
	},
	profileNewPasswordSuccess: false,
	notificationsSuccess: false,

	smsSent: false,
	confirmPhone: false,
	phone_not_confirm: null
}

export default (state = defaultState, action) => {
	const { type, error, payload } = action

	switch (type) {
		case PENDING:
			return { ...state, loading: true }

		case PROFILE_ERROR:
			return { ...state, loading: false, error }

		case PROFILE_ERROR_CLOSE:
			return { ...state, error: null }

		case USER_INFO_START:
			return { ...state, loadingInfoSelector: true }

		case USER_INFO_SUCCESS:
			return {
				...state,
				loadingInfoSelector: false,
				loading: false,
				info: { ...state.info, ...payload.user_info },
				phone_not_confirm: payload.phone_not_confirm
			}

		case CHANGE_EMAIL_OPEN:
			return { ...state, changeEmail: { ...state.changeEmail, open: true } }

		case CHANGE_EMAIL_CLOSE:
			return { ...state, changeEmail: { ...state.changeEmail, open: false } }

		case CHANGE_EMAIL_SUCCESS:
			return { ...state, loading: false, changeEmail: { ...state.changeEmail, sent: true } }

		case CHANGE_EMAIL_SUCCESS_CLOSE:
			return { ...state, loading: false, changeEmail: defaultState.changeEmail }

		case CHANGE_PASSWORD_SUCCESS:
			return { ...state, loading: false, profileNewPasswordSuccess: true }

		case CHANGE_PASSWORD_SUCCESS_CLOSE:
			return { ...state, profileNewPasswordSuccess: false }

		case NOTIFICATIONS_SUCCESS:
			return { ...state, loading: false, notificationsSuccess: true }

		case NOTIFICATIONS_SUCCESS_CLOSE:
			return { ...state, notificationsSuccess: false }

		case SEND_SMS:
			return { ...state, loading: false, smsSent: true }

		case SEND_SMS_RELOAD:
			return { ...state, loading: false, smsSent: false }

		case PHONE_SEND_SMS_SUCCESS:
			return { ...state, loading: false, confirmPhone: true, info: { ...state.info, phone: payload.phone } }

		default:
			return state
	}
}

/**
 * Selectors
 * */
export const errorSelector = (state) => state[moduleName].error
export const loadingInfoSelector = (state) => state[moduleName].loadingInfoSelector

export const loadingSelector = (state) => state[moduleName].loading

export const userInfoSelector = (state) => state[moduleName].info

export const changeEmailFormOpenSelector = (state) => state[moduleName].changeEmail.open
export const changeEmailFormSentSelector = (state) => state[moduleName].changeEmail.sent

export const profileNewPasswordSuccessSelector = (state) => state[moduleName].profileNewPasswordSuccess
export const notificationsSuccessSelector = (state) => state[moduleName].notificationsSuccess

export const smsSentSelector = (state) => state[moduleName].smsSent
export const confirmPhoneSelector = (state) => state[moduleName].confirmPhone

/**
 * Action Creators
 * */
export const closeError = () => {
	return {
		type: PROFILE_ERROR_CLOSE
	}
}

export const openChangeEmailForm = () => {
	return {
		type: CHANGE_EMAIL_OPEN
	}
}
export const closeChangeEmailForm = () => {
	return {
		type: CHANGE_EMAIL_CLOSE
	}
}
export const closeChangeEmailSuccess = () => {
	return {
		type: CHANGE_EMAIL_SUCCESS_CLOSE
	}
}
export const changeEmail = (params) => async (dispatch) => {
	dispatch({ type: PENDING })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) dispatch({ type: CHANGE_EMAIL_SUCCESS })
	} catch ({ response }) {
		dispatch({ type: PROFILE_ERROR, error: response.data.message })
	}
}

export const getUserInfo = ({ email, token }) => async (dispatch) => {
	dispatch({ type: USER_INFO_START })
	try {
		const { data } = await axios.get(api, {
			params: {
				cmd: 'get_user_info',
				email: email,
				token: token
			}
		})
		if (data.status) dispatch({ type: USER_INFO_SUCCESS, payload: { user_info: data.user_info } })
	} catch ({ response }) {
		dispatch({ type: PROFILE_ERROR, error: response.data.message })
	}
}

export const userInfoUpdate = ({ email, token, isNotifications, ...rest }) => async (dispatch) => {
	dispatch({ type: PENDING })
	try {
		const { data } = await axios.get(api, {
			params: {
				cmd: 'user_update',
				email: email,
				token: token,
				...rest
			}
		})
		if (isNotifications) await dispatch({ type: NOTIFICATIONS_SUCCESS })
		if (data.status)
			dispatch({
				type: USER_INFO_SUCCESS,
				payload: { user_info: { phone_not_confirm: data.phone_not_confirm, ...rest } }
			})
	} catch ({ response }) {
		if (response && response.data && response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		dispatch({ type: PROFILE_ERROR, error: response.data.message })
	}
}

export const newPassword = (params) => async (dispatch) => {
	dispatch({ type: PENDING })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) dispatch({ type: CHANGE_PASSWORD_SUCCESS })
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		dispatch({ type: PROFILE_ERROR, error: error.response.data.message })
	}
}

export const closePasswordSuccess = () => {
	return {
		type: CHANGE_PASSWORD_SUCCESS_CLOSE
	}
}

export const closeNotificationsSuccess = () => {
	return {
		type: NOTIFICATIONS_SUCCESS_CLOSE
	}
}

export const phoneСonfirm = (params) => async (dispatch) => {
	dispatch({ type: PENDING })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) dispatch({ type: SEND_SMS })
	} catch ({ response }) {
		if (response && response.data && response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		dispatch({ type: PROFILE_ERROR, error: response.data.message })
	}
}
export const sendSmsReload = (params) => async (dispatch) => {
	dispatch({ type: SEND_SMS_RELOAD })
}

export const smsCodeCheck = (params) => async (dispatch) => {
	dispatch({ type: PENDING })
	try {
		const { data } = await axios.get(api, { params })
		if (data.ShowMessage) {
			return dispatch({ type: PROFILE_ERROR, error: data.ShowMessage })
		}
		if (data.status) {
			await dispatch({ type: PHONE_SEND_SMS_SUCCESS, payload: { phone: params.phone } })
			dispatch(
				getUserInfo({
					email: params.email,
					token: params.token
				})
			)
		}
	} catch ({ response }) {
		if (response && response.data && response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		dispatch({ type: PROFILE_ERROR, error: response.data.message })
	}
}
