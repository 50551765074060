import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'

class InputField extends Component {
	static propTypes = {
		value: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		placeholder: PropTypes.string.isRequired,
		valid: PropTypes.bool.isRequired,

		validate: PropTypes.string,
		setFieldValid: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired
	}

	state = {
		focus: false,
		init: false
	}

	limits = {
		email: {
			regex: /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-]+\.[A-Za-z]+$/
		},
		name: {
			regex: /^[а-яА-Я-]+$/
		},
		second_name: {
			regex: /^[а-яА-Я-]+$/
		},
		password: {
			regex: /^[a-zA-Z0-9-_.$%#]+$/,
			min: 6
		},
		text: {
			min: 1
		},
		textarea: {
			min: 10
		},
		phone: {
			regex: /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
		},
		date: {
			regex: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
		},
		code: {
			min: 5
		}
	}

	render() {
		return (
			<div className={this.getClassName()}>
				<label>
					{this.props.type === 'textarea' ? (
						<textarea
							name={this.props.name}
							type={this.props.type}
							onBlur={this.handleBlur}
							onFocus={this.handleFocus}
							value={this.props.value}
							onChange={this.props.change}
							onKeyPress={this.handleKeyPeress}
						>
							{this.props.value}
						</textarea>
					) : this.props.validate === 'date' || this.props.validate === 'phone' ? (
						<InputMask
							mask={this.props.validate === 'phone' ? '+7 (999) 999-99-99' : '99.99.9999'}
							name={this.props.name}
							type={this.props.type}
							onBlur={this.handleBlur}
							onFocus={this.handleFocus}
							value={this.props.value}
							onChange={this.props.change}
							onKeyPress={this.handleKeyPeress}
						/>
					) : (
						<input
							name={this.props.name}
							type={this.props.type}
							onBlur={this.handleBlur}
							onFocus={this.handleFocus}
							value={this.props.value}
							onChange={this.props.change}
							onKeyPress={this.handleKeyPeress}
							readOnly={this.props.readOnly}
						/>
					)}
					<span>{this.props.placeholder}</span>
				</label>
			</div>
		)
	}

	handleKeyPeress = (ev) => {
		if (ev.key === 'Enter' && typeof this.props.keyPressed === 'function') this.props.keyPressed()
	}

	handleBlur = async (ev) => {
		this.setState({ focus: false })
		if (this.props.name === 'birthday') {
			const date = this.props.value.split('.')
			if (date.length > 1) {
				const dd = date[0]
				const mm = date[1]
				let yy = date[2]
				if (yy && yy.includes('_')) {
					yy = parseInt(yy) < 22 ? '20' + yy.replace('__', '') : '19' + parseInt(yy.replace('__', ''))
				}
				await this.props.setDateValue(dd + '.' + mm + '.' + yy)
			}
		}
		this.props.setFieldValid(this.props.name, this.isValidField())
	}

	handleFocus = (ev) => {
		this.props.setFieldValid(this.props.name, true)
		this.setState({ focus: true, valid: true, init: true })
	}

	getClassName = () => {
		const valid = this.props.valid || (!this.props.value && !this.props.triggerValidate) ? ' valid' : ' error'
		const focus = this.props.value || this.state.focus ? ' focus' : ''
		return 'input-field text-field ' + this.props.className + focus + valid
	}

	isValidField = () => {
		const { validate, value, compareValue } = this.props
		const limit = this.limits[validate]
		if (!limit) return true

		if (validate === 'second_name') {
			if (!value) return true
			if (value && !limit.regex.test(value)) return false
		}

		if (limit.regex && !limit.regex.test(value)) {
			return false
		}

		if (limit.min && value.length < limit.min) {
			return false
		}

		if (validate === 'date') {
			const year = parseInt(value.split('.')[2])
			const maxYear = new Date().getFullYear() - 14
			const minYear = new Date().getFullYear() - 85
			if (year > maxYear || year < minYear) return false
		}

		if (compareValue && value !== compareValue) {
			return false
		}

		return true
	}
}

export default InputField
