import React, { Component } from 'react'
import { connect } from 'react-redux'
import SelectField from '../../common/select'
import { changeField, dateFromOptions, dateToOptions } from '../../../redux/modules/mailing'

class DateField extends Component {
	render() {
		const { filter, from, to } = this.props
		if (!from.length) return null
		return (
			<div className="filter-field filter-field--large">
				<div className="filter-field__title">Дата</div>
				<div className="filter-field__body filter-field--from-to">
					<SelectField
						change={this.changeFrom}
						{...filter.date_from}
						options={[...filter.date_from.options, ...from]}
					/>
					<SelectField
						change={this.changeTo}
						{...filter.date_to}
						options={[...filter.date_to.options, ...to]}
					/>
				</div>
			</div>
		)
	}

	changeFrom = (ev) => {
		const { changeField, filter, to } = this.props

		changeField(ev.target.name, ev.target.value)
		if (new Date(filter.date_to.value).getTime() < new Date(ev.target.value).getTime()) {
			changeField('date_to', ev.target.value)
		}
		if (filter.date_to.value === '') {
			changeField('date_to', to[to.length - 1].value)
		}
	}
	changeTo = (ev) => this.props.changeField(ev.target.name, ev.target.value)
}

export default connect(
	(state) => ({
		filter: state.mailing.filter,
		from: dateFromOptions(state),
		to: dateToOptions(state)
	}),
	{ changeField }
)(DateField)
