import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { changeField, changeRadio, checkRow, sortedSelector } from '../../../redux/modules/mailing'

import Search from './search'
import Buildings from './buildings'
import DateField from './date'
import Corpus from './corpus'
import Floor from './floor'
import Num from './num'
import Rooms from './rooms'
import Price from './price'
import Section from './section'
import Status from './status'

class Filter extends Component {
	state = {
		text: {
			value: '',
			name: 'text'
		}
	}

	render() {
		const { filter } = this.props
		console.log(filter.radio)
		return (
			<div className="filter">
				<Search scrollIntoView={this.props.scrollIntoView} />
				<Buildings />
				<div className="filter__row">
					<DateField />
					<div className="filter-field filter-field--client">
						<div className="filter-field__title">Клиент</div>
						<div className="field-text">
							<input type="text" onChange={this.changeField} {...filter.client} />
						</div>
					</div>
					<div className="filter-field filter-field--email">
						<div className="filter-field__title">Email</div>
						<div className="field-text">
							<input type="text" placeholder="Email" onChange={this.changeField} {...filter.email} />
						</div>
					</div>
				</div>

				<div className="filter__row filter__row--last">
					<Corpus />
					<Section />
					<Floor />
					<Num />
					<Rooms />
					<Price />
					<Status />
					<div className="filter-field filter-field--btn">
						<div className="filter-field__title">&nbsp;</div>
						<div className="btn" onClick={this.props.scrollIntoView}>
							применить
						</div>
					</div>
				</div>
				<div className="filter-field-radio">
					<div className={filter.radio === 1 ? 'active' : ''} value="1" onClick={this.changeRadio}>
						Выбрать по одному объекту у каждого клиента
					</div>
					<div className={filter.radio === 2 ? 'active' : ''} value="2" onClick={this.changeRadio}>
						Выбрать по самой высокой цене у каждого клиента
					</div>
					<div className={filter.radio === 3 ? 'active' : ''} value="3" onClick={this.changeRadio}>
						Выбрать по самой низкой цене у каждого клиента
					</div>
				</div>
			</div>
		)
	}

	changeField = (ev) => this.props.changeField(ev.target.name, ev.target.value)

	changeRadio = (ev) => {
		const { filter, changeRadio, checkRow, sorted } = this.props
		let value = +ev.target.getAttribute('value')
		value = value === filter.radio ? 0 : value
		changeRadio(value)

		let newCheckedList = []

		// выбрать по 1 объекту у каждого клиента
		if (value === 1) newCheckedList = sorted.map((el) => el[0].id)

		// выбрать по самой высокой цене у каждого клиента
		if (value === 2) {
			sorted.map((client) => {
				const maxPrice = Math.max.apply(
					Math,
					client.map(function(o) {
						return o['Price']
					})
				)
				client.map((el) => {
					if (el['Price'] === maxPrice) newCheckedList.push(el.id)
				})
			})
		}
		// выбрать по самой низкой цене у каждого клиента
		if (value === 3) {
			sorted.map((client) => {
				const minPrice = Math.min.apply(
					Math,
					client.map(function(o) {
						return o['Price']
					})
				)
				client.map((el) => {
					if (el['Price'] === minPrice) newCheckedList.push(el.id)
				})
			})
		}

		// выбрать все
		if (value === 4) {
			sorted.map((client) => client.map((el) => newCheckedList.push(el.id)))
		}

		checkRow(newCheckedList)
	}
}

export default connect(
	(state) => ({
		filter: state.mailing.filter,
		sorted: sortedSelector(state)
	}),
	{ changeField, changeRadio, checkRow }
)(Filter)
