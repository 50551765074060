import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import SelectField from '../common/select2'
import { getUserInfo, userInfoSelector } from '../../redux/modules/profile'
import { sendPpo } from '../../redux/modules/form'

import Button from '../common/button'

class Personal extends Component {
	state = {
		loading: false,
		triggerValidate: false,
		last_name: {
			value: '',
			name: 'last_name',
			type: 'text',
			placeholder: 'Фамилия',
			validate: 'name',
			valid: false
		},
		first_name: {
			value: '',
			name: 'first_name',
			type: 'text',
			placeholder: 'Имя',
			validate: 'name',
			valid: false
		},
		second_name: {
			value: '',
			name: 'second_name',
			type: 'text',
			placeholder: 'Отчество',
			validate: 'second_name',
			valid: true
		},
		phone: {
			value: '',
			name: 'phone',
			type: 'tel',
			placeholder: 'Телефон',
			validate: 'phone',
			valid: false
		},
		email: {
			value: '',
			name: 'email',
			type: 'text',
			placeholder: 'Электронная почта',
			validate: 'email',
			valid: false
		},
		address: {
			value: '',
			name: 'address',
			validate: true,
			valid: false,
			options: [
				{ label: 'Адрес обращения', value: '' },
				{ label: 'Адрес 1', value: '1' }
			]
		},
		category: {
			value: '',
			name: 'category',
			validate: true,
			valid: false,
			options: [
				{ label: 'Категория обращения', value: '' },
				{ label: 'Категория 1', value: '1' }
			]
		},
		flat: {
			value: '',
			name: 'flat',
			type: 'tel',
			placeholder: 'Номер квартиры',
			validate: 'text',
			valid: false,
			options: [
				{ label: 'Номер квартиры', value: '' },
				{ label: '123', value: '123' }
			]
		},
		description: {
			value: '',
			name: 'description',
			type: 'textarea',
			placeholder: 'Описание',
			validate: 'textarea',
			valid: false
		},
		check: false
	}

	componentDidMount() {
		const { user, userInfo, getUserInfo } = this.props
		this.setObjectInfo()
		if (user && !userInfo.email) {
			getUserInfo({ email: user.email, token: user.token })
		} else {
			this.setUserInfo()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { userInfo } = this.props
		if (userInfo.email && prevProps.userInfo.email !== this.props.userInfo.email) {
			this.setUserInfo()
		}
		if (
			(this.props.deals && this.props.deals !== prevProps.deals) ||
			(this.props.flat && this.props.flat !== prevProps.flat)
		) {
			this.setObjectInfo()
		}
	}

	setUserInfo = () => {
		const { userInfo } = this.props
		this.setState({
			last_name: {
				...this.state.last_name,
				value: userInfo.last_name,
				valid: !!userInfo.last_name
			},
			first_name: {
				...this.state.first_name,
				value: userInfo.first_name,
				valid: !!userInfo.first_name
			},
			second_name: {
				...this.state.second_name,
				value: userInfo.second_name,
				valid: !!userInfo.second_name
			},
			phone: {
				...this.state.phone,
				value: userInfo.phone,
				valid: !!userInfo.phone
			},
			email: {
				...this.state.email,
				value: userInfo.email,
				valid: !!userInfo.email
			}
		})
	}

	setObjectInfo = () => {
		this.setState({
			flat: this.props.flat
				? {
						...this.state.flat,
						value: this.props.flat.objectNumber,
						valid: !!this.props.flat.objectNumber
				  }
				: {
						...this.state.flat,
						options: [
							...this.state.flat.options,
							...this.props.deals.map((el) => ({ label: el.objectNumber, value: el.objectNumber }))
						]
				  }
		})
	}

	render() {
		return (
			<div>
				<div className="modal-text modal-text--small">
					Дорогие покупатели, мы рады, что вы выбрали наш жилой квартал. На все возникающие вопросы по вашей
					квартире и проекту в целом вы можете получить ответы у менеджера. Для получения дополнительной
					информации направьте нам запрос по форме обратной связи.
				</div>
				<div className="ppo-form">
					<div className="ppo-form__column">{this.profileForm}</div>
					<div className="ppo-form__column">{this.objectForm}</div>
				</div>
				<div className="ppo-form ppo-form--btn">
					<div className="ppo-form__column">
						<div className="input-field check-field check-field-policy">
							<label className={this.state.triggerValidate && !this.state.check ? 'error' : ''}>
								<input
									type="checkbox"
									checked={this.state.check}
									name="check"
									onChange={this.handleChangeCheck}
									onKeyPress={this.checkKeyPressed}
								/>
								<i></i>
								<span>
									Даю согласие на обработку <br />
									<a href="/personal-data" target="_blank" tabIndex="-1">
										моих персональных данных
									</a>
								</span>
							</label>
						</div>
					</div>
					<div className="ppo-form__column">
						<Button submit={true} onClick={this.handleSend} loading={this.state.loading}>
							Отправить
						</Button>
					</div>
				</div>
			</div>
		)
	}

	get objectForm() {
		return (
			<Fragment>
				<SelectField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.address}
					keyPressed={this.handleSend}
				/>
				<SelectField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.category}
					keyPressed={this.handleSend}
				/>
				{this.props.flat ? (
					<InputField
						triggerValidate={this.state.triggerValidate}
						setFieldValid={this.setFieldValid}
						change={this.handleChange}
						{...this.state.flat}
						keyPressed={this.handleSend}
					/>
				) : (
					<SelectField
						triggerValidate={this.state.triggerValidate}
						setFieldValid={this.setFieldValid}
						change={this.handleChange}
						{...this.state.flat}
						keyPressed={this.handleSend}
					/>
				)}
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.description}
				/>
			</Fragment>
		)
	}

	get profileForm() {
		const { userInfo } = this.props
		return (
			<Fragment>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.email}
					keyPressed={this.handleSend}
				/>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.last_name}
					keyPressed={this.handleSend}
				/>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.first_name}
					keyPressed={this.handleSend}
				/>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.second_name}
					keyPressed={this.handleSend}
				/>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.phone}
					keyPressed={this.handleSend}
				/>
			</Fragment>
		)
	}

	handleSend = async () => {
		this.setState({ triggerValidate: true })
		if (!this.formIsValid() || this.props.loading) return false
		this.setState({ loading: true })
		await this.props.sendPpo({
			email: this.props.user.email,
			token: this.props.user.token,
			first_name: this.state.first_name.value,
			second_name: this.state.second_name.value,
			last_name: this.state.last_name.value
		})
		this.setState({ loading: false })
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			const field = this.state[name]
			if (field.name === 'phone' && this.props.userInfo.phone) return true
			if (!field.validate) return true
			return field.valid
		})
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		if (name === 'phone') this.props.sendSmsReload()
		this.setState({ [name]: { ...this.state[name], value } })
	}

	handleChangeCheck = (ev) => {
		const { name, checked } = ev.target
		this.setState({ [name]: checked })
	}

	checkKeyPressed = (event) => {
		if (event.key === 'Enter') {
			this.setState({ check: !this.state.check })
		}
	}
}

export default connect(
	(state) => ({
		userInfo: userInfoSelector(state),
		user: state.auth.user
	}),
	{ getUserInfo, sendPpo }
)(Personal)
