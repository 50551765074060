import React, { Component } from 'react'
import { connect } from 'react-redux'
import SelectField from '../../common/select'
import { changeField, sectionOptions } from '../../../redux/modules/mailing'

class Section extends Component {
	render() {
		const { filter, options } = this.props
		if (!options.length) return null
		return (
			<div className="filter-field filter-field--small">
				<div className="filter-field__title">Секция</div>
				<div className="filter-field__body">
					<SelectField
						change={this.changeField}
						{...filter.section}
						options={[...filter.section.options, ...options]}
					/>
				</div>
			</div>
		)
	}

	changeField = (ev) => this.props.changeField(ev.target.name, ev.target.value)
}

export default connect(
	(state) => ({
		filter: state.mailing.filter,
		options: sectionOptions(state)
	}),
	{ changeField }
)(Section)
