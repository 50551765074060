import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { objectSelector, errorObjectSelector, loadingObjectSelector, loadObject } from '../../../redux/modules/booking'
import Row from './row'
class Table extends Component {
	componentDidMount() {
		if (!this.props.object && !this.props.loading) {
			this.props.loadObject({
				cmd: 'get_object',
				article: this.props.article,
				object: this.props.id
			})
		}
	}

	render() {
		const { errorObject, object } = this.props
		if (errorObject) return <div className="table-info">{errorObject}</div>
		if (!object) return null

		const title =
			object.Type === 'Жилое'
				? 'Квартиры'
				: object.Type === 'Кладовая'
				? 'Кладовые помещения'
				: object.Type === 'Машиноместо'
				? 'Паркинг'
				: 'Коммерческие помещения'

		return (
			<div className="table">
				<div className="table-title">{title}</div>
				<table>
					<thead>
						<tr>
							<th className="title-img"></th>
							<th className="title-text">Информация об объекте</th>
							<th className="title-icon">Документация</th>
							<th className="title-square">
								Площадь, м
								<sup>
									<small>2</small>
								</sup>
							</th>
							<th className="title-price">Стоимость, ₽</th>
						</tr>
					</thead>
					<tbody>
						<Row object={object} />
					</tbody>
				</table>
			</div>
		)
	}
}

export default connect(
	(state) => ({
		object: objectSelector(state),
		loadingObject: loadingObjectSelector(state),
		errorObject: errorObjectSelector(state),
		loadingObject: loadingObjectSelector(state)
	}),
	{ loadObject }
)(Table)
