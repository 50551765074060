import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputField from '../common/input-field'
import { Link } from 'react-router-dom'
import { closeError } from '../../redux/modules/deals'
// import Counter from '../common/counter'

class Message extends Component {
	static propTypes = {
		close: PropTypes.func,
		article: PropTypes.string.isRequired,
		object: PropTypes.string.isRequired
	}

	id = null

	componentDidMount() {
		this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.close, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		return (
			<div className="modal" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.hide}></div>
				<div className="modal__container">
					<div className="modal__body">
						<div className="modal-close ico-close" onClick={this.hide}></div>
						<div className="modal-title">Для продолжения бронирования необходимо заполнить профиль</div>
						<div className="form-content">
							<div className="modal-text"></div>
							<Link
								to={`/booking/${this.props.article}/${this.props.object}`}
								onClick={this.props.close}
								className="input-field btn-field"
							>
								<div className="btn">Продолжить бронирование</div>
							</Link>
						</div>
					</div>
					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}
}

export default Message
