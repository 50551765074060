import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Reveal from 'react-reveal/Reveal'
import InputField from './common/input-field'
import ErrorMessage from './common/error-message'
import {
	signUp,
	authErrorSelector,
	closeErrorMessage,
	attemptsSingUpSelector,
	authLoadingSelector
} from '../redux/modules/auth'
import Button from './common/button'
import layout from '../decorators/layout'

class SignUp extends Component {
	componentDidMount() {
		document.title = 'Регистрация - Сервис онлайн-покупки PSN group'
	}
	state = {
		triggerValidate: false,
		email: {
			value: '',
			name: 'email',
			type: 'email',
			placeholder: 'Email',
			validate: 'email',
			valid: false
		},
		check: false
	}

	render() {
		return (
			<Fragment>
				<div className="page-bg animate-scale"></div>
				<div className="auth-logo ico-1 animate-fade animate-delay02"></div>

				<div className="modal modal-registration visible">
					<div className="modal__container">
						<Reveal effect="fadeInUp">
							<div className="modal__body">
								<div className="modal-title">
									Создайте
									<br />
									учетную запись
								</div>
								<div className="form-content js-registration">
									<InputField
										triggerValidate={this.state.triggerValidate}
										setFieldValid={this.setFieldValid}
										change={this.handleChange}
										keyPressed={this.handleSend}
										className="ico-email"
										{...this.state.email}
									/>
									<div className="input-field check-field check-field-policy">
										<label
											className={this.state.triggerValidate && !this.state.check ? 'error' : ''}
										>
											<input
												type="checkbox"
												checked={this.state.check}
												name="check"
												onChange={this.handleChangeCheck}
												onKeyPress={this.keyPressed}
											/>
											<i></i>
											<span>
												Даю согласие на обработку <br />
												<a href="/personal-data" target="_blank" tabIndex="-1">
													моих персональных данных
												</a>
											</span>
										</label>
									</div>
									<Button
										submit={true}
										onClick={this.handleSend}
										onKeyPress={this.keyPressed}
										loading={this.props.loading}
									>
										Зарегистрироваться
									</Button>
									<div className="modal-text modal-text--bottom">
										Регистрируясь, вы соглашаетесь
										<br />с{' '}
										<a href="https://www.psngroup.ru/privacy-policy.php" target="_blank">
											Политикой конфиденциальности.
										</a>
									</div>
									<div className="form-links">
										<div className="form-links__item">
											<Link to="/sign-in">Авторизация</Link>
										</div>
									</div>
								</div>
							</div>
						</Reveal>

						<div className="modal-helper"></div>
					</div>
				</div>
				{this.props.error && <ErrorMessage error={this.props.error} close={this.props.closeErrorMessage} />}
			</Fragment>
		)
	}

	keyPressed = (event) => {
		if (event.key === 'Enter') {
			this.handleSend()
		}
	}

	handleSend = () => {
		if (this.props.loading) return false
		this.setState({ triggerValidate: true })
		if (!this.formIsValid()) return false

		this.props.signUp(
			{
				cmd: 'user_add',
				email: this.state.email.value
			},
			this.props.attempts
		)
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			if (name === 'check') return this.state.check
			const field = this.state[name]
			if (!field.validate) return true
			return field.valid
		})
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}

	handleChangeCheck = (ev) => {
		const { name, checked } = ev.target
		this.setState({ [name]: checked })
	}
}

export default connect(
	(state) => ({
		error: authErrorSelector(state),
		attempts: attemptsSingUpSelector(state),
		loading: authLoadingSelector(state)
	}),
	{ signUp, closeErrorMessage }
)(layout(SignUp))
