import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import accounting from 'accounting'
import Checkbox from '../../common/checkbox'
import { checkRow, checkedListSelector } from '../../../redux/modules/mailing'

class Row extends Component {
	state = {
		checked: false
	}

	render() {
		const { el, mobile, first, checkedList } = this.props
		return (
			<tr>
				<td>
					<span className="cell__title mobile">Дата</span>
					{el.date}
				</td>
				<td>
					<span className="cell__title mobile">Клиент</span>
					{first ? el.client_name : ''}
				</td>
				<td>
					<span className="cell__title mobile">Email</span>
					{first ? el.client_email : ''}
				</td>
				<td>
					<span className="cell__title mobile">Проект</span>
					{el.BuildingGroupName.replace(/^[0-9][0-9]\./g, '')}{' '}
				</td>
				<td>
					<span className="cell__title mobile">Код</span>
					{el.ObjectID}
				</td>
				<td className="cell-center">
					<span className="cell__title mobile">Корпус</span>
					{el.corpus}
				</td>
				<td className="cell-center">
					<span className="cell__title mobile">Секция</span>
					{el.section}
				</td>
				<td className="cell-center">
					<span className="cell__title mobile">Этаж</span>
					{el.Floor}
				</td>
				<td className="cell-center">
					<span className="cell__title mobile">№&nbsp;кв.</span>
					{el.BeforeBtiNumber}
				</td>
				<td className="cell-center">
					<span className="cell__title mobile">Комнат</span>
					{el.Rooms}
				</td>
				<td className="cell-center cell-price">
					<span className="cell__title mobile">Стоимость, ₽</span> {accounting.formatNumber(el.Price, 0, ' ')}{' '}
				</td>
				<td className="cell-img">
					<img src={el.Layout ? el.Layout : '/images/default.jpg'} alt="" />
				</td>
				{el.status === 1 ? (
					<td>
						<span className="cell__title mobile">Статус</span>Не&nbsp;отправлено
					</td>
				) : (
					<td>
						<span className="cell__title mobile">Статус</span>Отправлено
					</td>
				)}
				<td>
					<span className="cell__title mobile">Выбрать</span>
					<Checkbox title="" checked={checkedList.indexOf(el.id) > -1} change={this.handleChange} />
				</td>
			</tr>
		)
	}

	handleChange = () => {
		const { el, checkRow, checkedList } = this.props
		const newCheckedList =
			checkedList.indexOf(el.id) > -1 ? checkedList.filter((id) => id !== el.id) : [...checkedList, el.id]
		checkRow(newCheckedList)
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile,
		checkedList: checkedListSelector(state)
	}),
	{ checkRow }
)(Row)
