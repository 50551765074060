import React, { Component } from 'react'
import { connect } from 'react-redux'
import { newEmail } from '../redux/modules/auth'
import layout from '../decorators/layout'

class NewEmail extends Component {
	componentDidMount() {
		this.props.newEmail({
			cmd: 'change_email',
			checkword: this.props.checkword,
			email: this.props.email,
			new_email: this.props.new_email
		})
	}

	render() {
		return null
	}
}

export default connect(null, { newEmail })(NewEmail)
