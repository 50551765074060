import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadDeals } from '../../redux/modules/deals'

class Loader extends Component {
	id = null

	componentDidMount() {
		const { loadDeals, user, refresh_after } = this.props
		this.id = setInterval(() => {
			loadDeals({
				cmd: 'user_deals',
				email: user.email,
				token: user.token
			})
		}, refresh_after * 1000)
	}

	componentWillUnmount() {
		clearInterval(this.id)
	}

	render() {
		return (
			<div className="crm_loader">
				История ваших сделок будет обновлена в течение нескольких минут <br />{' '}
				<img src="/images/loading.gif" alt="" />{' '}
			</div>
		)
	}
}

export default connect(
	(state) => ({
		user: state.auth.user,
		not_synced: state.deals.not_synced,
		refresh_after: state.deals.refresh_after
	}),
	{ loadDeals }
)(Loader)
