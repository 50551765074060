import axios from 'axios'
import { api } from '../config'
import { createSelector } from 'reselect'
import { distinct, objectToMap, arrayToMap } from '../../services/utils'
import favorites from '../mocks/favorites'

/**
 * Constants
 * */
export const moduleName = 'favorites'
export const LOAD_START = `${moduleName}/LOAD_START`
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`
export const LOAD_ERROR = `${moduleName}/LOAD_ERROR`
export const CLOSE_ERROR = `${moduleName}/CLOSE_ERROR`
export const REMOVE_START = `${moduleName}/REMOVE_START`
export const REMOVE_SUCCESS = `${moduleName}/REMOVE_SUCCESS`
export const REMOVE_ERROR = `${moduleName}/REMOVE_ERROR`
export const REMOVE_ALL_START = `${moduleName}/REMOVE_ALL_START`
export const REMOVE_ALL_SUCCESS = `${moduleName}/REMOVE_ALL_SUCCESS`
export const REMOVE_ALL_ERROR = `${moduleName}/REMOVE_ERROR`
export const ADD_START = `${moduleName}/ADD_START`
export const ADD_SUCCESS = `${moduleName}/ADD_SUCCESS`
export const ADD_ERROR = `${moduleName}/ADD_ERROR`

/**
 * Reducer
 * */
const defaultState = {
	loading: false,
	loaded: false,
	error: null,
	remove_loading: false,
	remove_all_loading: false,
	add_loading: false,
	success: false,
	entities: []
}

export default (state = defaultState, action) => {
	const { type, error, payload } = action

	switch (type) {
		case LOAD_START:
			return { ...state, loading: true }

		case LOAD_ERROR:
			return { ...state, loading: false, error }

		case LOAD_SUCCESS:
			return { ...state, loading: false, loaded: true, entities: payload.favorites }

		case CLOSE_ERROR:
			return { ...state, error: null }

		case REMOVE_START:
			return { ...state, remove_loading: true }

		case REMOVE_ERROR:
			return { ...state, remove_loading: false, error }

		case REMOVE_SUCCESS:
			return {
				...state,
				remove_loading: false,
				entities: state.entities.filter((el) => el.ObjectID !== payload.id)
			}

		case REMOVE_ALL_START:
			return { ...state, remove_all_loading: true }

		case REMOVE_ALL_ERROR:
			return { ...state, remove_all_loading: false, error }

		case REMOVE_ALL_SUCCESS:
			return {
				...state,
				remove_loading: false,
				entities: []
			}

		case ADD_START:
			return { ...state, add_loading: true }

		case ADD_ERROR:
			return { ...state, add_loading: false, error }

		case ADD_SUCCESS:
			return {
				...state,
				add_loading: false,
				success: true,
				entities: [...state.entities, payload.object]
			}

		default:
			return state
	}
}

/**
 * Selectors
 * */
export const errorSelector = (state) => state[moduleName].error
export const loadingSelector = (state) => state[moduleName].loading
export const loadedSelector = (state) => state[moduleName].loaded
export const favoritesSelector = (state) => state[moduleName].entities
export const removeLoadingSelector = (state) => state[moduleName].remove_loading
export const removeAllLoadingSelector = (state) => state[moduleName].remove_all_loading
export const groupSelector = createSelector(favoritesSelector, (list) => objectToMap(list, 'Type'))
export const favSuccessSelector = (state) => state[moduleName].success
/**
 * Action Creators
 * */

export const removeFavoriteItem = (params) => async (dispatch) => {
	dispatch({ type: REMOVE_START })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) dispatch({ type: REMOVE_SUCCESS, payload: { id: params.object } })
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		if (error.response) dispatch({ type: REMOVE_ERROR, error: error.response.data.message })
	}
}

export const addFavoriteItem = (params) => async (dispatch) => {
	dispatch({ type: ADD_START })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) dispatch({ type: ADD_SUCCESS, payload: { object: data.object } })
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		if (error.response) dispatch({ type: ADD_ERROR, error: error.response.data.message })
	}
}

export const removeFavorites = (params) => async (dispatch) => {
	// dispatch({ type: REMOVE_ALL_SUCCESS })
	dispatch({ type: REMOVE_ALL_START })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) dispatch({ type: REMOVE_ALL_SUCCESS, payload: { id: params.object } })
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		dispatch({ type: REMOVE_ALL_ERROR, error: error.response.data.message })
	}
}

export const closeError = () => {
	return {
		type: CLOSE_ERROR
	}
}

export const loadFavorites = (params) => async (dispatch) => {
	dispatch({ type: LOAD_START })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) dispatch({ type: LOAD_SUCCESS, payload: { favorites: data.objects } })
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error_code === 4) {
			dispatch({ type: 'LOG_OUT_MESSAGE' })
		}
		if (error.response) dispatch({ type: LOAD_ERROR, error: error.response.data.message })
	}
}
