import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SelectField extends Component {
	static propTypes = {
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.string.isRequired,
		change: PropTypes.func.isRequired,
		options: PropTypes.array.isRequired
	}

	render() {
		return (
			<div className="input-field select-field">
				<select name={this.props.name} value={this.props.value} onChange={this.props.change}>
					{this.options}
				</select>
			</div>
		)
	}

	get options() {
		return this.props.options.map((el, i) => (
			<option key={i} value={el.value}>
				{el.label}
			</option>
		))
	}
}

export default SelectField
