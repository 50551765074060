import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeForm } from '../../redux/modules/form'
import Form from './form'

class SignUpSuccess extends Component {
	id = null

	componentDidMount() {
		if (this.container) this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.closeForm, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		return (
			<div className="modal modal-ppo" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.hide}></div>
				<div className="modal__container">
					<div className="modal__body">
						<div className="modal-close ico-close" onClick={this.hide}></div>
						<div className="modal-title">постпродажное обслуживание</div>
						<Form flat={this.props.flat} />
					</div>
					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}
}

export default connect(
	(state) => ({
		checked: state.mailing.checked,
		templates: state.mailing.templates,
		discount: state.mailing.discount
	}),
	{ closeForm }
)(SignUpSuccess)
