import React, { Fragment } from 'react'
import Header from '../components/layout/header2'
import Footer from '../components/layout/footer2'

export default (OriginalComponent) =>
	class LayoutComponent extends React.Component {
		render() {
			return (
				<Fragment>
					<div className="page">
						<Header />
						<OriginalComponent {...this.props} />
						<Footer />
					</div>
				</Fragment>
			)
		}
	}
