import axios from 'axios'
import { api } from '../config'
import { createSelector } from 'reselect'
import { distinct, objectToMap, arrayToMap } from '../../services/utils'
import faq from '../mocks/faq'

/**
 * Constants
 * */
export const moduleName = 'faq'
export const LOAD_START = `${moduleName}/LOAD_START`
export const LOAD_SUCCESS = `${moduleName}/LOAD_SUCCESS`
export const LOAD_ERROR = `${moduleName}/LOAD_ERROR`
export const CLOSE_ERROR = `${moduleName}/CLOSE_ERROR`

export const CLOSE_FORM = `${moduleName}/CLOSE_FORM`
export const OPEN_FORM = `${moduleName}/OPEN_FORM`
export const CLOSE_FORM_SUCCESS = `${moduleName}/CLOSE_FORM_SUCCESS`

export const SEND_START = `${moduleName}/SEND_START`
export const SEND_SUCCESS = `${moduleName}/SEND_SUCCESS`
export const SEND_ERROR = `${moduleName}/SEND_ERROR`

/**
 * Reducer
 * */
const defaultState = {
	loading: false,
	loaded: false,
	error: null,
	remove_loading: false,
	remove_all_loading: false,
	entities: [],
	sections: [],

	formOpen: false,
	pending: false,
	fulfilled: false
}

export default (state = defaultState, action) => {
	const { type, error, payload } = action

	switch (type) {
		case LOAD_START:
			return { ...state, loading: true }

		case LOAD_ERROR:
			return { ...state, loading: false, error }

		case LOAD_SUCCESS:
			// todo
			return { ...state, loading: false, loaded: true, entities: payload.faq, sections: payload.sections }
		// return { ...state, loading: false, loaded: true, entities: payload.faq}

		case CLOSE_ERROR:
			return { ...state, error: null }

		case OPEN_FORM:
			return { ...state, formOpen: true }

		case CLOSE_FORM:
			return { ...state, formOpen: false }

		case SEND_START:
			return { ...state, pending: true }

		case SEND_ERROR:
			return { ...state, pending: false, error }

		case SEND_SUCCESS:
			return { ...state, pending: false, fulfilled: true }

		case CLOSE_FORM_SUCCESS:
			return { ...state, fulfilled: false }

		default:
			return state
	}
}

/**
 * Selectors
 * */
export const errorSelector = (state) => state[moduleName].error
export const loadingSelector = (state) => state[moduleName].loading
export const loadedSelector = (state) => state[moduleName].loaded
export const faqSelector = (state) => state[moduleName].entities
export const groupSelector = createSelector(faqSelector, (list) => objectToMap(list, 'group'))
export const sectionsSelector = (state) => state[moduleName].sections

export const formOpenSelector = (state) => state[moduleName].formOpen
export const pendingSelector = (state) => state[moduleName].pending
export const fulfilledSelector = (state) => state[moduleName].fulfilled
/**
 * Action Creators
 * */

export const closeSuccess = () => {
	return {
		type: CLOSE_FORM_SUCCESS
	}
}

export const openForm = () => {
	return {
		type: OPEN_FORM
	}
}
export const closeForm = () => {
	return {
		type: CLOSE_FORM
	}
}

export const closeError = () => {
	return {
		type: CLOSE_ERROR
	}
}

export const loadQuestions = (params) => async (dispatch) => {
	dispatch({ type: LOAD_START })
	try {
		const { data } = await axios.get(api, { params })
		if (data.status) {
			dispatch({ type: LOAD_SUCCESS, payload: { faq: data.faq.questions, sections: data.faq.sections } })
		}
	} catch (error) {
		console.log(error)
		dispatch({ type: LOAD_ERROR, error: error.response.data.message })
	}
}

export const sendQuestion = (params) => async (dispatch) => {
	dispatch({ type: SEND_START })
	var formData = new FormData()
	formData.append('cmd', params.cmd)
	formData.append('name', params.name)
	formData.append('email', params.email)
	formData.append('email2', params.email2)
	formData.append('question', params.question)
	formData.append('file', params.file)

	try {
		const { data } = await axios.post(api, formData)
		if (data.status) dispatch({ type: SEND_SUCCESS })
	} catch (error) {
		console.log(error)
		dispatch({ type: SEND_ERROR, error: error.response.data.message })
	}

	// $.ajax({
	// 	url: api,
	// 	type: 'POST',
	// 	data: formData,
	// 	processData: false,
	// 	contentType: false,
	// 	dataType: 'json',
	// 	success: function(response) {
	// 		if (response.status) {
	// 			dispatch({ type: SEND_SUCCESS })
	// 		} else {
	// 			dispatch({ type: SEND_SUCCESS })
	// 		}
	// 	},
	// 	error: function(error) {
	// 		console.log(error)
	// 		dispatch({ type: SEND_ERROR, error: error })
	// 	}
	// })
}
