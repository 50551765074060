import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeModal } from '../../redux/modules/mailing'
import { declOfNum } from '../../services/utils'

class SignUpSuccess extends Component {
	id = null

	componentDidMount() {
		if (this.container) this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.closeModal, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		return (
			<div className="modal modal-mail" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.hide}></div>
				<div className="modal__container">
					<div className="modal__body">
						<div className="modal-close ico-close" onClick={this.hide}></div>
						{this.body}
					</div>
					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	get body() {
		const { checked, templates, discount } = this.props
		if (!checked.length) {
			return (
				<div className="form-content">
					<div className="modal-text">Выберите объекты</div>
					<div className="input-field btn-field" onClick={this.hide}>
						<div className="btn">Закрыть</div>
					</div>
				</div>
			)
		}
		if (!templates.value) {
			return (
				<div className="form-content">
					<div className="modal-text">Выберите шаблон</div>
					<div className="input-field btn-field" onClick={this.hide}>
						<div className="btn">Закрыть</div>
					</div>
				</div>
			)
		}
		const template = templates.options.find((el) => +el.value === +templates.value)
		return (
			<div className="form-content">
				<div className="modal-text">
					Вы уверены, что хотите отправить {checked.length}{' '}
					{declOfNum(checked.length, ['письмо', 'письма', 'писем'])} по шаблону <br />
					<span>«{template.label}»</span>?
				</div>
				<div className="input-field btn-field" onClick={this.hide}>
					<div className="btn">Отправить</div>
				</div>
				<div className="input-field btn-field" onClick={this.hide}>
					<div className="btn-link">
						<span>Отменить</span>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
	(state) => ({
		checked: state.mailing.checked,
		templates: state.mailing.templates,
		discount: state.mailing.discount
	}),
	{ closeModal }
)(SignUpSuccess)
