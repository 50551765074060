import React, { Component } from 'react'
import { connect } from 'react-redux'
import SelectField from '../../common/select'
import { changeField, corpusFromOptions, corpusToOptions } from '../../../redux/modules/mailing'

class Corpus extends Component {
	render() {
		const { filter, from, to } = this.props
		if (!from.length) return null
		return (
			<div className="filter-field filter-field--middle">
				<div className="filter-field__title">Корпус</div>
				<div className="filter-field__body filter-field--from-to">
					<SelectField
						change={this.changeFrom}
						{...filter.corpus_from}
						options={[...filter.corpus_from.options, ...from]}
					/>
					<SelectField
						change={this.changeTo}
						{...filter.corpus_to}
						options={[...filter.corpus_to.options, ...to]}
					/>
				</div>
			</div>
		)
	}

	changeFrom = (ev) => {
		const { changeField, filter, to } = this.props
		changeField(ev.target.name, ev.target.value)
		if (filter.corpus_to.value < ev.target.value) changeField('corpus_to', ev.target.value)
		if (filter.corpus_to.value === '') changeField('corpus_to', to[to.length - 1].value)
		if (ev.target.value === '') changeField('corpus_to', '')
	}

	changeTo = (ev) => {
		const { filter, changeField, from } = this.props
		changeField(ev.target.name, ev.target.value)
		if (ev.target.value === '') changeField('corpus_from', '')
		if (ev.target.value && filter.corpus_from.value === '') changeField('corpus_from', from[0].value)
	}
}

export default connect(
	(state) => ({
		filter: state.mailing.filter,
		from: corpusFromOptions(state),
		to: corpusToOptions(state)
	}),
	{ changeField }
)(Corpus)
