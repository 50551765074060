export default (store) => (next) => (action) => {
	const { setLocalStorage, getLocalStorage, removeLocalStorage, ...rest } = action

	if (!setLocalStorage && !getLocalStorage && !removeLocalStorage) return next(action)

	if (setLocalStorage) {
		let { value, name } = setLocalStorage

		if (value.token_lifetime) {
			const date = new Date()
			date.setTime(date.getTime() + value.token_lifetime * 1000)
			value = { ...value, expiration: date }
		}

		localStorage.setItem(name, JSON.stringify(value))
		return next({ ...rest })
	}

	if (removeLocalStorage) {
		localStorage.removeItem(removeLocalStorage)
		return next({ ...rest })
	}

	const storageValue = JSON.parse(localStorage.getItem(getLocalStorage))

	next({ ...rest, storageValue })
}
