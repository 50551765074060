import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<div className="content">
					<div>
						<div className="footer-logo ico-1"></div>
						<span className="footer-copy">© Группа ПСН {new Date().getFullYear()}</span>
					</div>
					<div>
						<a href="https://www.psngroup.ru/privacy-policy.php" className="footer-link" target="_blank">
							Политика конфиденциальности
						</a>
					</div>
				</div>
			</div>
		)
	}
}

export default Footer
