import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from './common/input-field'
import Button from './common/button'
import { phoneСonfirm, smsCodeCheck, sendSmsReload, getUserInfo } from '../redux/modules/profile'

class SmsCode extends Component {
	state = {
		triggerValidate: false,
		phone: {
			value: '',
			name: 'phone',
			type: 'tel',
			placeholder: 'Телефон',
			validate: 'phone',
			valid: false
		},
		code: {
			value: '',
			name: 'code',
			type: 'text',
			placeholder: 'Код',
			validate: 'code',
			valid: false
		},
		pending: false,
		count: 0,
		open: true
	}

	id = null

	componentDidMount() {
		const { info } = this.props
		this.id = setTimeout(() => {
			if (this.container) this.container.classList.add('visible')
		}, 10)
		if (info.phone_not_confirm) {
			this.setState({ phone: { ...this.state.phone, value: info.phone_not_confirm, valid: true } })
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { info } = this.props
		if (info.phone_not_confirm && info.phone_not_confirm !== prevProps.info.phone_not_confirm) {
			this.setState({ phone: { ...this.state.phone, value: info.phone_not_confirm, valid: true } })
		}
		if (this.container) {
			clearTimeout(this.id)
			this.id = setTimeout(() => {
				if (this.container) this.container.classList.add('visible')
			}, 10)
		}
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(() => {
			this.setState({ open: false })
		}, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
		this.props.sendSmsReload()
	}

	render() {
		const { info, user, bookingPage, bookingError, not_synced } = this.props
		if (!user || not_synced) return null
		// if (!this.state.open) return null
		if (!this.state.open || info.crm_id || !info.first_name) return null
		return (
			<div className="modal" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.hide}></div>
				<div className="modal__container">
					<div className="modal__body">
						<div className="modal-title">Подтвердить телефон</div>
						<div className="form-content">
							{this.phone}
							{this.code}
						</div>
					</div>
					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	get phone() {
		if (this.props.sms_sent) return null
		return (
			<div>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.phone}
					keyPressed={this.handlePhoneСonfirm}
				/>
				<Button submit={true} onClick={this.handlePhoneСonfirm} loading={this.state.pending}>
					Подтвердить
				</Button>
			</div>
		)
	}

	get code() {
		if (!this.props.sms_sent) return null
		return (
			<div>
				<div className="modal-text modal-text--large-center">
					Мы {this.state.count === 2 ? 'повторно ' : ''}отправили смс с кодом
					<br /> на номер {this.state.phone.value}
				</div>
				<InputField
					triggerValidate={this.state.triggerValidate}
					setFieldValid={this.setFieldValid}
					change={this.handleChange}
					{...this.state.code}
					keyPressed={this.handleSend}
				/>
				<Button submit={true} onClick={this.handleSend} loading={this.state.pending}>
					Отправить
				</Button>
				<div className="form-links">
					{this.state.count === 1 && (
						<div className="form-links__item" onClick={this.handlePhoneСonfirm}>
							<div className="sms-link">
								<span>Отправить повторно</span>
							</div>
						</div>
					)}
					<div className="form-links__item" onClick={this.handleChangePhone}>
						<div className="sms-link">
							<span>Изменить номер телефона</span>
						</div>
					</div>
				</div>
			</div>
		)
	}

	handleChangePhone = () => {
		this.setState({ count: 0 })
		this.props.sendSmsReload()
	}

	handlePhoneСonfirm = async () => {
		if (!this.phoneIsValid() || this.state.pending) return false
		this.setState({ pending: true, count: this.state.count + 1 })
		await this.props.phoneСonfirm({
			cmd: 'sms_code_send',
			email: this.props.user.email,
			token: this.props.user.token,
			phone: ('' + this.state.phone.value).replace(/\D/g, '')
		})
		this.setState({ pending: false })
	}

	handleSend = async () => {
		this.setState({ triggerValidate: true })
		if (!this.formIsValid() || this.state.pending) return false

		this.setState({ pending: true })
		await this.props.smsCodeCheck({
			cmd: 'sms_code_check',
			email: this.props.user.email,
			token: this.props.user.token,
			code: this.state.code.value,
			phone: ('' + this.state.phone.value).replace(/\D/g, '')
		})
		this.setState({ pending: false })
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			const field = this.state[name]
			if (!field.validate) return true
			return field.valid
		})
	}

	phoneIsValid = () => this.state['phone'].valid

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}
}

export default connect(
	(state) => ({
		user: state.auth.user,
		info: state.profile.info,
		sms_sent: state.profile.smsSent,
		confirm: state.profile.confirmPhone,
		not_synced: state.deals.not_synced
	}),
	{ phoneСonfirm, smsCodeCheck, sendSmsReload, getUserInfo }
)(SmsCode)
