import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	changeTemplate,
	changeDiscount,
	changeRadio,
	checkRow,
	sortedSelector,
	openModal
} from '../../redux/modules/mailing'
import SelectField from '../common/select'

class Send extends Component {
	render() {
		const { templates } = this.props
		return (
			<div className="mailing-send">
				<div className="filter-field filter-field--large2">
					<SelectField change={this.changeTemplate} {...templates} />
				</div>
				<div className="filter-field filter-field--middle">
					<div className="field-text">
						<input
							type="number"
							placeholder="Задать скидку"
							onChange={this.changeField}
							value={this.props.discount}
						/>
					</div>
				</div>
				<div class="filter-field filter-field--btn">
					<div class="btn" onClick={this.checkAll}>
						выбрать все
					</div>
				</div>
				<div class="filter-field filter-field--btn">
					<div class="btn btn2" onClick={this.props.openModal}>
						отправить
					</div>
				</div>
			</div>
		)
	}

	changeField = (ev) => this.props.changeDiscount(ev.target.value)
	changeTemplate = (ev) => this.props.changeTemplate(ev.target.value)

	checkAll = (ev) => {
		const { changeRadio, checkRow, sorted } = this.props
		changeRadio(4)
		let newCheckedList = []
		sorted.map((client) => client.map((el) => newCheckedList.push(el.id)))
		checkRow(newCheckedList)
	}
}

export default connect(
	(state) => ({
		user: state.auth.user,
		templates: state.mailing.templates,
		discount: state.mailing.discount,
		sorted: sortedSelector(state),
		filter: state.mailing.filter
	}),
	{ changeTemplate, changeDiscount, changeRadio, checkRow, openModal }
)(Send)
