import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputField from '../common/input-field'
import { closePasswordSuccess } from '../../redux/modules/profile'
import Counter from '../common/counter'

class ChangeEmail extends Component {
	id = null

	componentDidMount() {
		if (this.container) this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.closePasswordSuccess, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		return (
			<div className="modal modal-change-password-success" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.hide}></div>
				<div className="modal__container">
					<div className="modal__body" data-redirect="auth.html">
						<div className="modal-title">
							Вы успешно
							<br />
							изменили пароль!
						</div>

						<div className="form-content">
							<div className="modal-text">
								Используйте его для&nbsp;последующих входов в&nbsp;Личный кабинет
							</div>
							<div className="input-field btn-field" onClick={this.hide}>
								<div className="btn">закрыть</div>
							</div>
						</div>
						{/*<div className="modal-text modal-text--bottom">
							Это окно будет скрыто автоматически через <Counter callback={this.hide} /> секунд
						</div>*/}
					</div>

					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}
}

export default connect(null, { closePasswordSuccess })(ChangeEmail)
