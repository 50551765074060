import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Reveal from 'react-reveal/Reveal'
import InputField from './common/input-field'
import {
	resetPassword,
	authErrorSelector,
	closeErrorMessage,
	resetPasswordEmailSelector,
	authLoadingSelector
} from '../redux/modules/auth'
import ErrorMessage from './common/error-message'
import Button from './common/button'
import layout from '../decorators/layout'

class ResetPassword extends Component {
	componentDidMount() {
		document.title = 'Забыли пароль - Сервис онлайн-покупки PSN group'
	}
	state = {
		resending: false,
		triggerValidate: false,
		email: {
			value: '',
			name: 'email',
			type: 'email',
			placeholder: 'Email',
			validate: 'email',
			valid: false
		}
	}

	render() {
		return (
			<div>
				<div className="page-bg animate-scale"></div>
				<div className="auth-logo ico-1 animate-fade animate-delay02"></div>
				{this.props.email ? this.success : this.form}
				{this.props.error && <ErrorMessage error={this.props.error} close={this.props.closeErrorMessage} />}
			</div>
		)
	}

	get form() {
		return (
			<div className="modal modal-reset-password visible">
				<div className="modal__container">
					<Reveal effect="fadeInUp">
						<div className="modal__body js-reset-password">
							<div className="modal-title">Восстановление пароля</div>
							<div className="form-content">
								<div className="modal-text">
									Укажите адрес электронной почты для&nbsp;восстановления пароля
								</div>
								<InputField
									triggerValidate={this.state.triggerValidate}
									setFieldValid={this.setFieldValid}
									change={this.handleChange}
									className="ico-email"
									{...this.state.email}
									keyPressed={this.handleSend}
								/>
								<Button submit={true} onClick={this.handleSend} loading={this.props.loading}>
									сбросить пароль
								</Button>
								<div className="form-links">
									<div className="form-links__item">
										<Link
											to="/sign-in"
											className="form-links__item-auth--withbr"
											data-modal-change="auth"
										>
											<span>Я вспомнил пароль:</span>{' '}
											<span>вернуться&nbsp;к&nbsp;форме&nbsp;входа</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</Reveal>

					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	get success() {
		return (
			<div className="modal modal-reset-password-message visible">
				<div className="modal__container">
					<Reveal effect="fadeInUp" key={this.props.email + this.state.resending}>
						<div className="modal__body">
							<div className="modal-title">Проверьте вашу электронную почту</div>
							<div className="form-content">
								<div className="modal-text">
									<p>
										Мы {this.state.resending && 'повторно'} отправили письмо на адрес электронной
										почты{' '}
										<span className="color-orange js-reset-password-email">{this.props.email}</span>
										. Перейдите по ссылке в письме для изменения пароля.
									</p>
									<p>
										Если письма нет во входящих, проверьте другие папки (например, «Спам»,
										«Социальные сети» и т.д.)
									</p>
									{this.state.resending || (
										<p>
											<span className="link" onClick={this.handleResend}>
												Мне не пришло письмо
											</span>
										</p>
									)}
								</div>
							</div>
						</div>
					</Reveal>

					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	handleSend = () => {
		if (this.props.loading) return false
		this.setState({ triggerValidate: true })
		if (!this.formIsValid()) return false

		this.props.resetPassword({
			cmd: 'chpass_request',
			email: this.state.email.value
		})
	}

	handleResend = () => {
		this.props.resetPassword({
			cmd: 'chpass_request',
			email: this.state.email.value
		})
		this.setState({ resending: true })
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			const field = this.state[name]
			if (!field.validate) return true
			return field.valid
		})
	}

	setFieldValid = (name, valid) => {
		this.setState({ [name]: { ...this.state[name], valid } })
	}

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}
}

export default connect(
	(state) => ({
		error: authErrorSelector(state),
		email: resetPasswordEmailSelector(state),
		loading: authLoadingSelector(state)
	}),
	{ resetPassword, closeErrorMessage }
)(layout(ResetPassword))
