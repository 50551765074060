import React, { Component, Fragment } from 'react'
import accounting from 'accounting'

class Row extends Component {
	render() {
		const { object } = this.props
		const price = object.show_price_for_status.includes(101) ? accounting.formatNumber(object.Price, 0, ' ') : '-'
		const title =
			object.Type === 'Жилое'
				? +object.Rooms === 0
					? 'Студия'
					: `${object.Rooms} - комнатная квартира `
				: object.Type === 'Нежилое'
				? 'Апартаменты'
				: object.Type

		return (
			<tr>
				<td className="cell-img">
					<img src={object.Layout ? object.Layout : '/images/default.jpg'} alt="" />
				</td>
				<td className="cell-text">
					<div>
						<span className="color-orange">{title}</span> <br />
						{object.AddressBuild}
						<br />
						{object.BuildingGroupName.replace(/^[0-9][0-9]\./g, 'ЖК ')}
					</div>
				</td>
				<td className="cell-icon cell-icon--book">
					{object.docs_url ? (
						<a href={object.docs_url} target="_blank">
							<span className="ico-22"></span>
						</a>
					) : (
						'-'
					)}
				</td>
				<td className="cell-square">
					<span className="cell__title mobile">
						Площадь, м<sup>2</sup>
					</span>
					{object.SpaceDesign}
				</td>
				<td className="cell-price">
					<span className="cell__title mobile">Стоимость, ₽ </span>
					{price}
				</td>
			</tr>
		)
	}
}

export default Row
