import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import InputField from '../common/input-field'
import {
	signIn,
	attemptsSingInSelector,
	authLoadingSelector,
	authErrorSelector,
	closeErrorMessage
} from '../../redux/modules/auth'
import ErrorMessage from '../common/error-message'
import Button from '../common/button'

class EnterPassword extends Component {
	state = {
		triggerValidate: false
	}
	render() {
		return (
			<Fragment>
				<div className="profile-page-form">
					<div className="profile-page-form__column js-profile-personal">
						<div className="form-title">
							Введите ваш пароль от&nbsp;учетной записи {this.props.email.value}
						</div>
						<div className={this.props.open ? 'password-wrap open' : 'password-wrap'}>
							<div className="password-wrap__handle-click" onClick={this.props.passwordToggle}></div>
							<InputField
								triggerValidate={this.state.triggerValidate}
								setFieldValid={this.props.setFieldValid}
								change={this.props.handleChange}
								className="ico-password"
								{...this.props.password}
								keyPressed={this.handleSend}
							/>
						</div>
						<Button submit={true} onClick={this.handleSend} loading={this.props.loading}>
							Авторизоваться
						</Button>
						<div className="form-links">
							<div className="form-links__item">
								<a href="/reset-password" target="_blank">
									Забыли пароль?
								</a>
							</div>
						</div>
					</div>
				</div>
				{this.props.error && <ErrorMessage error={this.props.error} close={this.props.closeErrorMessage} />}
			</Fragment>
		)
	}

	handleSend = () => {
		this.setState({ triggerValidate: true })
		if (!this.formIsValid() || this.props.loading) return false

		this.props.signIn(
			{
				cmd: 'user_authorize',
				email: this.props.email.value,
				password: this.props.password.value
			},
			this.props.attempts
		)
	}

	formIsValid = () => this.props.password.valid && this.props.email.valid
}

export default connect(
	(state) => ({
		error: authErrorSelector(state),
		attempts: attemptsSingInSelector(state),
		loading: authLoadingSelector(state)
	}),
	{ signIn, closeErrorMessage }
)(EnterPassword)
