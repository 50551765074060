import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import InputField from '../common/input-field'
import { newPassword, profileNewPasswordSuccessSelector, loadingSelector } from '../../redux/modules/profile'
import Success from './change-password-success'
import Button from '../common/button'

class NewPassword extends Component {
	state = {
		loading: false,
		triggerValidate: false,
		password: {
			value: '',
			name: 'password',
			type: 'text',
			placeholder: 'Текущий пароль',
			validate: 'password',
			valid: false
		},
		password1: {
			value: '',
			name: 'password1',
			type: 'text',
			placeholder: 'Новый пароль',
			validate: 'password',
			valid: false
		},
		password2: {
			value: '',
			name: 'password2',
			type: 'text',
			placeholder: 'Повторите новый пароль',
			validate: 'password',
			valid: false
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.success !== this.props.success && this.props.success) {
			this.setState({
				triggerValidate: false,
				password: { ...this.state.password, value: '', valid: false },
				password1: { ...this.state.password1, value: '', valid: false },
				password2: { ...this.state.password2, value: '', valid: false }
			})
		}
	}

	render() {
		return (
			<Fragment>
				<div className="profile-page-form__column js-profile-password">
					<div className="form-title">пароль</div>
					<div className="modal-text" style={{ fontSize: '14px' }}>
						Пароль должен состоять из 6 или более символов латинского алфавита, при этом допускается
						использование заглавных и строчных букв, цифр, пробелов, знаков препинания и специальных
						символов.
					</div>
					<InputField
						triggerValidate={this.state.triggerValidate}
						setFieldValid={this.setFieldValid}
						change={this.handleChange}
						{...this.state.password}
						keyPressed={this.handleSend}
					/>
					<InputField
						triggerValidate={this.state.triggerValidate}
						setFieldValid={this.setFieldValid}
						change={this.handleChange}
						{...this.state.password1}
						keyPressed={this.handleSend}
					/>
					<InputField
						triggerValidate={this.state.triggerValidate}
						setFieldValid={this.setFieldValid}
						change={this.handleChange}
						compareValue={this.state.password1.value}
						{...this.state.password2}
						keyPressed={this.handleSend}
					/>
					<Button submit={true} onClick={this.handleSend} loading={this.props.loading && this.state.loading}>
						сохранить
					</Button>
				</div>
				{this.props.success && <Success />}
			</Fragment>
		)
	}

	handleSend = async () => {
		if (this.props.loading) return false
		this.setState({ triggerValidate: true })
		if (!this.formIsValid()) return false

		this.setState({ loading: true })
		await this.props.newPassword({
			cmd: 'chpass',
			password: this.state.password.value,
			password1: this.state.password1.value,
			password2: this.state.password2.value,
			email: this.props.user.email
		})
		this.setState({ loading: false })
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			const field = this.state[name]
			if (!field.validate) return true
			return field.valid
		})
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}
}

export default connect(
	(state) => ({
		success: profileNewPasswordSuccessSelector(state),
		user: state.auth.user,
		loading: loadingSelector(state)
	}),
	{ newPassword }
)(NewPassword)
