export const searchToObject = (search) => {
	return search
		.substring(1)
		.split('&')
		.reduce((result, value) => {
			var parts = value.split('=')
			if (parts[0]) result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1])
			return result
		}, {})
}

export const distinct = (items, prop) => {
	const unique = []
	const distinctItems = []

	items.map((item) => {
		if (!unique[item[prop]]) distinctItems.push(item)

		return (unique[item[prop]] = 1)
	})

	return distinctItems
}

export const objectToMap = (object, prop) => {
	return object.reduce((acc, value) => {
		const oldValue = acc[value[prop]] ? acc[value[prop]] : []
		return {
			...acc,
			[value[prop]]: [...oldValue, value]
		}
	}, {})
}
export const arrayToMap = (object, prop) => {
	return object.reduce((acc, value) => {
		return {
			...acc,
			[value[prop]]: value
		}
	}, {})
}

export const isInt = (n) => Number(n) === +n

export const sortingTable = (items, sort) => {
	return items.sort((a, b) => {
		const a_s = a[sort.field]
		const b_s = b[sort.field]
		if (isInt(a_s)) {
			return sort.order === 'desc'
				? +a_s > +b_s
					? 1
					: +b_s > +a_s
					? -1
					: 0
				: +a_s < +b_s
				? 1
				: +b_s < +a_s
				? -1
				: 0
		} else {
			return sort.order === 'desc' ? a_s.localeCompare(b_s) : b_s.localeCompare(a_s)
		}
	})
}

export const groupAndSort = (object, prop, sort) => {
	return object.reduce((acc, value) => {
		const oldValue = acc[value[prop]] ? acc[value[prop]] : []
		return {
			...acc,
			[value[prop]]: sortingTable([...oldValue, value], sort)
		}
	}, {})
}

export const sortGroups = (items, sort) => {
	return Object.keys(items).sort((a, b) => {
		const a_s = items[a][0][sort.field]
		const b_s = items[b][0][sort.field]
		if (isInt(a_s)) {
			return sort.order === 'desc'
				? +a_s > +b_s
					? 1
					: +b_s > +a_s
					? -1
					: 0
				: +a_s < +b_s
				? 1
				: +b_s < +a_s
				? -1
				: 0
		} else {
			return sort.order === 'desc' ? a_s.localeCompare(b_s) : b_s.localeCompare(a_s)
		}
	})
}

export const compare = (a, b) => (a < b ? -1 : a > b ? 1 : 0)

export const declOfNum = (number, titles) => {
	var cases = [2, 0, 1, 1, 1, 2]
	return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}
