import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Reveal from 'react-reveal/Reveal'
import InputField from './common/input-field'
import {
	newPassword,
	authErrorSelector,
	closeErrorMessage,
	newPasswordSuccessSelector,
	authLoadingSelector
} from '../redux/modules/auth'
import ErrorMessage from './common/error-message'
import Button from './common/button'
import Counter from './common/counter'
import { getPage } from '../redux/ac'
import layout from '../decorators/layout'

class NewPassword extends Component {
	componentDidMount() {
		document.title = 'Новый пароль - Сервис онлайн-покупки PSN group'
	}
	state = {
		triggerValidate: false,
		password1: {
			value: '',
			name: 'password1',
			open: false,
			type: 'password',
			placeholder: 'Новый пароль',
			validate: 'password',
			valid: false
		},
		password2: {
			value: '',
			name: 'password2',
			open: false,
			type: 'password',
			placeholder: 'Повторите новый пароль',
			validate: 'password',
			valid: false
		}
	}

	render() {
		return (
			<div>
				<div className="page-bg animate-scale"></div>
				<div className="auth-logo ico-1 animate-fade animate-delay02"></div>
				{this.props.success ? this.success : this.form}
				{this.props.error && <ErrorMessage error={this.props.error} close={this.props.closeErrorMessage} />}
			</div>
		)
	}

	get form() {
		return (
			<div className="modal modal-change-password visible">
				<div className="modal__container">
					<Reveal effect="fadeInUp">
						<div className="modal__body js-change-password">
							<div className="modal-title">Введите новый пароль</div>
							<div className="form-content">
								<div class="modal-text" style={{ fontSize: '14px' }}>
									Пароль должен состоять из 6 или более символов латинского алфавита, при этом
									допускается использование заглавных и строчных букв, цифр, пробелов, знаков
									препинания и специальных символов.
								</div>
								<div className={this.state.password1.open ? 'password-wrap open' : 'password-wrap'}>
									<div
										className="password-wrap__handle-click"
										onClick={() => this.passwordToggle('password1')}
									></div>
									<InputField
										triggerValidate={this.state.triggerValidate}
										setFieldValid={this.setFieldValid}
										change={this.handleChange}
										className="ico-password"
										{...this.state.password1}
										keyPressed={this.handleSend}
									/>
								</div>
								<div className={this.state.password2.open ? 'password-wrap open' : 'password-wrap'}>
									<div
										className="password-wrap__handle-click"
										onClick={() => this.passwordToggle('password2')}
									></div>
									<InputField
										triggerValidate={this.state.triggerValidate}
										setFieldValid={this.setFieldValid}
										change={this.handleChange}
										className="ico-password"
										compareValue={this.state.password1.value}
										{...this.state.password2}
										keyPressed={this.handleSend}
									/>
								</div>
								<Button submit={true} onClick={this.handleSend} loading={this.props.loading}>
									сменить пароль
								</Button>
							</div>
						</div>
					</Reveal>

					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	get success() {
		return (
			<div className="modal modal-change-password-success visible">
				<div className="modal__container">
					<Reveal effect="fadeInUp" key={this.props.success}>
						<div className="modal__body" data-redirect="auth.html">
							<div className="modal-title">
								Вы успешно
								<br />
								изменили пароль!
							</div>

							<div className="form-content">
								<div className="modal-text">
									Теперь используйте его для&nbsp;входа в&nbsp;личный кабинет
								</div>
								<div className="input-field btn-field" onClick={this.handleClose}>
									<div className="btn">закрыть</div>
								</div>
							</div>
							{/*<div className="modal-text modal-text--bottom">
								Это окно будет скрыто автоматически через <Counter callback={this.handleClose} /> секунд
							</div>*/}
						</div>
					</Reveal>

					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	passwordToggle = (name) => {
		this.setState({
			[name]: {
				...this.state[name],
				open: !this.state[name].open,
				type: this.state[name].open ? 'password' : 'text'
			}
		})
	}

	handleSend = () => {
		if (this.props.loading) return false
		this.setState({ triggerValidate: true })
		if (!this.formIsValid()) return false

		this.props.newPassword({
			cmd: 'chpass',
			password1: this.state.password1.value,
			password2: this.state.password2.value,
			checkword: this.props.checkword,
			email: this.props.email
		})
	}

	formIsValid = () => {
		return Object.keys(this.state).every((name) => {
			const field = this.state[name]
			if (!field.validate) return true
			return field.valid
		})
	}

	setFieldValid = (name, valid) => this.setState({ [name]: { ...this.state[name], valid } })

	handleChange = (ev) => {
		const { name, value } = ev.target
		this.setState({ [name]: { ...this.state[name], value } })
	}

	handleClose = () => this.props.getPage('/sign-in')
}

export default connect(
	(state) => ({
		error: authErrorSelector(state),
		success: newPasswordSuccessSelector(state),
		loading: authLoadingSelector(state)
	}),
	{ newPassword, closeErrorMessage, getPage }
)(layout(NewPassword))
