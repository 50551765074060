import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputField from '../common/input-field'
import { Link } from 'react-router-dom'
// import Counter from '../common/counter'

class Message extends Component {
	static propTypes = {
		close: PropTypes.func,
		message: PropTypes.string.isRequired
	}

	id = null

	componentDidMount() {
		this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.close, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		return (
			<div className="modal" ref={(el) => (this.container = el)}>
				<div className="modal__overlay" onClick={this.handleClose}></div>
				<div className="modal__container">
					<div className="modal__body">
						{this.props.close && <div className="modal-close ico-close" onClick={this.hide}></div>}
						<div className="modal-title">Ошибка</div>
						<div className="form-content">
							<div className="modal-text" dangerouslySetInnerHTML={{ __html: this.props.message }} />
							<Link to="/profile" className="input-field btn-field">
								<div className="btn">Перейти в профиль</div>
							</Link>
						</div>
					</div>
					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}

	handleClose = () => {
		if (!this.props.close) return false
		this.hide()
	}
}

export default Message
