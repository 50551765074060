import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CheckboxField extends Component {
	static propTypes = {
		checked: PropTypes.bool.isRequired,
		title: PropTypes.string.isRequired,
		change: PropTypes.func.isRequired
	}

	render() {
		return (
			<div className="input-field check-field">
				<label>
					<input
						type="checkbox"
						checked={this.props.checked}
						name={this.props.name}
						onChange={this.props.change}
					/>
					<i></i>
					<span>{this.props.title}</span>
				</label>
			</div>
		)
	}
}

export default CheckboxField
