import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'
import { setAccess } from '../../redux/modules/auth'
import {
	loadedSelector,
	loadingSelector,
	errorSelector,
	loadQuestions,
	closeError,
	faqSelector,
	groupSelector,
	formOpenSelector,
	sectionsSelector
} from '../../redux/modules/faq'
import ErrorMessage from '../common/error-message'
import Menu from './menu'
import Tabs from './tabs'
import Form from './form'
import layout from '../../decorators/layout'

class Faq extends Component {
	componentDidMount() {
		document.title = 'Вопросы и ответы - Сервис онлайн-покупки PSN group'
		window.scrollTo(0, 0)
		const { loaded, loading, loadQuestions } = this.props
		if (loaded || loading) return false

		this.props.setAccess(true)
		loadQuestions({
			cmd: 'faq'
		})
	}

	componentDidUpdate(prevProps, prevState) {
		const { loaded, loading, loadQuestions } = this.props
		if (loaded || loading) return false

		loadQuestions({
			cmd: 'faq'
		})
	}

	componentWillUnmount() {
		this.props.setAccess(false)
	}

	render() {
		const { loaded, closeError, groups, sections, list, id } = this.props
		if (!loaded) return null
		return (
			<Fragment>
				<Fade>
					<div className="content middle faq">
						<div className="title">Вопросы и&nbsp;ответы</div>
						<div className="tabs">
							<Menu sections={sections} />
							<Tabs id={id} items={id ? groups[id] : list} />
						</div>
					</div>
				</Fade>
				{this.props.formOpen && <Form />}
				{this.props.error && <ErrorMessage error={this.props.error} close={closeError} />}
			</Fragment>
		)
	}
}

export default connect(
	(state) => ({
		loaded: loadedSelector(state),
		loading: loadingSelector(state),
		error: errorSelector(state),
		deals_error: state.deals.error,
		list: faqSelector(state),
		groups: groupSelector(state),
		sections: sectionsSelector(state),

		formOpen: formOpenSelector(state)
	}),
	{ loadQuestions, closeError, setAccess }
)(layout(Faq))
