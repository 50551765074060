import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import accounting from 'accounting'
import { removeDeal, removeLoadingSelector } from '../../../redux/modules/deals'
import { getPage } from '../../../redux/ac'

class Row extends Component {
	state = {
		loading: false
	}
	render() {
		const { el, mobile, user } = this.props
		if (!el.objectId) return null
		if (user.is_developer) {
			return (
				<Fragment>
					<tr>
						<td colSpan="9" style={{ borderTop: '1px solid #ccc' }}>
							deal id: {el.dealId} <br />
							object id: {el.objectId} <br />
							ddu id: {el.dduContractID}
						</td>
					</tr>
					{this.row}
				</Fragment>
			)
		}
		return this.row
	}

	get row() {
		const { el, mobile, cancel } = this.props

		const title =
			el.objectType === 'Жилое'
				? +el.objectRooms === 0
					? 'Студия'
					: `${el.objectRooms} - комнатная квартира `
				: el.objectType === 'Нежилое'
				? 'Апартаменты'
				: el.objectType

		return (
			<tr data-href="deal.html" data-status="stat3">
				<td className="cell-num" onClick={this.handleClick}>
					<span className="cell__title mobile">№</span>
					{el.objectNumber}
				</td>
				<td className="cell-img" onClick={this.handleClick}>
					<img src={el.objectPlanImage ? el.objectPlanImage : '/images/default.jpg'} alt="" />
					{this.props.mobile && (
						<div className="row-icons-mobile">
							<div className="cell-type" onClick={this.handleClick}>
								{el.objectType === 'Жилое' ? (
									<span className="ico-16"></span>
								) : el.objectType === 'Машиноместо' ? (
									<span className="ico-17"></span>
								) : (
									<span className="ico-18"></span>
								)}
							</div>
							{el.objectPDF ? (
								<div className="cell-icon">
									<a href={el.objectPDF} target="_blank">
										<span className="ico-download"></span>
									</a>
								</div>
							) : null}
							<a className="close-icon-row" href="/" onClick={this.handleRemove} />
						</div>
					)}
				</td>
				<td className="cell-text" onClick={this.handleClick}>
					<div>
						<span className="color-orange">
							{title} №{el.objectNumber}
						</span>{' '}
						<br />
						{el.articleName.replace(/^[0-9][0-9]\./g, 'ЖК ')}
						<br />
						{el.articleAddress}
					</div>
				</td>
				<td className="cell-type" onClick={this.handleClick}>
					{el.objectType === 'Жилое' ? (
						<span className="ico-16"></span>
					) : el.objectType === 'Машиноместо' ? (
						<span className="ico-17"></span>
					) : (
						<span className="ico-18"></span>
					)}
				</td>
				<td className="cell-square" onClick={this.handleClick}>
					<span className="cell__title mobile">
						Площадь, м<sup>2</sup>
					</span>
					{el.objectSquare}
				</td>
				<td className="cell-price" onClick={this.handleClick}>
					<span className="cell__title mobile">Стоимость, ₽</span>
					{+el.objectPrice ? accounting.formatNumber(el.objectPrice, 0, ' ') : '-'}
				</td>
				{mobile || (
					<td className="cell-icon">
						{el.objectPDF ? (
							<a href={el.objectPDF} target="_blank">
								<span className="ico-download"></span>
							</a>
						) : (
							'-'
						)}
					</td>
				)}
				<td className="cell-text1" onClick={this.handleClick}>
					<span className="cell__title mobile">Статус</span>
					<span className="text1__loading">
						{this.getActive().stepName}
						{this.props.loading && this.state.loading && <i className="loading-icon" />}
					</span>
				</td>
				{cancel || (
					<td className="cell-text2">
						<div>
							<span className="cell__title mobile">Действия</span>
							{+this.getActive().stepId === 1 ? (
								<a href="/" onClick={this.handleRemove}>
									Отменить
								</a>
							) : (
								'-'
							)}
							{this.props.mobile && <div className="cell-text2__info">&nbsp;</div>}
						</div>
					</td>
				)}
			</tr>
		)
	}

	getActive = (list) =>
		this.props.el.history.reduce((acc, el) => {
			if (el.startDateTime) return el
			return acc
		}, {})

	handleRemove = async (ev) => {
		ev.preventDefault()
		if (this.props.loading) return false
		this.setState({ loading: true })
		await this.props.removeDeal({
			cmd: 'unbook',
			email: this.props.user.email,
			token: this.props.user.token,
			object: this.props.el.objectId,
			deal: this.props.el.dealId
		})
		this.setState({ loading: false })
	}

	handleClick = () => this.props.getPage(`/deals/${this.props.el.dealId}`)
}

export default connect(
	(state) => ({
		mobile: state.mobile,
		user: state.auth.user,
		loading: removeLoadingSelector(state)
	}),
	{ removeDeal, getPage }
)(Row)
