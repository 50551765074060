import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Counter from '../common/counter'

class SuccessMessage extends Component {
	static propTypes = {
		close: PropTypes.func.isRequired
	}

	id = null

	componentDidMount() {
		this.id = setTimeout(() => this.container.classList.add('visible'), 10)
	}

	hide = () => {
		if (this.container) this.container.classList.remove('visible')
		this.id = setTimeout(this.props.close, 600)
	}

	componentWillUnmount() {
		clearTimeout(this.id)
	}

	render() {
		return (
			<div className="modal modal-ask-question-message" ref={(el) => (this.container = el)}>
				<div className="modal__overlay"></div>
				<div className="modal__container">
					<div className="modal__body js-ask-question-message">
						<div className="modal-close ico-close" onClick={this.hide}></div>
						<div className="modal-title">Спасибо за ваше обращение!</div>
						<div className="form-content">
							<div className="modal-text">
								<p>Мы свяжемся с вами в ближайшее время</p>
							</div>
							<div className="input-field btn-field">
								<div className="btn" onClick={this.hide}>
									Закрыть
								</div>
							</div>
						</div>
						{/*<div className="modal-text modal-text--bottom">
							Это окно будет скрыто автоматически через <Counter callback={this.hide} /> секунд
						</div>*/}
					</div>

					<div className="modal-helper"></div>
				</div>
			</div>
		)
	}
}

export default SuccessMessage
