import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addFavoriteItem, errorSelector, favSuccessSelector } from '../../redux/modules/favorites'
import { loadObject } from '../../redux/modules/booking'
import './style.css'
import Form from './form'
import { statusCodeDefault, statusCode } from '../../services/constants'

class FramePage extends Component {
	componentDidMount() {
		if (this.props.favorite && this.props.user) this.handleAddFavorite()
		if (this.props.booking) this.handleLoadObject()
	}

	handleAddFavorite = () => {
		const { user, article, object } = this.props
		this.props.addFavoriteItem({
			cmd: 'favorites_add',
			email: user.email,
			token: user.token,
			article,
			object
		})
	}

	handleLoadObject = () => {
		this.props.loadObject({
			cmd: 'get_object',
			article: this.props.article,
			object: this.props.object
		})
	}

	render() {
		const { favorite, booking, loaded, theme, booking_object, objectError } = this.props
		if (!loaded || (booking && !booking_object && !objectError)) return null
		return (
			<div className={`widget widget${theme}`}>
				{favorite && this.favorite}
				{booking && this.booking}
			</div>
		)
	}

	get booking() {
		const { article, object, user, booking_object, objectError } = this.props
		if (objectError) return <div>{objectError}</div>
		if (+booking_object.StatusCode === 8) {
			return <div dangerouslySetInnerHTML={{ __html: statusCode[8] }} />
		}
		if (+booking_object.StatusCode === 32) {
			return <div dangerouslySetInnerHTML={{ __html: statusCode[32] }} />
			return statusCode[32]
		}
		if (+booking_object.StatusCode === 128) {
			return <div dangerouslySetInnerHTML={{ __html: statusCode[128] }} />
			return statusCode[128]
		}
		if (+booking_object.StatusCode !== 4) {
			return <div dangerouslySetInnerHTML={{ __html: statusCodeDefault }} />
		}

		return (
			<div>
				Уважаемый клиент! <p>В настоящее время данный объект недвижимости доступен для&nbsp;бронирования!</p>{' '}
				Для завершения процедуры бронирования объекта необходимо авторизоваться/зарегистрироваться в&nbsp;личном
				кабинете на&nbsp;сайте&nbsp;online.psngroup.ru, после чего все ваши сделки будут доступны
				в&nbsp;соответствующем разделе
				<a href={`/booking/${article}/${object}`} target="_blank" className="widget-btn">
					<span>Перейти в Личный кабинет</span>
				</a>
			</div>
		)
	}

	get favorite() {
		const { user, favError, favSuccess, article, object } = this.props
		if (favError) return <div>{favError}</div>
		if (favSuccess)
			return (
				<div>
					Объект добавлен в Избранное.
					<div className="widget-pt35">
						Посмотреть все объекты вы сможете <br /> в{' '}
						<a href="/favorites" target="_blank">
							личном кабинете
						</a>
					</div>
				</div>
			)
		if (!user)
			return (
				<div>
					Для добавления объектов в Избранное
					<br /> необходимо зарегистрироваться/авторизоваться
					<br /> в личном кабинете
					<Form object={object} article={article} />
				</div>
			)
		return null
	}
}

export default connect(
	(state) => ({
		user: state.auth.user,
		loaded: state.auth.showIframe,

		favError: errorSelector(state),
		favSuccess: favSuccessSelector(state),

		booking_object: state.booking.object,
		objectError: state.booking.objectError
	}),
	{ addFavoriteItem, loadObject }
)(FramePage)
