import React, { Component, Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadFavorites } from '../../redux/modules/favorites'
import { loadDeals } from '../../redux/modules/deals'
import { signOut } from '../../redux/modules/auth'

class Icons extends Component {
	state = {
		old_st: window.scrollY,
		scrolled: false
	}

	componentDidMount() {
		window.addEventListener('scroll', this._onScroll)

		const {
			loadedFavorites,
			loadingFavorites,
			loadFavorites,
			loadDeals,
			loadedDeals,
			loadingDeals,
			user,
			clone
		} = this.props

		if (!loadedDeals && !loadingDeals && user && !clone) {
			loadDeals({
				cmd: 'user_deals',
				email: user.email,
				token: user.token
			})
		}
		if (!loadedFavorites && !loadingFavorites && user && !clone) {
			loadFavorites({
				cmd: 'favorites',
				email: user.email,
				token: user.token
			})
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this._onScroll)
	}

	render() {
		const { type, user, access, favorites, deals, mobile } = this.props
		if (mobile && type !== 'mobile') return null
		if (!mobile && type === 'mobile') return null
		if (!user && !access) return null

		const scrolled = this.state.scrolled ? ' scrolled' : ''
		if (mobile) return this.mobile

		return (
			<div className={'header-links'}>
				<NavLink to="/faq" className="header-link">
					<div>
						<span>Вопросы и ответы</span>
					</div>
				</NavLink>
				{user && (
					<Fragment>
						<NavLink to="/profile" className="header-link">
							<div>
								<span>Профиль</span>
							</div>
						</NavLink>
						<NavLink to="/favorites" className="header-link header-link--withicon">
							<div>
								<span>Избранное</span>
							</div>
							<i>{favorites.length}</i>
						</NavLink>
						<NavLink to="/deals" className="header-link header-link--withicon">
							<div>
								<span>Сделки</span>
							</div>
							<i>{deals.length}</i>
						</NavLink>
						<div className="header-link" onClick={() => this.props.signOut(true)}>
							<div>
								<span>Выход</span>
							</div>
						</div>
					</Fragment>
				)}
			</div>
		)
	}

	get mobile() {
		const { type, user, access, favorites, deals, mobile } = this.props
		const scrolled = this.state.scrolled ? ' scrolled' : ''

		return (
			<div className={'header-icons header-icons--' + type + scrolled}>
				<Link to="/faq" className="header-icons__icon">
					<div className="ico-faq">
						<span>Вопросы и ответы</span>
					</div>
				</Link>
				{user && (
					<Fragment>
						<Link to="/profile" className="header-icons__icon">
							<div className="ico-profile">
								<span>Профиль</span>
							</div>
						</Link>
						<Link to="/favorites" className="header-icons__icon">
							<div className="ico-fav">
								<span>Избранное</span>
							</div>
							<i>{favorites.length}</i>
						</Link>
						<Link to="/deals" className="header-icons__icon">
							<div className="ico-lock">
								<span>Сделки</span>
							</div>
							<i>{deals.length}</i>
						</Link>
						<div className="header-icons__icon" onClick={() => this.props.signOut(true)}>
							<div className="ico-logout">
								<span>Выход</span>
							</div>
						</div>
					</Fragment>
				)}
			</div>
		)
	}

	_onScroll = () => {
		if (this.props.type === 'mobile') {
			this.setState({
				old_st: window.scrollY,
				scrolled: window.scrollY > this.state.old_st
			})
		}
	}
}
export default connect(
	(state) => ({
		user: state.auth.user,
		access: state.auth.access,
		deals: state.deals.entities,
		favorites: state.favorites.entities,
		loadedFavorites: state.favorites.loaded,
		loadingFavorites: state.favorites.loading,
		loadedDeals: state.deals.loaded,
		loadingDeals: state.deals.loading,
		mobile: state.mobile
	}),
	{ loadFavorites, loadDeals, signOut }
)(Icons)
