import React, { Component } from 'react'

class Cookie extends Component {
	state = {
		open: true
	}

	render() {
		if (window.sessionStorage.getItem('cookieInfo') || !this.state.open) return null
		return (
			<div className="cookie_message">
				<p>
					Используя веб-сайт psngroup.ru вы&nbsp;соглашаетесь на&nbsp;использование файлов{' '}
					<a href="https://www.psngroup.ru/privacy-policy.php" target="_blank">
						cookies
					</a>{' '}
					для повышения уровня сервиса.
					<br />
					В&nbsp;случае отказа, сайт может работать некорректно.
				</p>
				<div className="cookie_message__close ico-close" onClick={this.close}></div>
			</div>
		)
	}

	close = () => {
		window.sessionStorage.setItem('cookieInfo', '1')
		this.setState({ open: false })
	}
}

export default Cookie
