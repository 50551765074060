import { createStore, applyMiddleware } from 'redux'
import reducer from './reducer'
import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import history from './history'
import storage from '../middlewares/storage'

const enhancer = applyMiddleware(thunk, routerMiddleware(history), storage)

const store = createStore(connectRouter(history)(reducer), enhancer)
window.store = store
export default store
